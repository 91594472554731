import { Flex } from "@chakra-ui/react";
import {
  CalendarBlockType,
  InboxBlockPositionType,
  InboxBlockStatusType,
  InboxBlockType,
} from "../../../../types/block/BlockType";

import InboxBlock from "../InboxBlock/InboxBlock";
import InboxToggle from "./Toggle/InboxToggle";

interface InboxToggleListProps {
  title: string;
  list: (InboxBlockType | CalendarBlockType)[];
  onDataDuplicate: (e: React.MouseEvent, data: InboxBlockType | CalendarBlockType) => void;
  onDataDelete: (rowData: InboxBlockType | CalendarBlockType) => void;
  taskSelectedId: string | null;
  onSelect: (id: string | null) => void;
  onClickInboxBlock: (
    e: React.MouseEvent,
    data: InboxBlockType | CalendarBlockType,
    ref: React.RefObject<HTMLDivElement>
  ) => void;
}

const InboxToggleList = ({
  title,
  list,
  onDataDuplicate,
  onDataDelete,
  taskSelectedId,
  onSelect,
  onClickInboxBlock,
}: InboxToggleListProps) => {
  return (
    <InboxToggle title={title} number={list.length ?? 0} onToggle={() => {}}>
      <Flex flexDirection="column" gap={2}>
        {list.map((task, index) => (
          <InboxBlock
            data={task}
            index={index}
            key={`${task.id}-${index}`}
            onDataDuplicate={onDataDuplicate}
            onDataDelete={onDataDelete}
            status={title as InboxBlockStatusType}
            position={InboxBlockPositionType.Toggle}
            isSelected={taskSelectedId === task.id}
            onSelect={onSelect}
            onClickBlock={onClickInboxBlock}
          />
        ))}
      </Flex>
    </InboxToggle>
  );
};
export default InboxToggleList;
