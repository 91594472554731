import React, { useMemo, useState, useEffect } from "react";
import { TaskRowLayer } from "./InboxBody/InboxBlock/TaskRowLayer";
import CalendarDragLayer from "./DragAndDrop/CalendarDragLayer";
const { useDragLayer } = require("react-dnd");

function InboxReorderDragLayer() {
  const [isOverCalendar, setIsOverCalendar] = useState(false);

  let savedWidth = localStorage.getItem("savedWidth");

  if (!savedWidth) {
    savedWidth = "320px";
  } else {
    savedWidth = parseInt(savedWidth.replace("px", ""), 10);
    savedWidth = savedWidth - 16;
    savedWidth = `${savedWidth}px`;
  }

  useEffect(() => {
    const handleDrag = (e) => {
      const calendarElement = document.querySelector(".rbc-calendar");
      if (calendarElement) {
        const rect = calendarElement.getBoundingClientRect();
        const isOver =
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom;
        setIsOverCalendar(isOver);
      }
    };

    document.addEventListener("dragover", handleDrag);
    return () => document.removeEventListener("dragover", handleDrag);
  }, []);

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const layerStyles = useMemo(
    () => ({
      position: "fixed",
      pointerEvents: "none",
      zIndex: 1000,
      left: 0,
      top: 0,
      transform: currentOffset
        ? `translate(${currentOffset.x}px, ${currentOffset.y}px)`
        : undefined,
      width: savedWidth,
      height: "fit-content",
      borderRadius: "6px",
      border: "1px solid var(--hover, rgba(255, 255, 255, 0.08))",
      background:
        "linear-gradient(0deg, var(--hover-big, rgba(255, 255, 255, 0.04)) 0%, var(--hover-big, rgba(255, 255, 255, 0.04)) 100%), #242626",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      boxSizing: "border-box",
      cursor: "pointer",
    }),
    [currentOffset]
  );

  const calendarLayerStyles = useMemo(
    () => ({
      position: "fixed",
      pointerEvents: "none",
      zIndex: 1000,
      left: 0,
      top: 0,
      transform: currentOffset
        ? `translate(${currentOffset.x - 1}px, ${currentOffset.y - 1}px)`
        : undefined,
      borderRadius: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "8px",
      boxSizing: "border-box",
      cursor: "pointer",
    }),
    [currentOffset]
  );

  if (!item || !isDragging || item.dragType !== "inboxItem") {
    return null;
  }

  return (
    <>
      {isOverCalendar ? (
        <div style={calendarLayerStyles} key={item.id}>
          <CalendarDragLayer data={item.data} />
        </div>
      ) : (
        <div style={layerStyles} key={item.id}>
          <TaskRowLayer data={item.data} />
        </div>
      )}
    </>
  );
}

export default React.memo(InboxReorderDragLayer);
