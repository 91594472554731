import { useDrag } from "react-dnd";
import { useRecoilState, useRecoilValue } from "recoil";
import { dragEventState } from "../../../../../recoil/calendar/calendarState";
import { draggedEventState } from "../../../../../recoil/calendar/calendarStateV2";
import useApi from "../../../../../services/auth/useApi";
import { useJuneTrackCall } from "../../../../../utils/june/analytics";
import styles from "./IntegrationDetailItems.module.css";

import { COMPLETED, INPROGRESS } from "../../../../../constants/taskStateType";
import { doneTaskListState } from "../../../../../recoil/taskList/doneTaskListState";
import { inboxTaskListState } from "../../../../../recoil/taskList/inboxTaskListState";
import { visibilityState } from "../../../../../recoil/calendar/settingCalendar";

function parseTicketToElements(ticket) {
  let content = [];

  ticket.description?.content?.forEach((item, itemIndex) => {
    item.content?.forEach((subItem, subItemIndex) => {
      if (subItem.type === "text") {
        content.push(
          <p key={`text-${itemIndex}-${subItemIndex}`} className={styles["jira-description"]}>
            {subItem.text}
          </p>
        );
      }
    });
  });
  return <div key={`ticket`}>{content}</div>;
}

function JiraTextRender({ data }) {
  const elements = parseTicketToElements(data);
  return <div>{elements}</div>;
}

const JiraItem = ({
  data,
  integrationId,
  creator,
  setData: setJiraData,
  listData,
  reloadFunction,
}) => {
  const redirectToJira = () => {
    trackCall("click_integration", { location: "integration", type: "jira" });

    const jiraMoveUrl = data.link;
    window.open(jiraMoveUrl, "_blank");
  };

  const makeLink = data.link;

  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);
  const [dragEvent, setDragEvent] = useRecoilState(dragEventState);
  const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);
  const [doneTaskList, updateDoneTaskList] = useRecoilState(doneTaskListState);
  const defaultVisibility = useRecoilValue(visibilityState);

  const trackCall = useJuneTrackCall();
  const api = useApi();

  const dragData = {
    ...data,
    id: "int-" + data.id,
    title: data.title,
    kind: "jira",
    provider: "jira",
    integrationId: integrationId,
    creator: creator,
    link: makeLink,
    color: "#abadad",
    dragType: "integrationDrag",
    integration: {
      integrationId: integrationId,
      itemId: data.id,
      provider: "jira",
      link: makeLink,
      title: data.title,
    },
  };

  const [{ isDragging }, drag] = useDrag({
    type: "integrationDrag",
    item: dragData,
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        const dropResult = monitor.getDropResult();
        if (!dropResult) {
          updateInboxTaskList((current) => {
            const updatedTasks = current.filter((task) => task.id !== data.id);
            return updatedTasks;
          });
        } else {
          if (dropResult.dropType !== "calendarIntegrationDrop") {
            const newItem = {
              id: dragData.id,
              title: item.title,
              integration: {
                integrationId: item.integrationId,
                itemId: data.id,
                provider: item.kind,
                link: item.link,
              },
              visibility: defaultVisibility === "public" ? "public" : "private",
              transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
              itemStatus: dropResult.dropType === "doneTask" ? COMPLETED : INPROGRESS,
              taskType: "Task",
            };

            api
              .post("tasks", newItem, {
                headers: {
                  "X-Requester": item.creator,
                },
              })
              .then((res) => {
                trackCall("create_block", {
                  location: "integration",
                });
              });
          }

          // setTimeout(() => {
          setJiraData((current) => current.filter((jira) => jira.id !== data.id));
          // }, 100);
        }
      } else {
        updateInboxTaskList((current) => {
          const updatedTasks = current.filter((task) => task.id !== dragData.id);
          return updatedTasks;
        });

        updateDoneTaskList((current) => {
          const updatedTasks = current.filter((task) => task.id !== dragData.id);
          return updatedTasks;
        });
      }
      setDragEvent(null);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     reloadJira();
  //   }, 100);
  // }, [draggedEvent]);

  return (
    <div
      ref={drag}
      draggable="true"
      key={{ title: "jira title" }}
      className={` ${isDragging ? styles["dragging"] : styles["item-area"]}`}
      onClick={redirectToJira}
      onDragStart={() => setDraggedEvent(dragData)}
    >
      <span className={styles["project-name"]}>{data.project}</span>
      <span className={styles["issue-title"]}>{data.title}</span>
      <img
        key={`avatar`}
        className={styles["profile-icon"]}
        src={data.reporter.avatarUrls["32x32"]}
        alt="Reporter Avatar"
      />
      <div className={styles["description-container"]}>
        <span className={styles["description"]}>
          <JiraTextRender data={data} />
        </span>
      </div>
    </div>
  );
};

export default JiraItem;
