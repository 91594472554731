import { Text } from "@chakra-ui/react";

interface CalendarBlockTitleProps {
  title: string;
  isCreateSelectEvent?: boolean;
  taskType?: string;
}

const CalendarBlockTitle = ({ title, isCreateSelectEvent, taskType }: CalendarBlockTitleProps) => (
  <Text color="white" textStyle="body2" lineHeight={1.38} noOfLines={1} w={"full"}>
    {title || (isCreateSelectEvent ? `New ${taskType}` : "No title")}
  </Text>
);

export default CalendarBlockTitle;
