import { createIcon } from "@chakra-ui/react";

export const InboxFilterIcon = createIcon({
  displayName: "InboxFilterIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 4.58329H2.66669V3.41663H8.00002V4.58329Z"
        fill={"currentColor"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08982 11.4287L13.4232 11.4287L13.4232 12.5953L8.08982 12.5953L8.08982 11.4287Z"
        fill={"currentColor"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 4.58329L10.6667 4.58329L10.6667 3.41663L13.3334 3.41663L13.3334 4.58329Z"
        fill={"currentColor"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66665 11.4167L5.33331 11.4167L5.33331 12.5834L2.66665 12.5834L2.66665 11.4167Z"
        fill={"currentColor"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 8.58329H2.66669V7.41663H13.3334V8.58329Z"
        fill={"currentColor"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33331 5C9.8856 5 10.3333 4.55228 10.3333 4C10.3333 3.44772 9.8856 3 9.33331 3C8.78103 3 8.33331 3.44772 8.33331 4C8.33331 4.55228 8.78103 5 9.33331 5ZM9.33331 6C10.4379 6 11.3333 5.10457 11.3333 4C11.3333 2.89543 10.4379 2 9.33331 2C8.22874 2 7.33331 2.89543 7.33331 4C7.33331 5.10457 8.22874 6 9.33331 6Z"
        fill={"currentColor"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75653 11.0117C6.20425 11.0117 5.75653 11.4594 5.75653 12.0117C5.75653 12.564 6.20425 13.0117 6.75653 13.0117C7.30882 13.0117 7.75653 12.564 7.75653 12.0117C7.75653 11.4594 7.30882 11.0117 6.75653 11.0117ZM6.75653 10.0117C5.65196 10.0117 4.75653 10.9071 4.75653 12.0117C4.75653 13.1163 5.65196 14.0117 6.75653 14.0117C7.8611 14.0117 8.75653 13.1163 8.75653 12.0117C8.75653 10.9071 7.8611 10.0117 6.75653 10.0117Z"
        fill={"currentColor"}
      />
    </>
  ),
});

export const CheckIcon = createIcon({
  displayName: "CheckIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.9751 8.09264L3.6911 7.39454L6.8331 10.6171L12.9751 4.31761L13.6911 5.01571L6.8331 12.0496L2.9751 8.09264Z"
      fill={"currentColor"}
    />
  ),
});
