import { useQueries, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { tokenState } from "../recoil/auth/tokenState";
import useApiCalendar from "../services/calendar/useApiCalendar";

import { accountState } from "../recoil/account/accountStateV2";
import { getMonthStartAndEndAsISO } from "../utils/date-format/dateUtils";
import { calendarTotalMonthState } from "../recoil/calendar/calendarStateV2";

export const calendarEventsKey = {
  all: ["calendarEvents"],
  calendarEvent: (calendarCurrentMonth, account) => [
    ...calendarEventsKey.all,
    calendarCurrentMonth,
    account,
  ],
};

const useFetchCalendarEvents = () => {
  const calendarApi = useApiCalendar();
  const account = useRecoilValue(accountState);
  const token = useRecoilValue(tokenState);
  const calendarTotalMonth = useRecoilValue(calendarTotalMonthState);

  // const [calendarEvents, setCalendarEvents] = useRecoilState(calendarEventState);
  const userEmail = account && account.accountInfo.accounts[0].email;
  const queryClient = useQueryClient();

  const fetchCalendarEvents = async (month) => {
    if (userEmail) {
      // 이때 Month는 2024-11 형태
      const { firstDay, lastDay } = getMonthStartAndEndAsISO(month);

      const response = await calendarApi.get("", {
        params: {
          timeMin: firstDay,
          timeMax: lastDay,
          maxResults: 2500,
          singleEvents: false,
        },
      });
      return response.data.items.map((event) => ({
        ...event,
        creator: {
          email: userEmail,
        },
      }));
    }
  };

  const invalidateCalendarEvents = async () => {
    // 쿼리 무효화
    await queryClient.invalidateQueries(calendarEventsKey.calendarEvent(calendarEventsKey.all));
    // 쿼리 다시 불러오기
  };

  // const updateCalendarEvents = (data, setCalendarEvents) => {
  //   setCalendarEvents((currentEvents) => {
  //     const updatedEvents = currentEvents.map(
  //       (item) => data.find((newItem) => newItem.id === item.id) || item
  //     );
  //     const newEvents = data.filter(
  //       (newItem) => !currentEvents.some((item) => item.id === newItem.id)
  //     );
  //     return [...updatedEvents, ...newEvents];
  //   });
  // };

  const combineDataWithoutDuplicates = (results) => {
    const map = new Map();

    results.forEach((result) => {
      if (result.status === "success") {
        result.data.forEach((item) => {
          map.set(item.id, item);
        });
      }
    });
    return Array.from(map.values());
  };

  const result = useQueries({
    queries: calendarTotalMonth?.map((month) => ({
      queryKey: calendarEventsKey.calendarEvent(month, userEmail),
      queryFn: () => fetchCalendarEvents(month),
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5분
      gcTime: Infinity,
      refetchOnWindowFocus: true,
    })),
    combine: (results) => {
      const allSuccessful = results.every((result) => result.status === "success");
      const combinedData = allSuccessful ? combineDataWithoutDuplicates(results) : [];
      return {
        data: combinedData,
        pending: results.some((result) => result.isLoading),
      };
    },
  });

  return { result, invalidateCalendarEvents };
};

export default useFetchCalendarEvents;
