import { Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";

interface CalendarBlockCheckboxProps {
  baseColor: string;
}

const CalendarBlockCheckbox = ({ baseColor }: CalendarBlockCheckboxProps) => (
  <Flex
    position="relative"
    w={pxToRem(14)}
    h={pxToRem(14)}
    margin="2px 0"
    flexShrink={0}
    borderRadius={pxToRem(3)}
    border={`1px solid ${baseColor}`}
    alignItems="center"
    justifyContent="center"
    overflow="hidden"
  />
);

export default CalendarBlockCheckbox;
