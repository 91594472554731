import { CalendarBlockType, InboxBlockType } from "../../types/block/BlockType";
import { TaskDividerType } from "../divider/divider.service";
import { filterTasks } from "../task/task.service";

export const isInboxBlock = (
  task: InboxBlockType | TaskDividerType | CalendarBlockType
): task is InboxBlockType => {
  return "taskType" in task;
};

export const countTasks = (
  taskLists: (InboxBlockType | TaskDividerType | CalendarBlockType)[][],
  selectedSpaceIdList: string[]
): number => {
  return taskLists.flat().filter((task) => {
    if (!isInboxBlock(task)) {
      return false;
    }
    return filterTasks(selectedSpaceIdList, task.projectId);
  }).length;
};
