import { useRecoilValue } from "recoil";
import { timeFormatState } from "../../recoil/calendar/settingCalendar";

export const useStandardDateTimeToTime = () => {
  const timeFormat = useRecoilValue(timeFormatState);

  return (date: string) => {
    const dateObj = new Date(date);
    const minutes = dateObj.getMinutes();

    // 정각인 경우
    if (timeFormat === "12-hour" && minutes === 0) {
      return dateObj
        .toLocaleTimeString("en-US", {
          hour: "numeric", // "2-digit" 대신 "numeric" 사용
          hour12: timeFormat === "12-hour",
        })
        .toLowerCase()
        .replace(":00", ""); // ":00" 제거
    }

    // 정각이 아닌 경우
    return dateObj
      .toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: timeFormat === "12-hour",
      })
      .toLowerCase();
  };
};
