import { atom, selector } from "recoil";
import { InboxBlockType } from "../../types/block/BlockType";
import { TaskDividerType } from "../../services/divider/divider.service";

// Inbox List에서 UI를 미리그리기 위한 전역 Array

export const inboxTaskListState = atom<(InboxBlockType | TaskDividerType)[] | null>({
  key: "inboxTaskListState",
  default: [],
});

export const inboxTaskListIdsState = selector<string[]>({
  key: "inboxTaskListIdsState",
  get: ({ get }) => {
    const inboxTaskList = get(inboxTaskListState);
    if (!inboxTaskList) return [];
    return inboxTaskList.map((task) => task.id);
  },
});
