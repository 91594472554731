import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useRecoilValue, useSetRecoilState } from "recoil";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";

import { inboxBlockQueryKey } from "../inboxQueryKey";
import { useReorderInboxBlockMutation } from "../core/useReorderInboxBlockMutation";
import { TaskDividerType } from "../../../services/divider/divider.service";
import { createTaskDividerApi } from "./useCreateTaskDividerMutation";
import { inboxTaskListState } from "../../../recoil/taskList/inboxTaskListState";

interface DuplicateTaskDividerPayload {
  targetId: string;
  newDivider: TaskDividerType;
}

export const useDuplicateTaskDividerMutation = (
  options?: UseMutationOptions<TaskDividerType, AxiosError, DuplicateTaskDividerPayload>
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  const inboxTaskList = useRecoilValue(inboxTaskListState);
  const setInboxTaskList = useSetRecoilState(inboxTaskListState);
  const { mutate: reorderMutate } = useReorderInboxBlockMutation();

  return useMutation<TaskDividerType, AxiosError, DuplicateTaskDividerPayload>({
    mutationFn: async ({ newDivider }: DuplicateTaskDividerPayload) => {
      return createTaskDividerApi(api, newDivider);
    },
    onMutate: async ({
      targetId,
      newDivider,
    }: {
      targetId: string;
      newDivider: TaskDividerType;
    }) => {
      await queryClient.cancelQueries({ queryKey: inboxBlockQueryKey() });

      let previousList = [...(inboxTaskList || [])];
      const targetIndex = previousList.findIndex((item) => item.id === targetId);

      if (targetIndex === -1) {
        previousList = [...(inboxTaskList || []), newDivider];
      }

      const updatedList = [
        ...previousList.slice(0, targetIndex + 1),
        newDivider,
        ...previousList.slice(targetIndex + 1),
      ];

      reorderMutate({
        taskIds: updatedList.map((task) => task.id),
        status: "InProgress",
      });

      setInboxTaskList(updatedList);

      return { previousList };
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inboxBlockQueryKey() });
    },
    ...options,
  });
};
