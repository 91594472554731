import { CreateDividerType } from "../../../types/space";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";
import { inboxBlockQueryKey } from "../inboxQueryKey";
import { CreateTaskDividerType, TaskDividerType } from "../../../services/divider/divider.service";
import { InboxBlockType } from "../../../types/block/BlockType";
import { inboxTaskListState } from "../../../recoil/taskList/inboxTaskListState";
import { useRecoilState, useSetRecoilState } from "recoil";

export const createTaskDividerApi = async (
  api: ReturnType<typeof useApi>,
  payload: CreateTaskDividerType
): Promise<TaskDividerType> => {
  const response = await api.post("/tasks/dividers", payload);
  return response.data;
};

export const useCreateTaskDividerMutation = (
  options?: UseMutationOptions<TaskDividerType, AxiosError, CreateTaskDividerType>
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const [inboxTaskList, setInboxTaskList] = useRecoilState(inboxTaskListState);

  return useMutation<TaskDividerType, AxiosError, CreateTaskDividerType>({
    mutationFn: (payload) => createTaskDividerApi(api, payload),
    onMutate: async (newDivider: CreateTaskDividerType) => {
      await queryClient.cancelQueries({ queryKey: inboxBlockQueryKey() });
      // setInboxTaskList를 함수형 업데이트로 사용하여 이전 상태를 기반으로 새로운 상태를 설정
      setInboxTaskList((prevInboxTaskList) => {
        return prevInboxTaskList ? [newDivider, ...prevInboxTaskList] : [newDivider];
      });

      return { inboxTaskList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inboxBlockQueryKey() });
    },
    ...options,
  });
};
