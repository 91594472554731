export const updateClientBlockList = <T extends { id: string }>(
  prev: T[] | null,
  payload: T,
  options?: { prepend?: boolean; removeItem?: boolean }
): T[] => {
  if (!prev) return options?.removeItem ? [] : [payload];

  const index = prev.findIndex((item) => item.id === payload.id);
  const updatedList = [...prev];

  if (options?.removeItem && index !== -1) {
    updatedList.splice(index, 1);
    return updatedList;
  }

  if (index !== -1) {
    updatedList[index] = payload;
    return updatedList;
  }

  return options?.prepend ? [payload, ...prev] : [...prev, payload];
};
