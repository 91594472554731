import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import InboxHeader from "./InboxHeader/InboxHeader";
import TaskList from "./InboxBody/TaskList";
import {
  InboxHeaderTab,
  ToggleVisibility,
  ToggleVisibilityByTab,
} from "../../types/inbox/inbox-headers";
import { pxToRem } from "../../utils/styles/size";
import { countTasks } from "../../services/inbox/inboxType";
import { useRecoilValue } from "recoil";
import { selectedSpaceIdListState } from "../../recoil/spaces/selectedSpaceIdListState";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import { useInbox3MonthsTaskList } from "../../hooks/inbox/useInbox3MonthsTaskList";

interface InboxProps {
  inboxRef: React.RefObject<HTMLDivElement>;
}

export type HandleTabToggleVisibility = (
  toggle: keyof ToggleVisibility,
  activeTab: InboxHeaderTab
) => void;

export default function TaskInbox({ inboxRef }: InboxProps) {
  const [activeTab, setActiveTab] = useState<InboxHeaderTab>(() => {
    const savedTab = localStorage.getItem("activeTab");
    return (savedTab as InboxHeaderTab) || "Inbox";
  });

  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListState);

  const inboxTaskList = useRecoilValue(inboxTaskListState);

  // TODO NOTE 여기서 타입 꼬였음.
  const { todayTaskList, inboxPlannedList, overdueList } =
    useInbox3MonthsTaskList(selectedSpaceIdList);

  const inboxTaskLists = [inboxTaskList ?? [], inboxPlannedList, overdueList];
  const todayTaskLists = [todayTaskList, overdueList];
  const selectedSpaceIds = selectedSpaceIdList ?? [];
  const inboxTaskCount = countTasks(inboxTaskLists, selectedSpaceIds);
  const todayTaskCount = countTasks(todayTaskLists, selectedSpaceIds);
  const inboxTodayCount = {
    inbox: inboxTaskCount,
    today: todayTaskCount,
  };

  const [toggleVisibility, setToggleVisibility] = useState<ToggleVisibilityByTab>({
    Inbox: { overdue: false, completed: false, planned: false },
    Today: { overdue: false, completed: false },
  });

  useEffect(() => {
    const savedToggleVisibility = localStorage.getItem("toggleVisibility");
    if (savedToggleVisibility) {
      setToggleVisibility(JSON.parse(savedToggleVisibility));
    }
  }, []);

  const handleToggleVisibility: HandleTabToggleVisibility = (toggle, activeTab) => {
    if (toggle === "planned" && activeTab === "Today") {
      return;
    }

    setToggleVisibility((prevState) => {
      const newState = {
        ...prevState,
        [activeTab]: {
          ...prevState[activeTab],
          [toggle]: !prevState[activeTab][toggle],
        },
      };

      localStorage.setItem("toggleVisibility", JSON.stringify(newState));

      return newState;
    });
  };

  const handleChangeActiveTab = (event: React.MouseEvent<HTMLButtonElement>) => {
    const tab = event.currentTarget.value as InboxHeaderTab;
    setActiveTab(tab);

    localStorage.setItem("activeTab", tab);
  };

  return (
    <Flex
      w={"100%"}
      h={"full"}
      flexDir={"column"}
      border={"1px solid"}
      borderColor={"#313435"}
      borderRadius={pxToRem(8)}
      backgroundColor={"#242626"}
      ref={inboxRef}
      userSelect="none"
      sx={{
        "& > *": {
          // 자식 요소들에 대한 스타일
          userSelect: "none",
          "--user-drag": "none",
          WebkitUserDrag: "none",
        },
      }}
    >
      <InboxHeader
        handleChangeActiveTab={handleChangeActiveTab}
        activeTab={activeTab}
        onToggleVisibility={handleToggleVisibility}
        toggleVisibility={toggleVisibility}
        inboxTodayCount={inboxTodayCount}
      />

      <TaskList toggleVisibility={toggleVisibility} activeTab={activeTab} />
    </Flex>
  );
}
