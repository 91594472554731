import { initialConvertGoogleCalendarToMobaCalendar } from "./initialConvertGoogleCalendarToMobaCalendar";

export const removeWorkingLocationEvents = (events) => {
  return events.filter((event) => event.eventType !== "workingLocation");
};

// 이벤트를 유형별로 분류하는 함수
export const categorizeEventsByRecurrence = (events) => {
  return {
    // 삭제된 이벤트
    deletedEvents: events.filter((event) => event.status === "cancelled"),

    // 업데이트된 반복 이벤트(반복 이벤트 중 this로 수정된 이벤트)
    updatedEvents: events.filter((event) => event.recurringEventId && event.status !== "cancelled"),

    // 기본 반복 이벤트
    baseEvents: events.filter((event) => event.recurrence && !event.recurringEventId),

    // 일반 이벤트
    nonRecurringEvents: events.filter(
      (event) => !event.recurrence && !event.recurringEventId && event.status !== "cancelled"
    ),
  };
};

export const convertToMobaFormat = (events) => {
  return initialConvertGoogleCalendarToMobaCalendar(events);
};
