import { atom } from "recoil";
import { InboxBlockType } from "../../types/block/BlockType";

/**
 * Completed 상태의 리스트를 그리기 위한 전역변수
 */
export const doneTaskListState = atom<InboxBlockType[] | null>({
  key: "doneTaskListState",
  default: null,
});
