import React, { useMemo } from "react";
import CalendarDragLayer from "../DragAndDrop/CalendarDragLayer";

const { useDragLayer } = require("react-dnd");

function InboxToCalendarDragLayer({ draggedItemId }) {
  const { item, currentOffset, isDragging, initialOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const calendarLayerStyles = useMemo(
    () => ({
      position: "fixed",
      pointerEvents: "none",
      zIndex: 1000,
      left: 0,
      top: 0,
      transform: currentOffset
        ? `translate(${currentOffset.x - 1}px, ${currentOffset.y - 1}px)`
        : undefined,
      borderRadius: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "8px",
      boxSizing: "border-box",
      cursor: "pointer",
    }),
    [currentOffset]
  );

  if (
    !item ||
    !isDragging ||
    item.dragType !== "inbox-calendar-item" ||
    !currentOffset ||
    !initialOffset ||
    item.data.id !== draggedItemId
  ) {
    return null;
  }

  return (
    <div style={calendarLayerStyles} key={item.data.id}>
      <CalendarDragLayer data={item.data} />
    </div>
  );
}

export default React.memo(InboxToCalendarDragLayer);
