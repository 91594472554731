import CalendarBlockCheckbox from "./CalendarBlockCheckbox";
import CalendarBlockTime from "./CalendarBlockTime";
import CalendarBlockTitle from "./CalendarBlockTitle";
import CalendarBlockIconSection from "./CalendarBlockIconSection";
import { CalendarBlockType } from "../../../types/block/BlockType";
import { projectListState } from "../../../recoil/projects/projectListState";
import { useRecoilValue } from "recoil";
import { getSpaceColor } from "../../../services/space/space.service";
import { DEFAULT_SPACE_COLOR } from "../../../constants/space";
import CalendarBlockContainer from "./CalendarBlockContainer";
import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { defaultDurationState } from "../../../recoil/calendar/settingCalendar";

interface CalendarBlockProps {
  data: CalendarBlockType;
}

const CalendarBlock = ({ data }: CalendarBlockProps) => {
  /**
   * 블록 UI만을 위한 컴포넌트
   *
   * width, height는 드래그 용으로 존재(이때에만 너비 높이 조절 필요, 나머지는 자동 조절됨)
   * blockSize는 15분 블럭과 나머지 구별하는 용도
   * - small : 15분 || allday
   * - large: 15분 이상
   *
   */
  const projectList = useRecoilValue(projectListState);
  const baseColor = data.projectId
    ? getSpaceColor(projectList, data.projectId)
    : DEFAULT_SPACE_COLOR;

  const [finalWidth, setFinalWidth] = useState(0);
  const [itemHeight, setItemHeight] = useState(0);
  const [blockSize, setBlockSize] = useState<"small" | "large">("small");
  const defaultDuration = useRecoilValue(defaultDurationState);

  useEffect(() => {
    const container = document.querySelector(".rbc-event");
    if (container) {
      setFinalWidth(container.clientWidth);
    }

    const heightInPixels = (Number(defaultDuration) / 15) * 20;
    setItemHeight(heightInPixels);
    setBlockSize(heightInPixels <= 20 ? "small" : "large");

    if (heightInPixels <= 20) {
      setBlockSize("small");
    }

    setItemHeight(heightInPixels);
  }, [data.allDay, data.end, data.start, defaultDuration]);

  return (
    <CalendarBlockContainer
      width={finalWidth}
      height={itemHeight}
      blockSize={blockSize}
      projectColor={baseColor ?? "#ababad"}
      isCompleted={data.itemStatus === "Completed"}
    >
      <Flex gap={1.5} justifyContent={"space-between"} alignItems={"flex-start"} w={"full"}>
        <CalendarBlockCheckbox baseColor={baseColor ?? DEFAULT_SPACE_COLOR} />
        <Flex flexDir={"column"} flex={1} w={"full"} noOfLines={1}>
          <CalendarBlockTitle
            title={data.title}
            isCreateSelectEvent={data.isCreateSelectEvent ?? false}
            taskType={data.taskType}
          />
          {blockSize !== "small" && (
            <CalendarBlockTime start={data.start} end={data.end} isOnlyDuration={true} />
          )}
        </Flex>
        <CalendarBlockIconSection
          priority={data.priority}
          visibility={data.visibility}
          transparency={data.transparency}
          integration={data.integration}
        />
      </Flex>
    </CalendarBlockContainer>
  );
};

export default CalendarBlock;
