import { Flex } from "@chakra-ui/react";
import InboxHeaderTabButton from "./InboxHeaderTabButton";
import InboxHeaderFilterButton from "./InboxHeaderFilterButton";

import { pxToRem } from "../../../utils/styles/size";
import { HandleTabToggleVisibility } from "..";
import { InboxHeaderTab, ToggleVisibilityByTab } from "../../../types/inbox/inbox-headers";

interface InboxHeaderProps {
  handleChangeActiveTab: (event: React.MouseEvent<HTMLButtonElement>) => void;
  activeTab: InboxHeaderTab;
  onToggleVisibility: HandleTabToggleVisibility;
  toggleVisibility: ToggleVisibilityByTab;
  inboxTodayCount: { inbox: number; today: number };
}

const InboxHeader = ({
  handleChangeActiveTab,
  activeTab,
  onToggleVisibility,
  toggleVisibility,
  inboxTodayCount,
}: InboxHeaderProps) => {
  return (
    <Flex
      w={"full"}
      h={pxToRem(50)}
      padding={"0 10px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      flexShrink={0}
    >
      <Flex gap={pxToRem(10)}>
        <InboxHeaderTabButton
          title="Inbox"
          count={inboxTodayCount.inbox}
          onClick={handleChangeActiveTab}
          activeTab={activeTab}
        />
        <InboxHeaderTabButton
          title="Today"
          count={inboxTodayCount.today}
          onClick={handleChangeActiveTab}
          activeTab={activeTab}
        />
      </Flex>

      <InboxHeaderFilterButton
        activeTab={activeTab}
        onToggleVisibility={onToggleVisibility}
        toggleVisibility={toggleVisibility}
      />
    </Flex>
  );
};
export default InboxHeader;
