import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { pxToRem } from "../../../../utils/styles/size";
import { useStandardDateTimeToTime } from "../../../../hooks/time-formatting/useStandardDateTimeToTime";
import { standardDateTimeToDate } from "../../../../utils/date-format/standardDateTimeToDate";

interface InboxBlockDateTimeProps {
  isOverdue: boolean;
  isTodayGeneral: boolean;
  isAllday: boolean;
  startDateTime: string;
  endDateTime: string;
}
const InboxBlockDateTime = ({
  isOverdue,
  isTodayGeneral,
  isAllday,
  startDateTime,
  endDateTime,
}: InboxBlockDateTimeProps) => {
  const standardDateTimeToTime = useStandardDateTimeToTime();
  const displayDateTime = (isAllday: boolean, startDateTime: string, endDateTime: string) => {
    // if (isAllDay) {
    //   // endDateTime에서 하루를 빼서 계산
    //   const endDate = new Date(endDateTime);
    //   // endDate.setDate(endDate.getDate() - 1);

    // const startDate = standardDateTimeToDate(startDateTime);
    //   const adjustedEndDate = standardDateTimeToDate(endDate.toISOString());

    //   return startDate !== adjustedEndDate ? `${startDate} - ${adjustedEndDate}` : startDate;
    // }

    // // 종일이 아닌 경우
    // const startDate = standardDateTimeToDate(startDateTime);
    // const endDate = standardDateTimeToDate(endDateTime);

    // return startDate !== endDate
    //   ? `${startDate} - ${endDate}` // 날짜가 다르면 날짜 범위 표시
    //   : standardDateTimeToTime(startDateTime); // 날짜가 같으면 시작 시간만 표시
    return standardDateTimeToTime(startDateTime);
  };

  const displayDate = (startDateTime: string, endDateTime: string) => {
    const startDate = standardDateTimeToDate(startDateTime);
    const endDate = standardDateTimeToDate(endDateTime);

    return startDate !== endDate
      ? `${startDate} - ${endDate}` // 날짜가 다르면 날짜 범위 표시
      : standardDateTimeToDate(startDateTime); // 날짜가 같으면 시작 시간만 표시
  };

  return (
    <Box
      padding={"0 2px"}
      flexShrink={0}
      alignSelf={"flex-start"}
      justifyItems={"center"}
      width={"max-content"}
    >
      <Flex
        backgroundColor={"rgba(0, 0, 0, 0.2)"}
        borderRadius={"3px"}
        px={pxToRem(2)}
        h={pxToRem(14)}
      >
        <Text
          color={isOverdue ? "#FF6464" : "#abadad"}
          fontSize={pxToRem(10)}
          fontStyle="normal"
          fontWeight={400}
          lineHeight="140%"
        >
          {isTodayGeneral
            ? displayDateTime(isAllday, startDateTime, endDateTime)
            : displayDate(startDateTime, endDateTime)}
        </Text>
      </Flex>
    </Box>
  );
};

export default InboxBlockDateTime;
