import { createPortal } from "react-dom";
import TaskDetail from "./TaskDetail";
import { taskPopupState } from "../recoil/taskDetail/taskPopupState";
import { useRecoilValue } from "recoil";

export const TaskDetailWrapper = ({ guestPopupRef, recurringPopupRef }) => {
  const taskDetail = useRecoilValue(taskPopupState);

  return (
    <>
      {taskDetail.isVisible &&
        createPortal(
          <TaskDetail
            key={taskDetail.data.id}
            initialData={taskDetail.data}
            guestPopupRef={guestPopupRef}
            recurringPopupRef={recurringPopupRef}
          />,
          document.body
        )}
    </>
  );
};
