import { useSetRecoilState } from "recoil";
import moment from "moment";

import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { expandRecurringEvent } from "../../utils/calendar/convertRruletoDates";
import { areDateTimeEqual } from "../../utils/common/dateTime/areDateTimeEqual";
import { areDateEqual } from "../../utils/common/dateTime/areDateEqual";
import { convertGoogleCalendarToMobaCalendar } from "../../utils/calendar/convertGoogleCalendarToMobaCalendar";
import { useHandleBlockDelete } from "./useHandleBlockDelete";
import { CalendarBlockType } from "../../types/block/BlockType";

interface useHandleBlockChangeProps {
  calendarLastMonth: string | null;
}
export function useHandleBlockChange({ calendarLastMonth }: useHandleBlockChangeProps) {
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  const { handleEventDelete } = useHandleBlockDelete();

  const handleEventChange = (
    eventData: CalendarBlockType,
    oldStartDate: string = "",
    oldEndDate: string = "",
    selectedOption: string = "",
    isFirstCreated: boolean = false,
    initialRecurrence: string[] | null = []
  ) => {
    const startString = eventData.start;
    const endString = eventData.end;
    const startTimeZoneString = eventData.startTimeZone || null;
    const endTimeZoneString = eventData.endTimeZone || null;

    const newStartDate = new Date(startString);
    const newEndDate = new Date(endString);

    const oldStart = new Date(oldStartDate);
    const oldEnd = new Date(oldEndDate);

    const isSameDate = areDateEqual(newStartDate, oldStart) && areDateEqual(newEndDate, oldEnd);

    const isMovedToAllDay = eventData.allDay || null;
    const isDateOnlyFormat = (date: string) => /^(\d{4})-(\d{2})-(\d{2})$/.test(date);
    const isMovedFromAllday = isFirstCreated ? isMovedToAllDay : isDateOnlyFormat(oldStartDate);

    // 반복 이벤트 변경 처리
    if (
      initialRecurrence &&
      initialRecurrence?.length > 0 &&
      initialRecurrence !== eventData.recurrence
    ) {
      if (selectedOption === "all") {
        handleEventDelete(eventData.id, newStartDate, eventData.recurringEventId, "all");

        setMobaCalendarList((current) => {
          let updatedEvents = [...current];
          const eventIndex = updatedEvents.findIndex((event) => event.id === eventData.id);
          let expandedEvent = expandRecurringEvent(eventData, calendarLastMonth);

          if (eventIndex !== -1) {
            updatedEvents.splice(eventIndex, 1);
          }

          expandedEvent = expandedEvent.map((event) => ({
            ...event,
            id: event.id,
            start:
              (isMovedFromAllday
                ? moment(event.start.date).format("YYYY-MM-DD")
                : new Date(event.start.dateTime)) || event.start,
            end:
              (isMovedFromAllday
                ? moment(event.end.date).format("YYYY-MM-DD")
                : new Date(event.end.dateTime)) || event.end,
          }));

          updatedEvents = [...updatedEvents, ...expandedEvent];
          return updatedEvents;
        });
      } else if (selectedOption === "from") {
        handleEventDelete(eventData.id, newStartDate, eventData.recurringEventId, "from");

        setMobaCalendarList((current) => {
          let updatedEvents = [...current];
          const eventIndex = updatedEvents.findIndex((event) => event.id === eventData.id);
          let expandedEvent = expandRecurringEvent(eventData, calendarLastMonth);

          if (eventIndex !== -1) {
            updatedEvents.splice(eventIndex, 1);
          }

          expandedEvent = expandedEvent.map((event) => ({
            ...event,
            id: event.id,
            start:
              (isMovedFromAllday
                ? moment(event.start.date).format("YYYY-MM-DD")
                : new Date(event.start.dateTime)) || event.start,
            end:
              (isMovedFromAllday
                ? moment(event.end.date).format("YYYY-MM-DD")
                : new Date(event.end.dateTime)) || event.end,
          }));

          updatedEvents = [...updatedEvents, ...expandedEvent];
          return updatedEvents;
        });
      }
    }

    setMobaCalendarList((current) => {
      let updatedEvents: CalendarBlockType[] = [...current];

      if (selectedOption === "all") {
        // 모든 관련 이벤트 업데이트
        updatedEvents = updatedEvents.map((event: CalendarBlockType) => {
          const eventStartDateTime = new Date(event.start);
          const eventEndDateTime = new Date(event.end);

          const { id, allDay, start, end, ...changedEventData } = { ...eventData };
          if (
            event.recurringEventId === eventData.recurringEventId ||
            event.id == eventData.recurringEventId ||
            eventData.id === event.recurringEventId
          ) {
            // allDay 변경 처리

            if (!isMovedFromAllday && isMovedToAllDay) {
              const updatedEvent = {
                ...changedEventData,
                id: event.id,
                allDay: true,
                start: moment(eventStartDateTime).format("YYYY-MM-DD"),
                end: moment(eventEndDateTime).format("YYYY-MM-DD"),
              };

              return updatedEvent;
            }

            let updatedStartDate, updatedEndDate;

            if (isSameDate) {
              updatedStartDate = new Date(
                eventStartDateTime.setHours(newStartDate.getHours(), newStartDate?.getMinutes())
              );
              updatedEndDate =
                isMovedFromAllday || isFirstCreated
                  ? new Date(
                      eventStartDateTime.setHours(newEndDate?.getHours(), newEndDate?.getMinutes())
                    )
                  : new Date(
                      eventEndDateTime.setHours(newEndDate?.getHours(), newEndDate?.getMinutes())
                    );
            } else {
              // 날짜 변경 처리
              const daysDifference =
                (newStartDate.getTime() - oldStart.getTime()) / (1000 * 60 * 60 * 24);
              updatedStartDate = new Date(eventStartDateTime);
              updatedEndDate = new Date(eventEndDateTime);

              updatedStartDate.setDate(eventStartDateTime.getDate() + daysDifference);
              updatedEndDate.setDate(eventEndDateTime.getDate() + daysDifference);
            }

            return {
              ...eventData,
              id: event.id,
              start: updatedStartDate,
              end: updatedEndDate,
            };
          }
          return event;
        });
      } else if (selectedOption === "from") {
        const selectedEventStart = oldStartDate
          ? new Date(oldStartDate)
          : newStartDate.setHours(0, 0, 0, 0);

        const originalEventStart = new Date(oldStartDate);
        const updatedEventStart = newStartDate;

        const isStartTimeChanged = !areDateTimeEqual(originalEventStart, updatedEventStart);

        if (isSameDate) {
          updatedEvents = updatedEvents.map((event) => {
            const convertedDateStart = event.start;
            const finalizedDateStart = new Date(convertedDateStart);

            const eventStart = new Date(event.start);
            const eventEnd = new Date(event.end);

            // allDay 변경 처리
            if (!isMovedFromAllday && isMovedToAllDay) {
              if (
                (event.recurringEventId === eventData.recurringEventId ||
                  event.id == eventData.recurringEventId ||
                  eventData.id === event.recurringEventId) &&
                moment(finalizedDateStart).isSameOrAfter(selectedEventStart, "day")
              )
                return {
                  ...eventData,
                  id: event.id,
                  start: moment(finalizedDateStart).format("YYYY-MM-DD"),
                  end: moment(eventEnd).format("YYYY-MM-DD"),
                  recurringEventId: isStartTimeChanged ? eventData.id : eventData.recurringEventId,
                };
            }

            if (
              (event.recurringEventId === eventData.recurringEventId ||
                event.id == eventData.recurringEventId ||
                eventData.id === event.recurringEventId) &&
              eventStart >= selectedEventStart
            ) {
              const eventStartDateTime = new Date(event.start);
              const eventEndDateTime = new Date(event.end);

              let updatedStartDate, updatedEndDate;

              updatedStartDate = new Date(
                eventStartDateTime.setHours(newStartDate.getHours(), newStartDate?.getMinutes())
              );
              updatedEndDate = isMovedFromAllday
                ? new Date(
                    eventStartDateTime.setHours(newEndDate?.getHours(), newEndDate?.getMinutes())
                  )
                : new Date(
                    eventEndDateTime.setHours(newEndDate?.getHours(), newEndDate?.getMinutes())
                  );

              return {
                ...eventData,
                id: event.id,
                start: updatedStartDate,
                end: updatedEndDate,
                recurringEventId: isStartTimeChanged ? eventData.id : eventData.recurringEventId,
              };
            }
            return event;
          });
        } else {
          // 날짜 변경 처리
          if (eventData.allDay) {
            const convertedDateEventData = {
              ...eventData,
              allDay: true,
              start: {
                date: moment(newStartDate).format("YYYY-MM-DD"),
              },
              end: {
                date: moment(newEndDate).format("YYYY-MM-DD"),
              },
              recurringEventId: isStartTimeChanged ? eventData.id : eventData.recurringEventId,
            };

            const expandedNewRecurringEvents = expandRecurringEvent(
              convertedDateEventData,
              calendarLastMonth
            )
              .filter((event) => event != null)
              .map((event) => ({
                ...event,
                start: event.start.date,
                startTimeZone: null,
                end: event.end.date,
                endTimezone: null,
                recurringEventId: isStartTimeChanged ? eventData.id : eventData.recurringEventId,
              }));

            updatedEvents = updatedEvents.filter((event) => {
              const isEventAfterOldStartDate = moment(new Date(event.start)).isAfter(
                moment(new Date(oldStartDate)),
                "day"
              );
              return !(
                event.recurringEventId === eventData.recurringEventId &&
                event.id !== eventData.id &&
                isEventAfterOldStartDate
              );
            });

            const filteredEvents = expandedNewRecurringEvents.filter((event) => {
              const isSameStartDate = moment(event.start).isSame(moment(newStartDate), "day");
              const isSameEndDate = moment(event.end).isSame(moment(newEndDate), "day");
              return !(isSameStartDate && isSameEndDate);
            });

            updatedEvents = [...updatedEvents, ...filteredEvents];
          } else {
            const convertedDateEventData = {
              ...eventData,
              allDay: false,
              start: {
                dateTime: newStartDate,
                timeZone: startTimeZoneString,
              },
              end: {
                dateTime: newEndDate,
                timeZone: endTimeZoneString,
              },
              recurringEventId: isStartTimeChanged ? eventData.id : eventData.recurringEventId,
            };

            const expandedNewRecurringEvents = expandRecurringEvent(
              convertedDateEventData,
              calendarLastMonth
            )
              .filter((event) => event != null)
              .map((event) => ({
                ...event,
                start: new Date(event.start.dateTime),
                startTimeZone: event.start.timeZone,
                end: new Date(event.end.dateTime),
                endTimezone: event.start.timeZone,
                recurringEventId: isStartTimeChanged ? eventData.id : eventData.recurringEventId,
              }));

            const filteredEvents = expandedNewRecurringEvents.filter((event) => {
              return !(
                event.start.getTime() === new Date(newStartDate).getTime() &&
                event.end.getTime() === new Date(newEndDate).getTime()
              );
            });

            updatedEvents = updatedEvents.filter((event) => {
              return !(
                event.recurringEventId === eventData.recurringEventId &&
                event.id !== eventData.id &&
                new Date(event.start)?.getDate() > new Date(oldStartDate)?.getDate()
              );
            });

            updatedEvents = [...updatedEvents, ...filteredEvents];
          }
        }
      } else {
        const eventIndex = updatedEvents.findIndex((event) => event.id === eventData.id);

        if (eventIndex !== -1) {
          const { isCreateSelectEvent, isDataDuplicateEvent, ...restEventData } = eventData;
          const convertEventData = convertGoogleCalendarToMobaCalendar(restEventData);
          updatedEvents[eventIndex] = { ...updatedEvents[eventIndex], ...convertEventData };
        }

        if (isFirstCreated && eventData?.recurrence && eventData?.recurrence?.length > 0) {
          let expandedEvent = expandRecurringEvent(eventData, calendarLastMonth);

          if (eventIndex !== -1) {
            updatedEvents.splice(eventIndex, 1);
          }

          expandedEvent = expandedEvent.map((event) => ({
            ...event,
            id: event.id,
            start:
              (isMovedFromAllday
                ? moment(event.start.date).format("YYYY-MM-DD")
                : new Date(event.start.dateTime)) || event.start,
            end:
              (isMovedFromAllday
                ? moment(event.end.date).format("YYYY-MM-DD")
                : new Date(event.end.dateTime)) || event.end,
          }));

          updatedEvents = [...updatedEvents, ...expandedEvent];
        }
      }

      return updatedEvents;
    });
  };

  return { handleEventChange };
}
