import { useMutation } from "@tanstack/react-query";
import useApi from "../services/auth/useApi";
import useFetchCalendarEvents from "../hooks/useFetchCalendarEvents";

import { deleteNullValueInObject } from "../utils/taskDetail/formatServerSendData";
import { ServerCalendarBlockType } from "../types/block/BlockType";

interface UpdateRecurrenceBlockApiProps {
  api: ReturnType<typeof useApi>;
  updatedBlockData: ServerCalendarBlockType;
  selectedOption: "current" | "from" | "all";
  notify?: boolean;
  creator: string;
}

export const updateRecurrenceBlock = async ({
  api,
  updatedBlockData,
  selectedOption,
  notify,
  creator,
}: UpdateRecurrenceBlockApiProps): Promise<void> => {
  // TODO 추후 eventType으로 변경

  try {
    const nullDeletedData = deleteNullValueInObject(updatedBlockData);

    const meetingCode = updatedBlockData.hangoutLink?.replace("https://meet.google.com/", "");
    const finalizedBlockData = updatedBlockData.hangoutLink
      ? { ...nullDeletedData, meetingCode }
      : nullDeletedData;

    await api.patch(`tasks/recurrence`, finalizedBlockData, {
      params: {
        eventId: finalizedBlockData.id,
        option: selectedOption,
        ...(notify ? { notification: notify } : {}),
      },
      headers: { "X-Requester": creator },
    });
  } catch (error) {
    throw new Error(`Failed to update recurrence block: ${error}`);
  }
};

export const useUpdateRecurrenceBlock = () => {
  const api = useApi();
  const { invalidateCalendarEvents } = useFetchCalendarEvents();

  return useMutation<void, Error, UpdateRecurrenceBlockApiProps>({
    mutationFn: ({
      updatedBlockData,
      selectedOption,
      notify,
      creator,
    }: UpdateRecurrenceBlockApiProps) =>
      updateRecurrenceBlock({ api, updatedBlockData, selectedOption, notify, creator }),
    onSuccess: () => {
      invalidateCalendarEvents();
    },
    onError: (error) => {
      console.error("Error in useUpdateRecurrenceBlock Mutation:", error);
    },
  });
};
