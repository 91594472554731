export const isValidUrl = (string: string): boolean => {
  try {
    // 1. URL 객체 생성 시도
    const url = new URL(string);

    // 2. 프로토콜 검사
    if (!["http:", "https:"].includes(url.protocol)) {
      return false;
    }

    // 3. 호스트네임 검사
    // - 최소 하나의 점(.)이 있어야 함
    // - 각 부분은 알파벳, 숫자, 하이픈만 허용
    // - 하이픈으로 시작하거나 끝나지 않아야 함
    const hostnameRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z0-9-]{1,63})*$/;
    if (!hostnameRegex.test(url.hostname)) {
      return false;
    }

    // 4. 최상위 도메인(TLD) 검사
    const tldRegex = /\.[a-z]{2,}$/i;
    if (!tldRegex.test(url.hostname)) {
      return false;
    }

    // 5. 경로 및 쿼리스트링 문자 검사
    const invalidChars = /[<>{}|\^~\[\]`]/;
    if (invalidChars.test(url.pathname) || invalidChars.test(url.search)) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};
