import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "./LinkAddModal.module.css";

export default function LinkAddModal({ selectedItem, onSave, onClose }) {
  const mainRef = useRef(null);
  const inputUrlRef = useRef(null);
  const inputPageRef = useRef(null);
  const fileSelectRef = useRef(null);
  const [headerMenu, setHeaderMenu] = useState("link");
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentUrl, setCurrentUrl] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleInputChange = (e) => {
    const urlValue = e.target.value.trim();

    setCurrentUrl(urlValue);
  };

  const handleSave = async () => {
    if (!currentUrl) {
      onClose();
      return;
    }

    onSave(
      headerMenu === "link" ? "links" : "files",
      {
        name: "",
        url: currentUrl,
        isLoading: true,
      },
      selectedItem ? selectedItem.index : undefined
    );
    onClose();
  };

  const handleInputKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      handleSave();
    } else if (e.key === "Escape") {
      e.stopPropagation();
      onClose();
    }
  };

  const handleFileSelect = () => {
    fileSelectRef.current.click();
  };

  const handleFileCancel = () => {
    if (fileSelectRef.current) {
      fileSelectRef.current.value = null;
    }
    setSelectedFile(null);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleInputKeyDown);

    inputUrlRef.current.focus();
    function handleClickOutside(event) {
      if (mainRef.current && !mainRef.current.contains(event.target)) {
        onClose();
      }
    }
    if (mainRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("keydown", handleInputKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return createPortal(
    <div className={styles["wrap"]} onMouseDown={(e) => e.stopPropagation()}>
      <div className={styles["main"]} ref={mainRef}>
        <div className={styles["header"]}>
          <div
            className={`${styles["header-title"]} ${
              headerMenu == "file" && styles["header-title-file"]
            }`}
          >
            <span
              className={`${headerMenu != "link" && styles["header-off"]}`}
              onClick={() => {
                setHeaderMenu("link");
                setSelectedFile(null);
              }}
            >
              Link
            </span>
            {/* <span className={`${headerMenu!="file" && styles['header-off']}`} onClick={()=>setHeaderMenu("file")}>File</span> */}
          </div>
          <span className={styles["header-close"]} onClick={onClose}>
            X
          </span>
        </div>
        <div className={styles["body"]}>
          {headerMenu == "link" && (
            <>
              <input
                placeholder="URL"
                ref={inputUrlRef}
                className={styles["body-input-url"]}
                onKeyDown={handleInputKeyDown}
                onChange={handleInputChange} // URL 변경 시 페이지 이름 가져오기
              />
              <input
                placeholder="Page Name"
                ref={inputPageRef}
                className={styles["body-input-name"]}
                onKeyDown={handleInputKeyDown}
              ></input>
            </>
          )}
          {headerMenu == "file" && (
            <>
              {selectedFile ? (
                <div className={styles["body-file-btn"]}>
                  <div className={styles["body-file-icon"]}></div>
                  <span className={styles["body-file-title"]}>{selectedFile.name}</span>
                  <button className={styles["body-file-cancel"]} onClick={handleFileCancel}>
                    X
                  </button>
                </div>
              ) : (
                <>
                  <div className={styles["body-fileSelect-btn"]} onClick={handleFileSelect}>
                    Choose a file
                  </div>
                  <input
                    type="file"
                    ref={fileSelectRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  ></input>
                </>
              )}
            </>
          )}
        </div>
        <div className={styles["footer"]} onClick={handleSave}>
          Save
        </div>
      </div>
    </div>,
    document.body
  );
}
