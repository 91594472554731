import { Box, Flex, IconButton, Link } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { ReactComponent as BlockLinkIcon } from "../../../assets/Integration/blockLink.svg";
import iconForVisibility from "../../../utils/taskDetail/visibility/iconForVisibility";
import { priorityOptions } from "../../../constants/task/TaskPriority";
import {
  defaultPriorityIcon,
  getBasicStyles,
} from "../../../views/TaskDetail/Header/PriorityButton";
import { TaskPriorityEnum } from "../../../types/inbox/inbox-headers";

interface CalendarBlockIconSectionProps {
  visibility?: string;
  transparency?: string;
  integration?: {
    provider: string;
    link: string;
  };
  priority?: TaskPriorityEnum;
}

const CalendarBlockIconSection = ({
  visibility,
  transparency,
  integration,
  priority,
}: CalendarBlockIconSectionProps) => {
  const currentPriorityIcon = priorityOptions.find((option) => {
    return option.value === priority;
  })?.icon;
  return (
    <Flex gap={1} alignItems="flex-start" py={0.5}>
      {visibility === "private" && (
        <Flex alignItems="center">{iconForVisibility(visibility, transparency)}</Flex>
      )}

      {currentPriorityIcon && (
        <Flex alignItems="center">
          <IconButton
            icon={currentPriorityIcon}
            boxSizing={"border-box"}
            aria-label={`Priority`}
            verticalAlign={"start"}
            p={0}
            flexShrink={1}
            h={pxToRem(14)}
            w={pxToRem(14)}
            variant="outline"
            fontSize={pxToRem(14)}
            borderRadius={pxToRem(3)}
            minWidth={"initial"}
            boxSize={pxToRem(14)}
            _active={{}}
            _focus={{
              outline: "none",
              boxShadow: "none",
            }}
            cursor={"initial"}
            _hover={{ bgColor: "black" }}
            {...getBasicStyles(false, priority)}
          />
        </Flex>
      )}

      {integration?.provider && (
        <Link
          href={integration.link}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
          display="flex"
          alignItems="center"
        >
          <BlockLinkIcon />
          <Box
            width={pxToRem(16)}
            height={pxToRem(16)}
            bgImage={`url(../../assets/Main/${integration.provider}-icon.svg)`}
            bgSize="contain"
            bgRepeat="no-repeat"
          />
        </Link>
      )}
    </Flex>
  );
};

export default CalendarBlockIconSection;
