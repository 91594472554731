import { Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../../utils/styles/size";
import {
  CalendarBlockType,
  InboxBlockPositionType,
  InboxBlockStatusType,
  InboxBlockType,
  isCalendarBlock,
} from "../../../../types/block/BlockType";
import InboxSubTaskToggle from "./InboxSubTaskToggle";
import InboxBlockBody from "./InboxBlockBody";

import { useState, memo } from "react";

import { ContextMenu } from "../../../Common/ContextMenu";

import { useInboxToCalendarDragAndDrop } from "../../../../hooks/inbox/useInboxToCalendarDragAndDrop";

interface InboxBlockProps {
  data: InboxBlockType | CalendarBlockType;
  onDataDuplicate: (e: React.MouseEvent, data: InboxBlockType | CalendarBlockType) => void;
  onDataDelete: (data: InboxBlockType | CalendarBlockType) => void;
  isTodayGeneral?: boolean;
  index: number;
  onReorder?: (payload: string[]) => void;
  status: InboxBlockStatusType;
  position: InboxBlockPositionType;
  isSelected: boolean;
  onSelect: (id: string | null) => void;
  onClickBlock: (
    e: React.MouseEvent,
    data: InboxBlockType | CalendarBlockType,
    ref: React.RefObject<HTMLDivElement>
  ) => void;
}

const InboxBlock = memo(
  ({
    data,
    onDataDuplicate,
    onDataDelete,
    index,
    onReorder,
    isTodayGeneral,
    status,
    position,
    isSelected,
    onSelect,
    onClickBlock,
  }: InboxBlockProps) => {
    const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);
    const [prevIndex, setPrevIndex] = useState(index);

    const { ref, isDragging: shouldShowPreview } = useInboxToCalendarDragAndDrop({
      index,
      data,
      // prevIndex,
      // setPrevIndex,
    });

    if (!data) return null;

    const handleDelete = () => {
      if (!data) return;
      setIsSidebarModalOn(false);
      onDataDelete(data);
    };

    const handleDuplicate = (e: React.MouseEvent) => {
      if (!data) return;
      setIsSidebarModalOn(false);

      if (isCalendarBlock(data)) {
        const { recurrence, recurringEventId, recurringNoteId, ...rest } = data;
        onDataDuplicate(e, rest);
      } else {
        onDataDuplicate(e, data);
      }
    };

    const handleClose = () => {
      onSelect(null);
      setIsSidebarModalOn(false);
    };

    const handleItemContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      onSelect(data.id);
      setIsSidebarModalOn(true);
    };

    const menuItems = [
      {
        label: "Duplicate",
        onClick: handleDuplicate,
      },
      {
        label: "Delete",
        onClick: handleDelete,
      },
    ];

    return (
      <Flex
        ref={ref}
        w={"full"}
        border={"1px solid"}
        borderRadius={pxToRem(6)}
        bg={shouldShowPreview || isSelected ? "whiteAlpha.50" : "initial"}
        borderColor={isSelected ? "whiteAlpha.50" : "transparent"}
        _hover={{ backgroundColor: "whiteAlpha.50" }}
        cursor={"pointer"}
        onClick={(e) => onClickBlock(e, data, ref)}
        onContextMenu={handleItemContextMenu}
        draggable="true"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Flex opacity={shouldShowPreview ? 0 : 1} w={"full"}>
          <InboxSubTaskToggle />
          <InboxBlockBody
            data={data}
            isOverdue={status === "Overdue"}
            isTodayGeneral={
              status === InboxBlockStatusType.Today && position === InboxBlockPositionType.General
            }
            isCompleted={status === "Completed"}
          />
        </Flex>
        {isSidebarModalOn && (
          <ContextMenu isOpen={isSidebarModalOn} onClose={handleClose} menuItems={menuItems} />
        )}
      </Flex>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.data === nextProps.data &&
      prevProps.isSelected === nextProps.isSelected &&
      prevProps.index === nextProps.index &&
      prevProps.status === nextProps.status &&
      prevProps.position === nextProps.position
    );
  }
);

InboxBlock.displayName = "InboxBlock";

export default InboxBlock;
