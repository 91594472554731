import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilValue } from "recoil";
import { CalendarViewType } from "../../constants";
import { meetWithAccountsState } from "../../recoil/account/accountState";
import { calendarViewState } from "../../recoil/calendar/calendarState";

import iconForVisibility from "../../utils/taskDetail/visibility/iconForVisibility";

import formatTime from "../../utils/common/dateTime/formatTime";
import { ReactComponent as BlockLinkIcon } from "../../assets/Integration/blockLink.svg";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";

import "./CustomEvent.css";
import styles from "./CustomEvent.module.css";
import formatTimeInterval from "../../utils/common/dateTime/formatTimeInterval";
import { getSpaceColor } from "../../services/space/space.service";
import { projectListState } from "../../recoil/projects/projectListState";
import { DEFAULT_SPACE_COLOR } from "../../constants/space";

import { getBasicStyles } from "../../views/TaskDetail/Header/PriorityButton";
import { priorityOptions } from "../../constants/task/TaskPriority";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { pxToRem } from "../../utils/styles/size";
import { EventRSVPResponse } from "../../types/inbox/inbox-headers";
import { hexToRgba } from "../../utils/styles/color";
import { timeFormatState } from "../../recoil/calendar/settingCalendar";
import { useUpdateBlockStatusMutation } from "../../react-query/block/useUpdateBlockStatusMutation";

const getBlockStyle = (isDone, event, meetWithAccounts, projectList, eventHover, taskDetail) => {
  const shouldCheckRsvp =
    event.taskType === "Event" && event.attendees && event.attendees?.length > 0;
  const userResponse = event.attendResponse;
  const pendingRsvp =
    shouldCheckRsvp &&
    (userResponse === EventRSVPResponse.NeedsAction || userResponse === undefined);
  const tentativeRsvp = shouldCheckRsvp && userResponse === EventRSVPResponse.Tentative;
  const hoverOrAcvitve =
    eventHover || (taskDetail.isVisible && (taskDetail.data && taskDetail.data.id) === event.id);

  const hexColor =
    meetWithAccounts.length > 0
      ? event.isMeetWith
        ? event.backgroundColor
        : DEFAULT_SPACE_COLOR
      : getSpaceColor(projectList, event.projectId, event.color);
  const borderStyle = pendingRsvp ? `dashed` : `solid`;

  let alpha = event.taskType === "Task" ? 0.05 : 0.15;

  if (isDone) {
    alpha = event.taskType === "Task" ? 0.03 : 0.08;
  }

  if (hoverOrAcvitve) {
    if (isDone) {
      alpha = event.taskType === "Task" ? 0.13 : 0.18;
    } else {
      alpha = event.taskType === "Task" ? 0.15 : 0.25;
    }
  }

  if (meetWithAccounts.length > 0 && event.isMeetWith) {
    return {
      borderColor: "#ffffff",
      backgroundColor: hexColor,
    };
  }

  if (tentativeRsvp) {
    const rgbaColor = hexToRgba(hexColor, 0.1);
    const darkerColor = hexToRgba(hexColor, 0.05);

    return {
      borderColor: hexColor,
      borderStyle: "solid",
      borderWidth: "1px",
      backgroundImage: `repeating-linear-gradient(135deg, ${rgbaColor}, ${rgbaColor} 7px, ${darkerColor} 7px, ${darkerColor} 14px)`,
    };
  }

  return {
    borderColor: hexColor,
    borderStyle: borderStyle,
    borderWidth: "1px",
    backgroundColor: hexToRgba(hexColor, alpha),
  };
};

export default function CustomEvent({ event, loadData, onEventChange, localizer }) {
  const taskDetail = useRecoilValue(taskPopupState);
  const calendarView = useRecoilValue(calendarViewState);

  const projectList = useRecoilValue(projectListState);
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);

  const [rowIcon, setRowIcon] = useState(true);
  const [eventColor, setEventColor] = useState(null);
  const [integrationImg, setIntegrationImg] = useState(null);

  const eventRef = useRef(null);
  const tooltipRef = useRef(null);
  const [hover, setHover] = useState(false);
  const [eventHover, setEventHover] = useState(false);
  const [isTitleOverflow, setIsTitleOverflow] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState(null);

  const shouldCheckRsvp =
    event.taskType === "Event" && event.attendees && event.attendees?.length > 0;
  const userResponse = event.attendResponse;
  const notGoing = shouldCheckRsvp && userResponse === EventRSVPResponse.Declined;
  const timeFormat = useRecoilValue(timeFormatState);

  const { mutate: updateBlockStatus } = useUpdateBlockStatusMutation();
  const getDateTimeDiffMSec = (startDate, endDate) => {
    return new Date(endDate).getTime() - new Date(startDate).getTime();
  };

  const eventMinutes = getDateTimeDiffMSec(event.start, event.end) / (60 * 1000);

  useEffect(() => {
    if (projectList) {
      const projectColor = getSpaceColor(projectList, event.projectId, event.color);

      setEventColor(projectColor);
    }

    if (event.integration?.provider != null) {
      setIntegrationImg(event.integration.provider);
    } else {
      setIntegrationImg(null);
    }
  }, [event, projectList]);

  useEffect(() => {
    setRowIcon(true);
  }, [event]);

  const [isAnimationActive, setIsAnimationActive] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsAnimationActive(true);
    const isDone = event.itemStatus === "Completed";

    const convertData = {
      ...event,
      itemStatus: !isDone ? "Completed" : "InProgress",
    };

    setRowIcon(!rowIcon);

    updateBlockStatus(convertData);
  };

  const handleIconEnter = () => {
    setHover(true);
  };

  const handleIconLeave = () => {
    setHover(false);
  };

  const taskIsDone = useMemo(
    () => (event.taskType === "Task" && event.itemStatus === "Completed" ? true : false),
    [event]
  );
  const eventIsDone = useMemo(() => {
    if (event.taskType === "Event") {
      return event.allDay
        ? localizer.format(new Date(event.end), "YYYYMMDD") -
            localizer.format(new Date(), "YYYYMMDD") <
            1
        : getDateTimeDiffMSec(new Date(), event.end) < 0;
    }
    return false;
  }, [event, event.end, localizer]);

  const notDoneTask = useMemo(() => {
    if (event.isMeetWith) return false;

    const allDayDiff =
      localizer.format(new Date(event.end), "YYYYMMDD") ===
      localizer.format(new Date(event.start), "YYYYMMDD")
        ? 0
        : 1;

    const notDone = event.allDay
      ? localizer.format(new Date(event.end), "YYYYMMDD") -
          localizer.format(new Date(), "YYYYMMDD") <
        allDayDiff
      : getDateTimeDiffMSec(new Date(), event.end) < 0;

    return event.taskType === "Task" && event.itemStatus !== "Completed" && notDone;
  }, [event, event.end, localizer]);

  const eventStyle = getBlockStyle(
    taskIsDone || eventIsDone || notGoing,
    event,
    meetWithAccounts,
    projectList,
    eventHover,
    taskDetail
  );

  const checkboxStyle = useMemo(() => {
    const baseColor =
      meetWithAccounts.length === 0 && eventColor ? eventColor : DEFAULT_SPACE_COLOR;
    const isDone = event.itemStatus === "Completed";

    return {
      backgroundColor: hover ? baseColor : `${baseColor}${isDone ? "" : "33"} `,
      boxShadow: `0 0 0 1px ${baseColor} inset`,
    };
  }, [eventColor, hover, event.itemStatus, meetWithAccounts]);

  const handleTitleTooltip = (e) => {
    if (
      e.target.clientWidth >= e.target.scrollWidth &&
      e.target.clientHeight >= e.target.scrollHeight
    ) {
      return;
    }

    const calendarEl = document.querySelector(`.rbc-calendar`);
    const calendarBound = calendarEl.getBoundingClientRect();

    const style = {};
    const eventRefBound = eventRef?.current?.getBoundingClientRect();
    const tooltipBound = tooltipRef?.current?.getBoundingClientRect();

    const tooltipMaxWidth = calendarBound.width - 20;
    const tooltipWidth =
      tooltipBound.width > tooltipMaxWidth ? tooltipMaxWidth : tooltipBound.width;
    const defaultLeft = eventRefBound.left + eventRefBound.width / 2 - tooltipWidth / 2;

    style.maxWidth = tooltipMaxWidth;
    style.top = eventRefBound.top - 22 - 5;
    style.left = defaultLeft;

    // 툴팁 왼쪽이 잘리는경우
    if (defaultLeft < calendarBound.left) {
      style.left = calendarBound.left + 10;
    }

    // 툴팁 오른쪽이 잘리는경우
    if (defaultLeft + tooltipWidth > calendarBound.right) {
      style.left = calendarBound.right - tooltipWidth - 10;
    }

    setTooltipStyle(style);
    setIsTitleOverflow(true);
  };

  const getEventContainerSizeClass = useMemo(() => {
    if (calendarView === CalendarViewType.MONTH.type || event.allDay) {
      return styles.smallEvent;
    }
    if (eventMinutes <= 15) {
      return styles.smallEvent;
    }
    if (eventMinutes >= 45) {
      return styles.largeEvent;
    }
    return "";
  }, [calendarView, eventMinutes, event]);

  const currentPriorityIcon = priorityOptions.find((option) => {
    return option.value === event.priority;
  })?.icon;

  return (
    <>
      <div
        ref={eventRef}
        className={`event_wrapper ${styles.container}
        ${getEventContainerSizeClass}
        ${taskIsDone || eventIsDone || notGoing ? styles.eventDone : ""}
        ${notDoneTask ? styles.notDoneTask : ""}
        ${event.isMeetWith ? styles.meetWithContainer : ""}
        `}
        onMouseEnter={() => setEventHover(true)}
        onMouseLeave={() => setEventHover(false)}
      >
        <div
          className={`${styles["wrap"]} `}
          style={{
            backgroundColor: eventStyle.backgroundColor,
            backgroundImage: eventStyle.backgroundImage,
          }}
        >
          <span
            className={styles.eventBorder}
            style={{
              borderColor: eventStyle.borderColor,
              borderStyle: eventStyle.borderStyle,
              borderWidth: eventStyle.borderWidth,
            }}
          ></span>
          {event.isMeetWith ? (
            <div
              onMouseOver={handleTitleTooltip}
              onMouseLeave={() => setIsTitleOverflow(false)}
              className={styles.title}
            >
              {event.title ? event.title : `busy`}
            </div>
          ) : (
            <>
              {event.hangoutLink ? (
                <a
                  href={event.hangoutLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className={styles.meetLinkWrapper}
                >
                  <div className={styles.blockLinkIcon}>
                    <BlockLinkIcon />
                  </div>
                  <div className={styles["meet"]}></div>
                </a>
              ) : (
                event.taskType === "Task" && (
                  <Flex id="checkbox-wrapper" py={0.5} shrink={0}>
                    <div
                      className={`${styles["checkbox"]} ${
                        styles[`${event.itemStatus === "Completed" ? "checkboxCompleted" : null}`]
                      } ${isAnimationActive ? styles.animationActive : ""}`}
                      style={checkboxStyle}
                      onClick={handleClick}
                      onMouseEnter={handleIconEnter}
                      onMouseLeave={handleIconLeave}
                    ></div>
                  </Flex>
                )
              )}

              <div className={styles.contents}>
                <div
                  onMouseOver={handleTitleTooltip}
                  onMouseLeave={() => setIsTitleOverflow(false)}
                  className={styles["title"]}
                >
                  {event.title
                    ? event.title
                    : event.isCreateSelectEvent
                      ? `New ${event.taskType}`
                      : "No title"}
                </div>
                {calendarView !== CalendarViewType.MONTH.type &&
                  !event.allDay &&
                  eventMinutes >= 30 && (
                    <div className={styles.timeDuration}>
                      {`${formatTime(event.start, timeFormat)} - ${formatTime(event.end, timeFormat)} / ${formatTimeInterval(new Date(event.start), new Date(event.end))}`}
                    </div>
                  )}
              </div>

              <Flex
                justifyContent={
                  calendarView !== CalendarViewType.MONTH.type &&
                  !event.allDay &&
                  eventMinutes >= 30
                    ? "initial"
                    : "center"
                }
                gap={pxToRem(4)}
                py={0.5}
              >
                {event.visibility && event.visibility === "private" && (
                  <div className={styles.iconForVisibility}>
                    {iconForVisibility(event.visibility, event.transparency)}
                  </div>
                )}

                {currentPriorityIcon && (
                  <div className={styles.priorityButton}>
                    <IconButton
                      icon={currentPriorityIcon}
                      boxSizing={"border-box"}
                      aria-label={`Priority`}
                      verticalAlign={"start"}
                      p={0}
                      flexShrink={1}
                      h={pxToRem(14)}
                      w={pxToRem(14)}
                      variant="outline"
                      fontSize={pxToRem(14)}
                      borderRadius={pxToRem(3)}
                      minWidth={"initial"}
                      boxSize={pxToRem(14)}
                      _active={{}}
                      _focus={{
                        outline: "none",
                        boxShadow: "none",
                      }}
                      cursor={"initial"}
                      _hover={{ bgColor: "black" }}
                      {...getBasicStyles(false, event.priority)}
                    />
                  </div>
                )}
                {integrationImg && (
                  <a
                    href={event.integration?.link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    className={styles.integrationWrapper}
                  >
                    <div className={styles.blockLinkIcon}>
                      <BlockLinkIcon />
                    </div>
                    <div
                      className={`${styles["integration"]} ${
                        integrationImg === "gmail"
                          ? styles["integration-gmail"]
                          : integrationImg === "jira"
                            ? styles["integration-jira"]
                            : integrationImg === "slack" && styles["integration-slack"]
                      }
              `}
                    ></div>
                  </a>
                )}
              </Flex>
            </>
          )}
        </div>
      </div>
      {calendarView === CalendarViewType.MONTH.type ? (
        createPortal(
          <div
            ref={tooltipRef}
            className="event-tooltip"
            style={{
              left: tooltipStyle && tooltipStyle.left,
              top: tooltipStyle && tooltipStyle.top,
              maxWidth: tooltipStyle && tooltipStyle.maxWidth,
              opacity: isTitleOverflow && 1,
              height: isTitleOverflow && "auto",
              padding: isTitleOverflow && "4px",
            }}
          >
            {event.title ? event.title : `New ${event.taskType}`}
          </div>,
          document.querySelector("#event-tooltip")
        )
      ) : (
        <div
          ref={tooltipRef}
          className="event-tooltip"
          style={{
            left: tooltipStyle && tooltipStyle.left,
            top: tooltipStyle && tooltipStyle.top,
            maxWidth: tooltipStyle && tooltipStyle.maxWidth,
            opacity: isTitleOverflow && 1,
            height: isTitleOverflow && "auto",
            padding: isTitleOverflow && "4px",
          }}
        >
          {event.title ? event.title : `New ${event.taskType}`}
        </div>
      )}
    </>
  );
}
