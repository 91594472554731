import { useRef, useEffect, useCallback } from "react";
import { useDrag, DragSourceMonitor, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useSetRecoilState } from "recoil";
import { draggedEventState } from "../../recoil/calendar/calendarStateV2";
import { TaskDividerType } from "../../services/divider/divider.service";
import { isInboxBlock } from "../../services/inbox/inboxType";
import { InboxBlockType, CalendarBlockType, isCalendarBlock } from "../../types/block/BlockType";

export interface InboxDragItem {
  dragType: string;
  data: InboxBlockType | TaskDividerType | CalendarBlockType;
  index: number;
}

interface useDragAndDropProps {
  data: InboxBlockType | TaskDividerType | CalendarBlockType;
  index: number;
}

export const useInboxToCalendarDragAndDrop = ({ index, data }: useDragAndDropProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const setDraggedEvent = useSetRecoilState(draggedEventState);

  const getDragItem = useCallback(() => {
    const dragItem = {
      dragType: "inbox-calendar-item",
      data,
      index,
    };

    if (isCalendarBlock(data) || isInboxBlock(data)) {
      setDraggedEvent({
        ...data,
        dragType: "inbox-calendar-item",
        index: index,
        start: "",
        end: "",
        allDay: false,
        title: data.title,
        visibility: data.visibility,
        transparency: data.transparency,
        taskType: data.taskType,
      });
    }
    return dragItem;
  }, [data, index, setDraggedEvent]);

  const [draggingItem, drag, preview] = useDrag({
    type: "inbox",
    item: getDragItem,
    collect: useCallback((monitor: DragSourceMonitor) => {
      const item = monitor.getItem() as { dragType: string };
      return {
        isDragging: monitor.isDragging() && (!item || item.dragType === "inbox-calendar-item"),
      };
    }, []),
    // end: useCallback(() => {
    //   setDraggedEvent(null);
    // }, [setDraggedEvent]),
  });
  const { isDragging = false } = draggingItem ?? {};
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  drag(ref);

  // const shouldShowPreview = useMemo(
  //   () => item && item.index === index && item.dragType === "inbox-calendar-item",
  //   [item?.index, item?.dragType, index]
  // );

  return { ref, isDragging };
};
