import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { InboxBlockType } from "../../../types/block/BlockType";
import { useSetRecoilState } from "recoil";
import { inboxTaskListState } from "../../../recoil/taskList/inboxTaskListState";
import { TaskDividerType } from "../../../services/divider/divider.service";

export const updateInboxBlockApi = async (
  api: ReturnType<typeof useApi>,
  creator: string,
  payload: Partial<InboxBlockType>
): Promise<void> => {
  await api.patch(`/tasks/${payload.id}?creator=${payload.creator}`, payload, {
    headers: {
      "X-Requester": payload.creator,
    },
  });
};

// 기존 recoil로 되돌리는 코드
// export const useUpdateInboxBlockMutation = (
//   options?: UseMutationOptions<void, AxiosError, InboxBlockType>
// ) => {
//   const api = useApi();
//   const trackCall = useJuneTrackCall();
//   const setInboxTaskList = useSetRecoilState(inboxTaskListState);

//   return useMutation({
//     mutationFn: (updates: InboxBlockType) => updateInboxBlockApi(api, updates),

//     onMutate: async (updateData: InboxBlockType) => {
//       setInboxTaskList((prev: InboxBlockType[] | null) => {
//         if (!prev) return [];
//         return prev.map((task) => (task.id === updateData.id ? updateData : task));
//       });
//     },

//     onSuccess: () => {
//       trackCall("update_block", { location: "inbox" });
//     },
//     ...options,
//   });
// };

export type UpdateInboxTaskContext = {
  previousTasks: InboxBlockType[] | undefined;
};

interface UpdateInboxBlockMutationOptions {
  creator: string;
  updates: InboxBlockType;
}

export const useUpdateInboxBlockMutation = (
  options?: UseMutationOptions<void, AxiosError, UpdateInboxBlockMutationOptions>
) => {
  const api = useApi();
  const trackCall = useJuneTrackCall();
  const setInboxTaskList = useSetRecoilState(inboxTaskListState);

  return useMutation({
    mutationFn: ({ creator, updates }: UpdateInboxBlockMutationOptions) =>
      updateInboxBlockApi(api, creator, updates),

    onMutate: async (variables: UpdateInboxBlockMutationOptions) => {
      const { updates } = variables;
      setInboxTaskList((prev: (InboxBlockType | TaskDividerType)[] | null) => {
        if (!prev) return [];
        return prev.map((task) => (task.id === updates.id ? updates : task));
      });
    },

    onSuccess: () => {
      trackCall("update_block", { location: "inbox" });
    },
    ...options,
  });
};
