import React, { useEffect, useCallback, startTransition } from "react";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";
import { toastState } from "../../recoil/toast/toastState";
import styles from "./Toast.module.css";

const ANIMATION_DURATION = 200;
const DISPLAY_DURATION = 3000;

function Toast() {
  const [toast, setToast] = useRecoilState(toastState);

  const hideToast = useCallback(() => {
    // startTransition으로 상태 업데이트를 비긴급으로 처리
    startTransition(() => {
      setToast((prev) => ({ ...prev, isVisible: false }));

      setTimeout(() => {
        setToast((prev) => ({ ...prev, message: "" }));
      }, ANIMATION_DURATION);
    });
  }, [setToast]);

  useEffect(() => {
    if (!toast.isVisible || !toast.message) return;

    const timer = setTimeout(hideToast, DISPLAY_DURATION);
    return () => clearTimeout(timer);
  }, [toast.isVisible, toast.message, hideToast]);

  const handleClose = useCallback(
    (event) => {
      event.stopPropagation();
      hideToast();
    },
    [hideToast]
  );

  if (!toast.isVisible || !toast.message) return null;

  const toastClassNames = `${styles.toastContainer} ${toast.isVisible ? styles.show : styles.hide}`;

  return createPortal(
    <div className={toastClassNames}>
      {toast.type && <div className={styles[`toast${toast.type}Icon`]} aria-hidden="true" />}
      <span className={styles.toastMessage} role="alert">
        {toast.message}
      </span>
      {toast.type !== "Error" && (
        <button
          className={styles.toastCloseButton}
          onClick={handleClose}
          onMouseDown={(e) => e.stopPropagation()}
          aria-label="Close notification"
        />
      )}
    </div>,
    document.body
  );
}

export default React.memo(Toast);
