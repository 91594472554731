import {
  CalendarBlockType,
  InboxBlockType,
  ServerCalendarBlockType,
} from "../../types/block/BlockType";

export const deleteNullValueInObject = (obj: ServerCalendarBlockType): ServerCalendarBlockType => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {} as ServerCalendarBlockType);
};

export const deleteNullValueInClientBlock = (
  obj: CalendarBlockType | InboxBlockType
): CalendarBlockType | InboxBlockType => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (value !== null) {
        return { ...acc, [key]: value };
      }
      return acc;
    },
    {} as CalendarBlockType | InboxBlockType
  );
};

export const deleteNullValueInObjectInInbox = (obj: Record<string, any>): Record<string, any> => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== null));
};
