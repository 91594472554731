import useApi from "../../services/auth/useApi";

import { useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";

import { CalendarBlockType, ServerCalendarBlockType } from "../../types/block/BlockType";
import { convertServerBlockTypeToClientBlockType } from "../../utils/common/block/convertServerBlockTypeToClientBlockType";
import { deleteNullValueInObject } from "../../utils/taskDetail/formatServerSendData";
import { UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useJuneTrackCall } from "../../utils/june/analytics";

interface ContextType {
  previousBlocks: CalendarBlockType[] | undefined;
}

export const createCalendarBlockApi = async (
  api: ReturnType<typeof useApi>,
  payload: ServerCalendarBlockType
): Promise<void> => {
  const deletedNullValueItem = deleteNullValueInObject(payload);

  await api.post("/tasks", deletedNullValueItem, {
    headers: {
      "x-requester": payload.creator,
    },
  });
};

export const useCreateCalendarBlockMutation = (
  options?: UseMutationOptions<void, AxiosError, ServerCalendarBlockType, ContextType>
) => {
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  const trackCall = useJuneTrackCall();
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, ServerCalendarBlockType, ContextType>({
    mutationFn: (payload) => createCalendarBlockApi(api, payload),

    onMutate: async (payload): Promise<ContextType | undefined> => {
      const clientConvertedCalendarBlock = convertServerBlockTypeToClientBlockType(payload);
      const previousBlocks = queryClient.getQueryData<CalendarBlockType[]>(["calendarBlocks"]);

      setMobaCalendarList((prev: CalendarBlockType[]) => {
        if (!prev) return [clientConvertedCalendarBlock];
        return [clientConvertedCalendarBlock, ...prev];
      });

      return { previousBlocks };
    },
    onSuccess: () => {
      trackCall("create_block", { location: "today_inbox" });
      // queryClient.invalidateQueries();
    },
    onError: (error, variables, context) => {
      if (context?.previousBlocks) {
        setMobaCalendarList(context.previousBlocks);
      }
    },
    ...options,
  });
};
