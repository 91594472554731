import { Flex } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { pxToRem } from "../../../../utils/styles/size";
import { TimeBlockCheckIconDone } from "../InboxBodyIcon";
import { useState } from "react";
import { projectListState } from "../../../../recoil/projects/projectListState";
import { DEFAULT_SPACE_COLOR } from "../../../../constants/space";
import { getSpaceColor } from "../../../../services/space/space.service";
import { COMPLETED, INPROGRESS } from "../../../../constants/taskStateType";

import { CalendarBlockType, InboxBlockType } from "../../../../types/block/BlockType";

import { useUpdateBlockStatusMutation } from "../../../../react-query/block/useUpdateBlockStatusMutation";
import { deleteNullValueInClientBlock } from "../../../../utils/taskDetail/formatServerSendData";
interface InboxBlockCheckboxProps {
  data: InboxBlockType | CalendarBlockType;
  isCompleted: boolean;
  projectId: string | null | any;
}

const InboxBlockCheckbox = ({ data, isCompleted, projectId }: InboxBlockCheckboxProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const projectList = useRecoilValue(projectListState);

  const { mutate: updateBlockStatus } = useUpdateBlockStatusMutation();

  const handleClickCheckBox = (e: React.MouseEvent) => {
    e.stopPropagation();
    const isDone = data.itemStatus === COMPLETED;
    const newStatus = isDone ? INPROGRESS : COMPLETED;

    const updatedData: CalendarBlockType | InboxBlockType = {
      ...data,
      itemStatus: newStatus,
    };
    const calendarBlock = deleteNullValueInClientBlock(updatedData);
    updateBlockStatus(calendarBlock);
  };

  // TODO 이 부분 이렇게 구현하는거 맞는지 확인 필요
  const baseColor = projectId ? getSpaceColor(projectList, projectId) : DEFAULT_SPACE_COLOR;
  const backgroundColor = isCompleted || isHovered ? baseColor : `${baseColor}33`;

  return (
    <Flex
      position="relative"
      w={pxToRem(14)}
      h={pxToRem(14)}
      margin={"2px 0"}
      flexShrink={0}
      borderRadius={pxToRem(3)}
      backgroundColor={backgroundColor}
      boxShadow={`0 0 0 1px ${baseColor} inset`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClickCheckBox}
      alignItems="center"
      justifyContent="center"
      overflow={"hidden"}
    >
      {(isCompleted || isHovered) && (
        <TimeBlockCheckIconDone display={"flex"} color="currentColor" width={"full"} />
      )}
    </Flex>
  );
};

export default InboxBlockCheckbox;
