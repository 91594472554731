export const resizableStyles = {
  ".custom-resizer-handle-right:hover": {
    animation: "showHandle 0.5s forwards",
    cursor: "col-resize",
  },
  "@keyframes showHandle": {
    "0%, 30%": {
      opacity: 0.4,
    },
    "30%, 50%": {
      opacity: 0.7,
    },
    "50%, 100%": {
      opacity: 0.8,
    },
    "100%": {
      opacity: 1,
    },
  },
};

export const resizableHandleStyle = {
  width: "4px",
  height: "100%",
  right: "-6px",
  backgroundColor: "rgba(255, 255, 255, 0.16)",
  opacity: 0,
  transition: "all 0.5s ease-out",
  cursor: "col-resize",
  zIndex: 10,
  borderRadius: "5px",
};
