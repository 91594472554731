import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { AxiosError } from "axios";

import useApi from "../../../services/auth/useApi";
import { useJuneTrackCall } from "../../../utils/june/analytics";

import { InboxBlockType } from "../../../types/block/BlockType";
import { UpdateInboxTaskContext } from "../core/useUpdateInboxBlockMutation";
import { useSetRecoilState } from "recoil";
import { inboxTaskListState } from "../../../recoil/taskList/inboxTaskListState";
import { TaskDividerType } from "../../../services/divider/divider.service";

export const deleteInboxBlockApi = async (
  api: ReturnType<typeof useApi>,
  data: InboxBlockType
): Promise<void> => {
  await api.patch(
    `tasks/${data.id}/mark`,
    {},
    {
      headers: { "x-requester": data.creator },
    }
  );
};

export const useDeleteInboxBlockMutation = (
  options?: UseMutationOptions<
    void,
    AxiosError,
    InboxBlockType
    // UpdateInboxTaskContext
  >
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const trackCall = useJuneTrackCall();
  const setInboxTaskList = useSetRecoilState(inboxTaskListState);
  return useMutation<void, AxiosError, InboxBlockType>({
    mutationFn: (data: InboxBlockType) => deleteInboxBlockApi(api, data),

    onMutate: async (data: InboxBlockType) => {
      setInboxTaskList((current: (InboxBlockType | TaskDividerType)[] | null) => {
        if (!current) return [];
        return current.filter((task: InboxBlockType | TaskDividerType) => task.id !== data.id);
      });

      // queryClient.invalidateQueries({ queryKey: inboxBlockQueryKey() });
      trackCall("delete_block", { location: "inbox", type: "task" });
    },

    onError: (_error, _taskToDelete, context) => {},

    onSuccess: (_data, data) => {
      // trackCall("delete_block", { location: "inbox", type: "task" });
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
    },

    ...options,
  });
};
