import { atom } from "recoil";

export const toastState = atom({
  key: "toastState",
  default: {
    type: "",
    isVisible: false,
    message: "",
  },
});
