import { useDrop } from "react-dnd";
import { useRecoilValue } from "recoil";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import styles from "./styles.module.css";

import { showMorePopupState } from "../../recoil/calendar/calendarState";

import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { expandRecurringEvent } from "../../utils/calendar/convertRruletoDates";
import { MyCalendar } from "./MyCalendar";

// 반복 이벤트 관리 함수
export function manageRecurringEvents(baseEvents, updatedEvents, deletedEvents, calendarLastMonth) {
  // 1. 기본 반복 이벤트 확장 (recurrence가 있는 이벤트 확장)
  let expandedEvents = baseEvents.flatMap((baseEvent) =>
    expandRecurringEvent(baseEvent, calendarLastMonth)
  );

  // 2. 'this event' 수정 처리 (originalStartTime이 있는 이벤트)
  updatedEvents.forEach((updatedEvent) => {
    // updatedEvent가 this로 변경된 게 반영된 값
    const updatedEventId = updatedEvent.id;

    if (updatedEventId) {
      let eventFound = false; // 이벤트가 존재하는지 여부를 추적

      expandedEvents = expandedEvents.map((instance) => {
        // ID가 일치하는 이벤트의 시간을 덮어쓰기
        if (instance.id === updatedEventId) {
          eventFound = true; // 이벤트가 존재하면 true로 설정
          return {
            ...updatedEvent,
            start: updatedEvent.start, // updated의 start.dateTime으로 덮어쓰기
            end: updatedEvent.end, // updated의 end.dateTime으로 덮어쓰기
            recurringEventId: instance.recurringEventId,
            recurringNoteId: updatedEvent.id,
            recurrence: instance.recurrence,
            recurringThisEdited: true,
          };
        }
        return instance;
      });

      // 만약 해당 이벤트가 없으면 새로운 이벤트로 추가
      if (!eventFound) {
        expandedEvents.push({
          ...updatedEvent,
          id: updatedEventId,
          start: updatedEvent.start, // updated의 start.dateTime으로 설정
          end: updatedEvent.end, // updated의 end.dateTime으로 설정
          recurringNoteId: updatedEvent.id,
        });
      }
    }
  });

  // 3. 삭제된 이벤트 처리
  deletedEvents.forEach((deletedEvent) => {
    expandedEvents = expandedEvents.filter((instance) => instance.id !== deletedEvent.id);
  });

  return expandedEvents; // 최종적으로 확장 및 수정된 이벤트 리스트 반환
}

export default function CalendarCustom({ loadData }) {
  const taskDetail = useRecoilValue(taskPopupState);
  const showMorePopup = useRecoilValue(showMorePopupState);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  const [, drop] = useDrop({
    accept: ["inbox", "integrationDrag", "moreTask"],
    drop(props, monitor) {
      const item = monitor.getItem();
      if (item.dragType === "integrationDrag") {
        return { dropType: "calendarIntegrationDrop" };
      }
    },
  });

  return (
    <div ref={drop} className={styles["calendar-custom"]}>
      {(taskDetail.isVisible || showMorePopup) && (
        <div className={styles.overlayView} onClick={handleOverlayClick}></div>
      )}
      <MyCalendar loadData={loadData} />
    </div>
  );
}
