import moment from "moment";
import { atom, selector } from "recoil";
import { DraggedEventType } from "../../types/block/BlockType";

// 현재 월을 전역 상태로 관리하는 Recoil atom
export const calendarCurrentMonthState = atom({
  key: "calendarCurrentMonthState",
  default: moment(new Date()).format("YYYY-MM"), // 기본값은 현재 월
});

// 한 번이라도 불러온 적 있는 캘린더 연-월(Ex. 2024-11)을 가지고 있는 배열 Recoil atom
export const calendarTotalMonthState = atom({
  key: "calendarTotalMonthState",
  default: [
    moment(new Date()).subtract(1, "months").format("YYYY-MM"),
    moment(new Date()).format("YYYY-MM"),
    moment(new Date()).add(1, "months").format("YYYY-MM"),
  ],
});

// calendarTotalMonthState 배열 중 가장 나중의 연-월 값을 가지고 있는 selector
// 반복 이벤트 종료 시점을 계산하기 위한 selector
export const calendarLastMonthSelector = selector({
  key: "calendarLastMonthSelector",
  get: ({ get }) => {
    const totalMonths = get(calendarTotalMonthState);
    return totalMonths.length > 0 ? totalMonths[totalMonths.length - 1] : null;
  },
});

export const draggedEventState = atom<DraggedEventType | null>({
  key: "draggedEventState",
  default: null,
});
