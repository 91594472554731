import moment from "moment";

export const getMonthStartDay = (dateString: string) => {
  const date = moment(dateString, "YYYY-MM");
  const firstDay = date.startOf("month").toISOString();
  return firstDay;
};

export const getMonthLastDay = (dateString: string) => {
  const date = moment(dateString, "YYYY-MM");
  const lastDay = date.endOf("month").toISOString();
  return lastDay;
};

export const getLastDayOfTwoMonthsLater = (dateString: string) => {
  const date = moment(dateString, "YYYY-MM");
  const lastDay = date.add(2, "months").endOf("month").toISOString();
  return lastDay;
};

export const getMonthStartAndEndAsISO = (dateString: string) => {
  const firstDay = getMonthStartDay(dateString);
  const lastDay = getMonthLastDay(dateString);

  return { firstDay, lastDay };
};
