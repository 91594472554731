import { useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";

export function useHandleBlockDelete() {
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);

  const handleEventDelete = (
    eventId: string,
    eventStartDate: string | Date = "",
    eventRecurringId = "",
    selectedOption = ""
  ) => {
    if (selectedOption === "all") {
      setMobaCalendarList((current) => {
        const updatedEvents = current.filter((event) => {
          return !(
            event.id === eventId ||
            event.recurringEventId === event.id ||
            eventRecurringId === event.recurringEventId
          );
        });
        return updatedEvents;
      });
    } else if (selectedOption === "from") {
      const selectedEventStart = new Date(eventStartDate);
      setMobaCalendarList((current) => {
        const updatedEvents = current.filter((event) => {
          const eventStart = new Date(event.start);
          return !(
            (event.id === eventId ||
              event.recurringEventId === event.id ||
              eventRecurringId === event.recurringEventId) &&
            eventStart >= selectedEventStart
          );
        });
        return updatedEvents;
      });
    } else {
      setMobaCalendarList((current) => {
        const updatedEvents = [...current];
        const eventIndex = updatedEvents.findIndex((event) => event.id === eventId);
        if (eventIndex !== -1) {
          updatedEvents.splice(eventIndex, 1);
        }
        return updatedEvents;
      });
    }
  };

  return { handleEventDelete };
}
