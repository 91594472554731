import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../../../utils/styles/size";
import { ToggleIcon } from "../../InboxBodyIcon";

interface InboxToggleProps {
  title: string;
  number: number;
  onToggle: () => void;
  children?: React.ReactNode;
}

const InboxToggle = ({ title, onToggle, number, children }: InboxToggleProps) => {
  return (
    <Accordion
      color={title === "Overdue" ? "#FF6464" : "white"}
      defaultIndex={[]}
      allowMultiple
      gap="6px"
      w={"full"}
    >
      <AccordionItem _expanded={{ backgroundColor: "#242626" }} borderRadius="5px">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              w={"full"}
              h={pxToRem(28)}
              boxSizing={"border-box"}
              justifyContent={"space-between"}
              padding="0px 6px"
              gap="6px"
              borderRadius={"5px"}
              textStyle="body1"
              _hover={{ backgroundColor: "whiteAlpha.300" }}
              onClick={onToggle}
              alignItems={"center"}
              backgroundColor={"whiteAlpha.200"}
            >
              <ToggleIcon
                h={pxToRem(28)}
                transform={isExpanded ? "rotate(90deg)" : ""}
                transitionDuration={"0.2s"}
              />
              <Flex w="full" justifyContent={"space-between"}>
                <Flex textStyle="body1">{title}</Flex>
                <Flex
                  textStyle="body1"
                  padding="0px 4px"
                  borderRadius="3px"
                  backgroundColor={"rgba(0, 0, 0, 0.20)"}
                >
                  {number}
                </Flex>
              </Flex>
            </AccordionButton>

            <AccordionPanel
              id="panel"
              p={0}
              pt={2}
              pb={4}
              backgroundColor={"#242626"}
              _closed={{ background: "#242626" }}
            >
              {children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default InboxToggle;
