import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import useApi from "../../services/auth/useApi";
import { CalendarBlockType, ServerCalendarBlockType } from "../../types/block/BlockType";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import { convertServerBlockTypeToClientBlockType } from "../../utils/common/block/convertServerBlockTypeToClientBlockType";

export const updateCalendarBlockApi = async (
  api: ReturnType<typeof useApi>,
  payload: ServerCalendarBlockType
): Promise<void> => {
  await api.patch(`/tasks/${payload.id}?creator=${payload.creator}`, payload, {
    headers: {
      "X-Requester": payload.creator,
    },
  });
};

interface UpdateCalendarBlockMutationOptions {
  payload: ServerCalendarBlockType;
}

export const useUpdateCalendarBlockMutation = (
  options?: UseMutationOptions<void, AxiosError, UpdateCalendarBlockMutationOptions>
) => {
  const api = useApi();
  const trackCall = useJuneTrackCall();
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);

  return useMutation({
    mutationFn: ({ payload }: UpdateCalendarBlockMutationOptions) =>
      updateCalendarBlockApi(api, payload),

    onMutate: async (variables: UpdateCalendarBlockMutationOptions) => {
      const { payload } = variables;

      setMobaCalendarList((prev: CalendarBlockType[] | null) => {
        if (!prev) return [];
        const clientConvertedBlock = convertServerBlockTypeToClientBlockType(payload);
        return prev.map((task) => (task.id === payload.id ? clientConvertedBlock : task));
      });
    },

    onSuccess: () => {
      trackCall("update_block", { location: "inbox" });
    },
    onError: (error) => {
      console.error("error", error);
    },
    ...options,
  });
};
