import { AxiosError } from "axios";
import { inboxBlockQueryKey } from "../inboxQueryKey";
import useApi from "../../../services/auth/useApi";
import { useQuery } from "@tanstack/react-query";
import { InboxBlockType } from "../../../types/block/BlockType";
import { TaskDividerType } from "../../../services/divider/divider.service";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { inboxTaskListState } from "../../../recoil/taskList/inboxTaskListState";

const useFetchInProgressBlockQuery = () => {
  const api = useApi();
  const setInboxTaskList = useSetRecoilState(inboxTaskListState);

  const { data, error, isLoading, isError } = useQuery<
    InboxBlockType[],
    AxiosError,
    (InboxBlockType | TaskDividerType)[]
  >({
    queryKey: inboxBlockQueryKey(),
    queryFn: async () => {
      const response = await api.get("/tasks?status=InProgress");
      return response.data.tasks;
    },
    refetchOnWindowFocus: false,
    retry: 0,
  });

  useEffect(() => {
    if (!data) return;
    if (data) {
      setInboxTaskList(data);
    }
  }, [data, setInboxTaskList]);

  return { data, isLoading, error, isError };
};

export default useFetchInProgressBlockQuery;
