import { ServerCalendarBlockType } from "../../../types/block/BlockType";

export const convertServerBlockTypeToClientBlockType = (eventData: ServerCalendarBlockType) => {
  if (!eventData.start) return eventData; // NOTE start가 없는 경우는 그대로 반환

  // NOTE startTimezone, endTimeZone은 allday이면 아예 필드를 포함하지 않도록 설정
  const startValue =
    "dateTime" in eventData?.start ? new Date(eventData.start.dateTime) : eventData.start.date;
  const startTimeZone = "dateTime" in eventData.start ? eventData.start.timeZone : undefined;
  const endValue =
    "dateTime" in eventData?.end ? new Date(eventData.end.dateTime) : eventData.end.date;
  const endTimeZone = "dateTime" in eventData.end ? eventData.end.timeZone : undefined;

  const result: any = {
    ...eventData,
    start: startValue,
    end: endValue,
  };

  if (startTimeZone !== undefined && startTimeZone !== null) {
    result.startTimeZone = startTimeZone;
  }

  if (endTimeZone !== undefined && endTimeZone !== null) {
    result.endTimeZone = endTimeZone;
  }

  return result;
};
