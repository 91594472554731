import React, { useEffect, useState } from "react";
import { SideBarGroupSection } from "./SideBarGroupSection";
import { SpaceItemTitleSection } from "./SpaceItemTitleSection";
import { SpaceSideBarItem } from "./SpaceSideBarItem";
import { usePrevious } from "@chakra-ui/react";
import { useCreateSpaceMutation } from "../../../react-query/space/core/useCreateSpaceMutation";
import { customScrollbar, useScrollBarStyle } from "../../../hooks/useScrollBarStyle";
import { useFetchSpaceQuery } from "../../../react-query/space/core/useFetchSpaceQuery";
import {
  createDividerPayload,
  createProjectPayload,
  isDividerType,
  isNonDeletedSpace,
  isNoSpaceType,
  isSpaceType,
} from "../../../services/space/space.service";
import { useMoveToTrashSpaceMutation } from "../../../react-query/space/deletion/useMoveToTrashSpaceMutation";
import { useMarkSpaceDoneMutation } from "../../../react-query/space/status/useMarkSpaceDoneMutation";
import { useUpdateSpaceMutation } from "../../../react-query/space/core/useUpdateSpaceMutation";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  inProgressSelectedSpaceIdListSelector,
  selectedSpaceIdListState,
  selectedSpaceIdListStateSelector,
} from "../../../recoil/spaces/selectedSpaceIdListState";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { useReorderSpacesMutation } from "../../../react-query/space/core/useReorderSpaceMutation";
import { NoSpaceFilterItem } from "./NoSpaceFilterItem";
import { useFetchProjectOrderQuery } from "../../../react-query/space/core/useFetchProjectOrderQuery";
import { NoSpaceFilter } from "../../../constants/space";
import { ProjectStatus, SpaceType } from "../../../types/space";
import { accountState } from "../../../recoil/account/accountStateV2";
import { getPrimaryAccount } from "../../../services/auth/account.service";
import {
  inProgressAllSpaceSelector,
  projectListState,
  spacesWithoutDividerSelector,
} from "../../../recoil/projects/projectListState";
import { useCreateDividerMutation } from "../../../react-query/space/divider/useCreateDividerMutation";
import { useDeleteDividerMutation } from "../../../react-query/space/divider/useDeleteDividerMutation";
import { SpaceDividerItem } from "./SpaceDividerItem";
import { pxToRem } from "../../../utils/styles/size";
import { useDuplicateDividerMutation } from "../../../react-query/space/divider/useDuplicateDividerMutation";
import { mobaCalendarListState } from "../../../recoil/calendar/mobaCalendarListState";
import { CalendarBlockType } from "../../../types/block/BlockType";

interface SpacesSectionProps {
  isExpanded: boolean;
  onOpenSidebar: () => void;
}

export const SpacesSection: React.FC<SpacesSectionProps> = ({
  isExpanded,
  onOpenSidebar,
  // handleDeleteSpace,
}) => {
  const { isLoading: isLoadingSpaces } = useFetchSpaceQuery();
  const allSpaces = useRecoilValue(spacesWithoutDividerSelector);
  const { data: projectOrderData = [] } = useFetchProjectOrderQuery();
  const inProgressItemsWithNoSpace = useRecoilValue(inProgressAllSpaceSelector(projectOrderData));

  const inProgressSpacesWithNoSpace = inProgressItemsWithNoSpace.filter(
    (space) => !isDividerType(space)
  );
  const setProjectList = useSetRecoilState(projectListState);
  const projectList = useRecoilValue(projectListState);
  const selectedSpaceIdList = useRecoilValue(selectedSpaceIdListStateSelector);
  const setSelectedSpaceIdList = useSetRecoilState(selectedSpaceIdListState);
  const inProgressSelectedSpaces = useRecoilValue(inProgressSelectedSpaceIdListSelector);
  const inProgressItemsLength = inProgressSpacesWithNoSpace.length;
  const hasSpaces = inProgressItemsLength > 0;
  const targetSpaces = inProgressSelectedSpaces.length;
  const isAllSelected = targetSpaces >= inProgressItemsLength; // add no space filter
  const shouldShowAll = hasSpaces && !isAllSelected;

  const account = useRecoilValue(accountState);
  const { isScrolling } = useScrollBarStyle(sideBarGroupId);
  const prevIsExpanded = usePrevious(isExpanded);
  const [isInputVisible, setInputVisible] = useState(false);
  const trackCall = useJuneTrackCall();
  const [mobaCalendarList, setMobaCalendarList] = useRecoilState(mobaCalendarListState);

  const { mutate: createSpace } = useCreateSpaceMutation();
  const { mutate: reorderMutate } = useReorderSpacesMutation();
  const { mutate: moveToTrashSpace } = useMoveToTrashSpaceMutation();
  const { mutate: archiveSpace } = useMarkSpaceDoneMutation();
  const { mutate: updateSpace } = useUpdateSpaceMutation();
  const { mutate: createDivider } = useCreateDividerMutation();
  const { mutate: deleteDivider } = useDeleteDividerMutation();
  const { mutate: duplicateDivider } = useDuplicateDividerMutation();

  const handleAddClick = () => {
    onOpenSidebar();
    setInputVisible(true);
  };

  const handleShowAll = () => {
    const allSpaceList = allSpaces.filter(isNonDeletedSpace).map((space) => space.id);
    setSelectedSpaceIdList([...allSpaceList, NoSpaceFilter]);
  };

  const handleSubmit = (newTitle: string) => {
    const email = getPrimaryAccount(account)?.email ?? "";
    const newItem = createProjectPayload(email, newTitle, projectList);
    setSelectedSpaceIdList([...selectedSpaceIdList, newItem.id]);
    createSpace(newItem, {
      onSuccess: () => {
        trackCall("create_space", { location: "space_filter" });
      },
    });
    setInputVisible(false);
  };

  const handleClickSpace = (id: string | typeof NoSpaceFilter) => {
    if (selectedSpaceIdList.length > 1 && !shouldShowAll) {
      setSelectedSpaceIdList([id]);
      return;
    }

    if (selectedSpaceIdList.includes(id)) {
      if (selectedSpaceIdList.length === 1) {
        handleShowAll();
        return;
      }
      setSelectedSpaceIdList(selectedSpaceIdList.filter((spaceId) => spaceId !== id));
    } else {
      setSelectedSpaceIdList([...selectedSpaceIdList, id]);
    }
  };

  const handleArchive = (id: string) => {
    archiveSpace(id, {
      onSuccess: () => {
        trackCall("complete_space", { location: "space_filter" });
      },
    });
  };

  const handleDeleteSpace = (spaceId: string) => {
    const newMobaCalendarList: CalendarBlockType[] | null = mobaCalendarList?.map(
      (event: CalendarBlockType) => {
        if (event && event.projectId && event.projectId === spaceId) {
          const { projectId, ...rest } = event;
          return { ...rest };
        }
        return event;
      }
    );
    setMobaCalendarList(newMobaCalendarList ?? []);
  };

  const handleDelete = (id: string) => {
    handleDeleteSpace(id);
    moveToTrashSpace(id, {
      onSuccess: () => {
        trackCall("delete_space", { location: "space_filter" });
      },
    });
  };

  const handleUpdate = (id: string, payload: Partial<SpaceType>) => updateSpace({ id, payload });

  const handleCreateDivider = () => {
    const email = getPrimaryAccount(account)?.email ?? "";
    const newItem = createDividerPayload(email);
    createDivider(newItem, {
      onSuccess: () => {
        trackCall("create_divider", { location: "sidebar" });
      },
    });
    setInputVisible(false);
  };

  const handleDuplicateDivider = (targetId: string) => {
    const email = getPrimaryAccount(account)?.email ?? "";
    const newDivider = createDividerPayload(email);
    duplicateDivider(
      { targetId, newDivider },
      {
        onSuccess: () => {
          trackCall("duplicate_divider", { location: "sidebar" });
        },
        onError: (err) => {
          console.error("Error duplicating divider:", err);
        },
      }
    );
  };

  const handleDeleteDivider = (selectedDividerId: string) => {
    deleteDivider(selectedDividerId, {
      onSuccess: () => {
        trackCall("delete_divider", { location: "sidebar" });
      },
    });
  };

  const handleReorder = (orderId: string[]) => {
    reorderMutate({
      projectIds: orderId,
    });
  };

  const handleMoveSpace = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex === hoverIndex) {
      return;
    }
    const updatedList = [...inProgressItemsWithNoSpace];
    const [movedItem] = updatedList.splice(dragIndex, 1);
    updatedList.splice(hoverIndex, 0, movedItem);

    setProjectList(updatedList);
  };

  useEffect(() => {
    if (prevIsExpanded && !isExpanded) {
      setInputVisible(false);
    }
  }, [isExpanded, prevIsExpanded]);

  return (
    <SideBarGroupSection
      id={sideBarGroupId}
      w={"full"}
      flex="1 1 auto"
      sx={customScrollbar(isScrolling, isExpanded)}
      overflowY="auto"
      gap={pxToRem(5)}
      isExpanded={isExpanded}
      isScrolling={isScrolling}
    >
      <SpaceItemTitleSection
        isExpanded={isExpanded}
        isInputVisible={isInputVisible}
        setInputVisible={setInputVisible}
        onCreateSpace={handleAddClick}
        shouldShowAll={shouldShowAll}
        onShowAll={handleShowAll}
        onSubmit={handleSubmit}
        onCreateDivider={handleCreateDivider}
      />

      <>
        {!isLoadingSpaces &&
          inProgressItemsWithNoSpace?.map((space, index) => {
            if (isDividerType(space)) {
              return (
                <SpaceDividerItem
                  data={space}
                  key={space.id}
                  onDelete={() => handleDeleteDivider(space.id)}
                  isExpanded={isExpanded}
                  onReorder={handleReorder}
                  onDuplicate={() => handleDuplicateDivider(space.id)}
                  index={index}
                />
              );
            }

            if (isNoSpaceType(space)) {
              return (
                <NoSpaceFilterItem
                  data={space}
                  key={space.id}
                  isExpanded={isExpanded}
                  index={index}
                  isSelected={selectedSpaceIdList.includes(space.id)}
                  onClickSpaceCircle={() => handleClickSpace(space.id)}
                  onReorder={handleReorder}
                />
              );
            }

            return (
              <SpaceSideBarItem
                key={space.id}
                data={space}
                index={index}
                isSelected={selectedSpaceIdList.includes(space.id)}
                isExpanded={isExpanded}
                onArchive={() => handleArchive(space.id)}
                onDelete={() => handleDelete(space.id)}
                onUpdate={(payload) => handleUpdate(space.id, payload)}
                onClickSpaceCircle={() => handleClickSpace(space.id)}
                onReorder={handleReorder}
                moveSpace={handleMoveSpace}
              />
            );
          })}
      </>
    </SideBarGroupSection>
  );
};

const sideBarGroupId = "spaces-section-element";
