import { Flex, MenuItem, Text } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { CheckIcon } from "./InboxHeaderIcon";
import {
  InboxHeaderTab,
  ToggleVisibility,
  ToggleVisibilityByTab,
} from "../../../types/inbox/inbox-headers";
import { HandleTabToggleVisibility } from "..";

interface InboxHeaderFilterItemProps<Tab extends InboxHeaderTab> {
  title: keyof ToggleVisibility;
  onToggleVisibility: HandleTabToggleVisibility;
  toggleVisibility: ToggleVisibilityByTab;
  activeTab: Tab;
}

const InboxHeaderFilterMenuItem = <Tab extends InboxHeaderTab>({
  title,
  onToggleVisibility,
  toggleVisibility,
  activeTab,
}: InboxHeaderFilterItemProps<Tab>) => {
  return (
    <MenuItem
      w={pxToRem(170)}
      h={8}
      py={0}
      px={2}
      box-sizing={"border-box"}
      alignItems={"center"}
      justifyItems={"space-between"}
      gap={pxToRem(6)}
      backgroundColor={"inherit"}
      onClick={() => onToggleVisibility(title, activeTab)}
      _hover={{ bg: "whiteAlpha.200" }}
    >
      <Flex w={"full"}>
        <Text
          textStyle="body3"
          color={toggleVisibility[activeTab][title] ? "#69E4FF" : "#fff"}
        >{`Show ${title} tasks`}</Text>
      </Flex>
      <Flex>
        <CheckIcon
          w={pxToRem(16)}
          color={toggleVisibility[activeTab][title] ? "#69E4FF" : "transparent"}
        />
      </Flex>
    </MenuItem>
  );
};

export default InboxHeaderFilterMenuItem;
