import { Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";

import { hexToRgba } from "../../../utils/styles/color";

interface CalendarBlockContainerProps {
  children: React.ReactNode;

  width: number;
  height: number;
  blockSize: "small" | "large";
  projectColor: string;
  isCompleted: boolean;
}

const CalendarBlockContainer = ({
  children,
  width,
  height,
  blockSize,
  projectColor,
  isCompleted,
}: CalendarBlockContainerProps) => {
  return (
    <Flex
      width={pxToRem(width)}
      height={pxToRem(height)}
      position="relative"
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      boxSizing="border-box"
      border={`1px solid ${hexToRgba(projectColor, 0.2)}`}
      background={`linear-gradient(0deg, ${hexToRgba(projectColor, 0.05)} 0%, ${hexToRgba(projectColor, 0.05)} 100%) #242626`}
      opacity={0.85}
      borderRadius={"6px"}
      overflow={"hidden"}
      px={1.5}
      py={blockSize === "small" ? 0 : 1}
    >
      {children}
    </Flex>
  );
};
export default CalendarBlockContainer;
