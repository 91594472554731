import { useCallback } from "react";

import { manageRecurringEvents } from "../../compoenets/CalendarCustom";
import {
  categorizeEventsByRecurrence,
  convertToMobaFormat,
  removeWorkingLocationEvents,
} from "../../utils/calendar/initializeDataUtils";

export const useInitialCalenadarBlocks = () => {
  const handleRecurringEvents = useCallback((categorizedEvents, calendarLastMonth) => {
    const { baseEvents, updatedEvents, deletedEvents } = categorizedEvents;
    return manageRecurringEvents(baseEvents, updatedEvents, deletedEvents, calendarLastMonth);
  }, []);

  const processInitialCalendarBlocks = useCallback(
    (events, calendarLastMonth) => {
      // 1. workingLocation 제거
      const validEvents = removeWorkingLocationEvents(events);

      // 2. 이벤트 분류
      const categorizedEvents = categorizeEventsByRecurrence(validEvents);

      // 3. 반복 이벤트 처리
      const processedRecurringEvents = handleRecurringEvents(categorizedEvents, calendarLastMonth);

      // 4. Moba 형식(React big calendar 형식)으로 변환
      const convertedRecurringEvents = convertToMobaFormat(processedRecurringEvents);
      const convertedNonRecurringEvents = convertToMobaFormat(categorizedEvents.nonRecurringEvents);

      return [...convertedRecurringEvents, ...convertedNonRecurringEvents];
    },
    [handleRecurringEvents]
  );

  return { processInitialCalendarBlocks };
};
