import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { useEffect, useRef } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DividerType, NoSpaceType, ProjectCategory, SpaceType } from "../types/space";
import { useFetchProjectOrderQuery } from "../react-query/space/core/useFetchProjectOrderQuery";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { inProgressAllSpaceSelector, projectListState } from "../recoil/projects/projectListState";
import { isDividerType } from "../services/space/space.service";

interface DragItem {
  dragType: "filterProject";
  data: SpaceType | DividerType | NoSpaceType;
  index: number;
}

interface useDragAndDropProps {
  prevIndex: number;
  setPrevIndex: (newIndex: number) => void;
  data: SpaceType | DividerType | NoSpaceType;
  index: number;
  onReorder: (orderId: string[]) => void;
}

export const useDragAndDrop = ({
  prevIndex,
  setPrevIndex,
  index,
  data,
  onReorder,
}: useDragAndDropProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { data: projectOrderData = [] } = useFetchProjectOrderQuery();
  const projectList = useRecoilValue(inProgressAllSpaceSelector(projectOrderData));
  const setProjectList = useSetRecoilState(projectListState);

  const [draggingItem, drag, preview] = useDrag({
    type: PROJECT_ACCEPT_TYPE,
    item: { dragType: PROJECT_DRAG_TYPE, data, index },
    collect: (monitor: DragSourceMonitor) => {
      const item = monitor.getItem() as DragItem;
      if (item?.dragType !== PROJECT_DRAG_TYPE) return;

      return {
        isDragging: monitor.isDragging(),
        item: monitor.getItem() as DragItem,
      };
    },
  });

  const { item, isDragging = false } = draggingItem ?? {};

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (prevIndex !== index) {
      const animation = ref.current?.animate(
        [
          { transform: `translateY(${(prevIndex - index) * 100}%)` },
          { transform: "translateY(0%)" },
        ],
        {
          duration: 150,
          easing: "ease-in-out",
        }
      );
      if (animation) {
        animation.onfinish = () => {
          setPrevIndex(index);
        };
      }
    }
  }, [index, prevIndex]);

  const [, drop] = useDrop({
    accept: PROJECT_ACCEPT_TYPE,
    hover: (item: DragItem, monitor: any) => {
      if (!ref.current || isDragging) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      const dragIndex = item.index;
      const hoverIndex = index;
      const isDragItemDivider =
        isDividerType(item?.data) && item.data.category === ProjectCategory.ProjectDivider;

      // 디바이더는 작은 높이를 가졌기 때문에, 이동 감도를 줄이기 위해 70% threshold 설정
      // 일반 항목은 50% 기준으로 처리 (절반을 넘어가면 moveProject 트리거)
      const threshold = isDragItemDivider ? hoverHeight * 0.7 : hoverHeight / 2;

      if (
        (dragIndex < hoverIndex && hoverClientY < threshold) ||
        (dragIndex > hoverIndex && hoverClientY > hoverHeight - threshold)
      ) {
        return;
      }

      moveProject(dragIndex, hoverIndex);
    },
    drop() {
      onReorder(projectList.map((project) => project.id));
    },
  });

  const moveProject = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex === hoverIndex || !item) {
      return;
    }
    const updatedList = [...projectList];
    const [movedItem] = updatedList.splice(dragIndex, 1); // 드래그된 아이템 삭제
    updatedList.splice(hoverIndex, 0, movedItem);
    setProjectList(updatedList);
    item.index = hoverIndex;
  };

  drag(drop(ref));
  const shouldShowPreview = item && item.index === index && item.dragType === "filterProject";

  return { ref, isDragging, shouldShowPreview };
};

const PROJECT_ACCEPT_TYPE = "project";
const PROJECT_DRAG_TYPE = "filterProject";
