import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { pxToRem } from "../../utils/styles/size";

interface ContextMenuItem {
  label: string;
  onClick: (e: React.MouseEvent) => void;
}

interface ContextMenuProps {
  isOpen: boolean;
  onClose: () => void;
  menuItems: ContextMenuItem[];
}

export const ContextMenu = ({ isOpen, onClose, menuItems }: ContextMenuProps) => {
  return (
    <Menu isOpen={isOpen} onClose={onClose} placement="right-start">
      <MenuButton overflow="hidden" />
      <MenuList
        border="1px solid"
        borderColor="whiteAlpha.100"
        borderRadius="5px"
        bg="#2F3131"
        w={pxToRem(120)}
        minW={pxToRem(120)}
        p={0}
        overflow="hidden"
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              item.onClick(e);
              onClose();
            }}
            boxSizing="border-box"
            w="full"
            px={2}
            py={0}
            h={pxToRem(34)}
            bg="#2F3131"
            textStyle="body3"
            fontSize={pxToRem(12)}
            lineHeight="140%"
            color="#fff"
            textAlign="start"
            outline="none"
            _hover={{ outline: "none", bg: "whiteAlpha.200" }}
            _focus={{ outline: "none" }}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
