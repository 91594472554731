import { EventRSVPResponse } from "../../types/inbox/inbox-headers";
import {
  BlackCheckCircleIcon,
  GrayCheckCircleIcon,
  GrayQuestionMarkCircle,
  RedXCircleIcon,
} from "../../icons";

export const RSVPOptions = [
  { value: EventRSVPResponse.Accepted, label: "Yes", icon: BlackCheckCircleIcon },
  { value: EventRSVPResponse.Declined, label: "No", icon: RedXCircleIcon },
  { value: EventRSVPResponse.Tentative, label: "Maybe", icon: GrayQuestionMarkCircle },
];
export const DefaultRSVPOption = {
  value: EventRSVPResponse.NeedsAction,
  label: "Going",
  icon: GrayCheckCircleIcon,
};
