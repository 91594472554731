export function MyDayHeader(props) {
  const { label } = props;
  const date = label.split(" ")[0];
  const dayOfWeek = label.split(" ")[1];

  return (
    <div className="dayWeekHeader">
      <div className="date">{date}</div>
      <div className="dayOfWeek">{dayOfWeek}</div>
    </div>
  );
}
