import { useRecoilValue } from "recoil";
import { timeFormatState } from "../../recoil/calendar/settingCalendar";

export function MyTimeGutterWrapper({ localizer, slotMetrics }) {
  const timeFormat = useRecoilValue(timeFormatState);
  return (
    <div className="rbc-time-gutter rbc-time-column">
      {slotMetrics.groups.map((date, idx) => {
        return (
          <div className="rbc-timeslot-group" key={`${date[0]}${idx}`}>
            <div className="rbc-label">
              {idx > 0 &&
                localizer.format(
                  new Date(date[0]),
                  timeFormat === "12-hour" ? "h a" : "HH" + ":" + "00"
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
