import { IntegrationType, ServerEventTimeType, AttendeeType, LinkType } from "./BlockContentsType";
import { EventRSVPResponse, TaskPriorityEnum } from "../inbox/inbox-headers";
import { TaskDividerType } from "../../services/divider/divider.service";

// 기본이 되는 Block 타입
export interface BaseBlockType {
  id: string;
  creator: string;
  title: string;
  projectId?: string;
  links?: LinkType[];
  note?: string;
  integration?: IntegrationType;
  visibility: "default" | "public" | "private";
  transparency: "opaque" | "transparent";
  taskType: "Task" | "Event";
  meetingCode?: string;
  itemStatus?: "Completed" | "InProgress" | "Deleted";
  previewNote?: string;
  priority?: TaskPriorityEnum;
}

// 시간 정보가 없는 Inbox Task Block
export interface InboxBlockType extends BaseBlockType {
  taskType: "Task";
}

// 시간 정보가 있는 Calendar Block의 기본 타입
interface BaseCalendarBlockType extends BaseBlockType {
  allDay: boolean;
  attendees?: AttendeeType[];
  hangoutLink?: string;
  recurrence?: string[];
  isCreateSelectEvent?: boolean;
  isDataDuplicateEvent?: boolean;
  recurringEventId?: string;
  recurringNoteId?: string;
  attendResponse?: EventRSVPResponse;
  location?: string;
  createdAt?: string | Date;
}

// 구글캘린더 data fetch 시 캘린더 블록 타입
export interface ServerCalendarBlockType extends BaseCalendarBlockType {
  start: ServerEventTimeType;
  end: ServerEventTimeType;
}

// React Big Calendar용 수정된 캘린더 블록 타입
export interface CalendarBlockType extends BaseCalendarBlockType {
  start: string | Date;
  startTimeZone?: string;
  end: string | Date;
  endTimeZone?: string;
}

// Popup 상태 타입도 새로운 네이밍에 맞게 수정
export interface BlockPopupState {
  isVisible: boolean;
  data: InboxBlockType | CalendarBlockType | ServerCalendarBlockType | TaskDividerType | null;
  modalPosition: { x: number; y: number };
  targetRect: DOMRect | null;
  onClose: (() => void) | null;
  loadData: (() => Promise<void>) | null;
  handleDataDuplicate:
    | ((e: React.MouseEvent, data: InboxBlockType | CalendarBlockType) => void)
    | (() => void)
    | null;
  type: string;
  handleEventDelete:
    | ((
        eventId: string,
        eventStartDate?: string,
        eventRecurringId?: string,
        selectedOption?: string
      ) => void)
    | null;
  handleEventChange:
    | ((
        eventData: CalendarBlockType,
        oldStartDate?: string,
        oldEndDate?: string,
        selectedOption?: string,
        isFirstCreated?: boolean,
        initialRecurrence?: string[] | null
      ) => void)
    | null;
  modalHeight?: number;
}

export interface DraggedEventType extends CalendarBlockType {
  kind?: string;
  color?: string | undefined;
  index?: number;
  dragType: string;
}

export const isCalendarBlock = (
  data: InboxBlockType | CalendarBlockType | TaskDividerType
): data is CalendarBlockType => {
  return "start" in data && data.start !== "";
};

export enum InboxBlockStatusType {
  Completed = "Completed",
  Overdue = "Overdue",
  Planned = "Planned",
  Today = "Today",
  Inbox = "Inbox",
}

export enum InboxBlockPositionType {
  General = "general",
  Toggle = "toggle",
}
