import React, { useEffect, useState } from "react";
import styles from "./TaskRow.module.css";
import { Box, Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../../utils/styles/size";
import InboxSubTaskToggle from "./InboxSubTaskToggle";
import InboxBlockBody from "./InboxBlockBody";
import { CalendarBlockType, InboxBlockType } from "../../../../types/block/BlockType";

interface TaskRowProps {
  data: InboxBlockType | CalendarBlockType;
}

export const TaskRowLayer = React.memo(({ data }: TaskRowProps) => {
  const [integrationImg, setIntegrationImg] = useState<string | null>(null);
  const isTaskRowSelected = true;

  useEffect(() => {
    const integrationData = data.integration?.provider;
    if (integrationData && integrationData !== "") {
      setIntegrationImg(integrationData);
    }
  }, []);

  return (
    <Flex w={"full"}>
      <InboxSubTaskToggle />
      <Box
        w={"full"}
        gap={pxToRem(6)}
        border={"1px solid"}
        borderColor={"transparent"}
        borderRadius={"5px"}
        bg={"transparent"}
        _hover={{ bg: "whiteAlpha.100" }}
        _active={{ borderColor: "whiteAlpha.100" }}
      >
        <Flex
          id="inbox-general"
          alignItems={"center"}
          as="div"
          p={0}
          opacity={1}
          bgColor={"initial"}
          cursor={"pointer"}
          border={"none"}
          boxSizing="border-box"
          flexWrap={"nowrap"}
          className={`${isTaskRowSelected && styles.expandSelected}`}
          key={data.id}
        >
          <InboxBlockBody data={data} isOverdue={false} isCompleted={false} />
          {integrationImg && (
            <div
              className={`${styles["integration"]} ${
                integrationImg === "gmail"
                  ? styles["integration-gmail"]
                  : integrationImg === "jira"
                    ? styles["integration-jira"]
                    : integrationImg === "slack" && styles["integration-slack"]
              } `}
            ></div>
          )}
        </Flex>
      </Box>
    </Flex>
  );
});
