import { customScrollbar, useScrollBarStyle } from "../../../hooks/useScrollBarStyle";
import { pxToRem } from "../../../utils/styles/size";
import { Flex, FlexProps } from "@chakra-ui/react";

interface TaskListWrapperProps extends FlexProps {
  children: React.ReactNode;
}
const taskListGroupId = "taskListSection";

export function TaskListWrapper({ children, ...props }: TaskListWrapperProps) {
  const { isScrolling } = useScrollBarStyle(taskListGroupId);
  return (
    <Flex
      id={taskListGroupId}
      w={"full"}
      flexGrow={1}
      sx={customScrollbar(isScrolling, true)}
      flexDir={"column"}
      p={isScrolling ? "0 0 8px 8px" : "0 8px 8px 8px"}
      gap={pxToRem(15)}
      overflowY="auto"
      {...props}
    >
      {children}
    </Flex>
  );
}
