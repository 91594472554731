import { Center } from "@chakra-ui/react";
import React from "react";
import { hexToRgba } from "../../../utils/styles/color";

interface PriorityIconWrapperProps {
  children: React.ReactNode;
}

export const PriorityIconWrapper: React.FC<PriorityIconWrapperProps> = ({ children }) => {
  return (
    <Center
      bg={"black"}
      borderRadius={3}
      border={`1px solid`}
      borderColor={hexToRgba("#ffffff", 0.08)}
      w={4}
      h={4}
      fontSize={14}
    >
      {children}
    </Center>
  );
};
