import { useCallback } from "react";

import { useJuneTrackCall } from "../../utils/june/analytics";

type ActionType =
  | "create_block"
  | "duplicate_block"
  | "update_block"
  | "delete_block"
  | "done_task"
  | "view_block_detail";

type LocationType = "inbox" | "today_inbox";

interface JuneBlockDataType {
  action: ActionType;
  location: LocationType;
  taskType?: "Task" | "Event";
}

const useHandleJuneBlockData = () => {
  const trackCall = useJuneTrackCall();

  const handleJuneBlockData = useCallback(({ action, location, taskType }: JuneBlockDataType) => {
    trackCall(action, { location, taskType });
  }, []);

  return handleJuneBlockData;
};

export default useHandleJuneBlockData;
