import { v4 as uuid4 } from "uuid";
import { InboxBlockType } from "../../types/block/BlockType";
import { TaskPriorityEnum } from "../../types/inbox/inbox-headers";

const createInboxBlockPayload = (
  newTaskName: string,
  accountEmail: string,
  defaultVisibility: string
): InboxBlockType => {
  return {
    id: `task-${uuid4()}`,
    creator: accountEmail,
    title: newTaskName,
    visibility: defaultVisibility === "public" ? "public" : "private",
    transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
    itemStatus: "InProgress",
    taskType: "Task", // 240802 새로 추가된 프로퍼티
    priority: TaskPriorityEnum.NoPriority,
  };
};

export default createInboxBlockPayload;
