import { useDragLayer } from "react-dnd";

import { CalendarBlockType } from "../../../types/block/BlockType";

import CalendarBlock from "../../CalendarCustom/CalendarBlock/CalendarBlock";

interface CalendarDragLayerProps {
  data: CalendarBlockType;
}
const CalendarDragLayer = ({ data }: CalendarDragLayerProps) => {
  const { item, isDragging, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  if (!isDragging || !currentOffset) {
    return null;
  }

  return <CalendarBlock data={data} />;
};

export default CalendarDragLayer;
