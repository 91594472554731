export const convertPascalTosnake = (input: string) => {
  if (typeof input !== "string") {
    return input;
  }

  return input
    .replace(/([a-z])([A-Z])/g, "$1_$2") // 소문자와 대문자 사이에 '_' 삽입
    .replace(/([A-Z])([A-Z][a-z])/g, "$1_$2") // 연속된 대문자 처리
    .toLowerCase();
};
