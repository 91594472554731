import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { doneTaskListState } from "../recoil/taskList/doneTaskListState";
import useApi from "../services/auth/useApi";

const useFetchCompletedTasks = () => {
  const api = useApi();

  const updateDoneTaskLst = useSetRecoilState(doneTaskListState);

  const fetchCompletedTasks = useCallback(() => {
    try {
      api.get("/tasks?status=Completed").then((res) => {
        updateDoneTaskLst(res.data.tasks);
      });
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  return { fetchCompletedTasks };
};

export default useFetchCompletedTasks;
