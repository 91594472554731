import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../services/auth/useApi";
import { AxiosError } from "axios";

import { inboxBlockQueryKey } from "../inboxQueryKey";
import { InboxBlockType } from "../../../types/block/BlockType";
import { TaskDividerType } from "../../../services/divider/divider.service";

const deleteTaskDividerApi = async (
  api: ReturnType<typeof useApi>,
  dividerId: string
): Promise<void> => {
  await api.delete(`/projects/dividers/${dividerId}`);
};

export const useDeleteTaskDividerMutation = (
  options?: UseMutationOptions<void, AxiosError, string>
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, string>({
    mutationFn: (dividerId) => deleteTaskDividerApi(api, dividerId),
    onMutate: async (dividerId) => {
      await queryClient.cancelQueries({ queryKey: inboxBlockQueryKey() });

      const inboxTaskList =
        queryClient.getQueryData<(InboxBlockType | TaskDividerType)[]>(inboxBlockQueryKey());

      if (inboxTaskList) {
        const updatedProjectList = inboxTaskList.filter(
          (task: InboxBlockType | TaskDividerType) => {
            return task.id !== dividerId;
          }
        );
        queryClient.setQueryData(inboxBlockQueryKey(), updatedProjectList);
      }
      return { dividerId };
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inboxBlockQueryKey() });
    },
    ...options,
  });
};
