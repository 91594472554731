import { FlagIcon } from "../../icons";
import { TaskPriorityEnum } from "../../types/inbox/inbox-headers";

export const priorityOptions = [
  {
    value: TaskPriorityEnum.High,
    label: "High",
    icon: <FlagIcon color={"#FF5D5D"} />,
  },
  {
    value: TaskPriorityEnum.Medium,
    label: "Medium",
    icon: <FlagIcon color={"#FFB65F"} />,
  },
  {
    value: TaskPriorityEnum.Low,
    label: "Low",
    icon: <FlagIcon color={"#48D27F"} />,
  },
];
