import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useApi from "../../../services/auth/useApi";
import { inboxBlockOrderQueryKey } from "../inboxQueryKey";

export interface ReorderInboxBlockPayload {
  taskIds: string[];
  status: "InProgress";
}

export const reorderInboxBlockApi = async (
  api: ReturnType<typeof useApi>,
  payload: ReorderInboxBlockPayload
): Promise<void> => {
  await api.post("/tasks/order", {
    taskIds: payload.taskIds,
    status: payload.status,
  });
};

export const useReorderInboxBlockMutation = (
  options?: UseMutationOptions<void, AxiosError, ReorderInboxBlockPayload>
) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, ReorderInboxBlockPayload>({
    mutationFn: (payload) => reorderInboxBlockApi(api, payload),
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: inboxBlockOrderQueryKey() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inboxBlockOrderQueryKey() });
    },
    ...options,
  });
};
