import React, { ElementType, forwardRef } from "react";
import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/styles/size";
import { TaskPriorityEnum } from "../../../types/inbox/inbox-headers";
import { priorityOptions } from "../../../constants/task/TaskPriority";
import { hexToRgba } from "../../../utils/styles/color";
import { FlagIcon, OneExclamationMarkIcon } from "../../../icons";

interface PriorityButtonProps {
  onClick: () => void;
  currentValue?: TaskPriorityEnum;
  isMenuVisible: boolean;
}
export const PriorityButton = forwardRef<HTMLButtonElement, PriorityButtonProps>(
  ({ onClick, currentValue, isMenuVisible, ...props }, ref) => {
    const currentPriorityIcon =
      priorityOptions.find((option) => option.value === currentValue)?.icon || defaultPriorityIcon;

    return (
      <Tooltip label={`Priority`} placement={"bottom"}>
        <IconButton
          ref={ref}
          role={"group"}
          icon={currentPriorityIcon}
          onFocus={(e) => {
            e.currentTarget.blur();
            e.preventDefault();
          }}
          boxSizing={"border-box"}
          aria-label={`Priority`}
          onClick={onClick}
          h={pxToRem(24)}
          variant="outline"
          fontSize={pxToRem(16)}
          borderRadius={pxToRem(5)}
          minWidth={"initial"}
          boxSize={pxToRem(24)}
          _active={{}}
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
          _hover={getHoverStyles(currentValue)}
          {...getBasicStyles(isMenuVisible, currentValue)}
          {...props} // Pass remaining props
        />
      </Tooltip>
    );
  }
);

export const getBasicStyles = (isMenuVisible: boolean, value?: TaskPriorityEnum) => {
  const baseBorder = `1px solid ${hexToRgba("#ffffff", 0.08)}`;
  return value === undefined || value === TaskPriorityEnum.NoPriority
    ? {
        border: baseBorder,
        bgColor: isMenuVisible ? getHoverStyles(value).bgColor : "transparent",
      }
    : {
        border: isMenuVisible ? getHoverStyles(value).border : baseBorder,
        bgColor: "black",
      };
};

export const getHoverStyles = (value?: TaskPriorityEnum) => {
  return value === undefined
    ? { bgColor: hexToRgba("#ffffff", 0.08) }
    : { border: `1px solid ${hexToRgba("#ffffff", 0.4)}` };
};

export const defaultPriorityIcon = (
  <FlagIcon
    color={"#CACDCE"}
    _groupHover={{
      color: "white",
    }}
  />
);
