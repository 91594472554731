import { Flex, Icon, MenuItem, Text } from "@chakra-ui/react";
import React from "react";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";
import { CheckIcon } from "../../../icons";

export const RSVPMenuItem: React.FC<{
  label: string;
  isSelected: boolean;
  onClick: () => void;
  icon: React.ElementType;
}> = ({ label, isSelected, onClick, icon }) => {
  return (
    <MenuItem {...commonMenuItemStyles()} boxSizing={"border-box"} onClick={onClick}>
      <Icon color={isSelected ? "#6943ff" : "#D9D9D9"} as={icon} />
      <Flex justifyContent={"space-between"} alignItems={"center"} w={"full"}>
        <Text
          textStyle={"body3"}
          color={isSelected ? "#69e4ff" : "#CACDCE"}
          className={isSelected ? "" : "rsvp-menu-text"}
        >
          {label}
        </Text>
        {isSelected && <CheckIcon w={pxToRem(14)} h={pxToRem(14)} color={"#69e4ff"} />}
      </Flex>
    </MenuItem>
  );
};

const commonMenuItemStyles = () => {
  return {
    p: 2,
    bg: "#313435",
    color: "#CACDCE",
    _hover: {
      bg: hexToRgba("#ffffff", 0.04),
      ".rsvp-menu-text": {
        color: "#fff", // hover 시 Text 색상을 #fff로 설정
      },
    },
    _focus: {
      outline: "none",
      boxShadow: "none",
    },
    _first: {
      borderTopRadius: 6,
    },
    _last: {
      borderBottomRadius: 6,
    },
    _only: {
      borderRadius: 6,
    },
    h: pxToRem(32),
    gap: 1.5,
  };
};
