import { useState, useEffect } from "react";
import { useDrag } from "react-dnd";
// import { createPortal } from "react-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { dragEventState } from "../../../../../recoil/calendar/calendarState";
import { draggedEventState } from "../../../../../recoil/calendar/calendarStateV2";
import useApi from "../../../../../services/auth/useApi";
import { useJuneTrackCall } from "../../../../../utils/june/analytics";
// import InboxReorderDragLayer from "../../../../TaskInbox/InboxReorderDragLayer";
import styles from "./IntegrationDetailItems.module.css";

import { COMPLETED, INPROGRESS } from "../../../../../constants/taskStateType";
import { doneTaskListState } from "../../../../../recoil/taskList/doneTaskListState";
import { inboxTaskListState } from "../../../../../recoil/taskList/inboxTaskListState";
import { visibilityState } from "../../../../../recoil/calendar/settingCalendar";
// import IntegrationDragLayer from "../../../IntegrationDragLayer";

const MailItem = ({
  data,
  integrationId,
  creator,
  setData: setMailData,
  listData,
  reloadFunction,
}) => {
  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);
  const [dragEvent, setDragEvent] = useRecoilState(dragEventState);
  const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);
  const [doneTaskList, updateDoneTaskList] = useRecoilState(doneTaskListState);
  const defaultVisibility = useRecoilValue(visibilityState);

  const trackCall = useJuneTrackCall();
  const api = useApi();

  const dragData = {
    ...data,
    id: "int-" + data.id,
    link: data.url,
    title: data.subject,
    kind: "gmail",
    provider: "gmail",
    integrationId: integrationId,
    color: "#abadad",
    creator: creator,
    dragType: "integrationDrag",
    integration: {
      integrationId: integrationId,
      itemId: data.id,
      provider: "gmail",
      link: data.url,
      title: data.subject,
    },
  };

  const [{ isDragging }, drag] = useDrag({
    type: "integrationDrag",
    item: dragData,
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        // NOTE 드랍이 되었을 때
        const dropResult = monitor.getDropResult();
        if (!dropResult) {
          updateInboxTaskList((current) => {
            const updatedTasks = current.filter((task) => task.id !== data.id);
            return updatedTasks;
          });
        } else {
          if (dropResult.dropType !== "calendarIntegrationDrop") {
            // NOTE 드랍한 곳이 인박스일 때
            const newItem = {
              id: dragData.id,
              title: item.title,
              integration: {
                integrationId: item.integrationId,
                itemId: data.id,
                provider: item.kind,
                link: item.link,
              },
              visibility: defaultVisibility === "public" ? "public" : "private",
              transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
              itemStatus: dropResult.dropType === "doneTask" ? COMPLETED : INPROGRESS,
              taskType: "Task",
            };

            api
              .post("tasks", newItem, {
                headers: {
                  "X-Requester": item.creator,
                },
              })
              .then((res) => {
                trackCall("create_block", {
                  location: "integration",
                });
              });
          }
          // NOTE inbox, calendar 드랍 시 공통 로직
          setMailData((current) => {
            return current.filter((mail) => mail.id !== data.id);
          });
        }
      } else {
        // NOTE 드랍을 안했을 때(원상복귀)
        updateInboxTaskList((current) => {
          const updatedTasks = current.filter((task) => task.id !== dragData.id);
          return updatedTasks;
        });

        updateDoneTaskList((current) => {
          const updatedTasks = current.filter((task) => task.id !== dragData.id);
          return updatedTasks;
        });
      }
      setDragEvent(null);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleGmailRedirect = () => {
    trackCall("click_integration", { location: "integration", type: "gmail" });
    window.open(data.url, "_blank");
  };

  return (
    <div
      ref={drag}
      className={` ${isDragging ? styles["dragging"] : styles["mailItem"]}`}
      draggable="true"
      key={{ title: "mail title" }}
      onDragStart={() => {
        setDraggedEvent(dragData);
      }}
      onClick={handleGmailRedirect}
    >
      <div className={styles["mailItem-type"]}>
        <div className={styles["mailItem-type-icon"]}></div>
        <span>{data.from}</span>
      </div>
      <div className={styles["mailItem-title"]}>{data.subject}</div>
      <span
        className={styles["mailItem-body"]}
        dangerouslySetInnerHTML={{ __html: data.snippet }}
      />
      {/* {isDragging &&
        createPortal(
          <div style={{ cursor: "pointer" }}>
            <IntegrationDragLayer />
          </div>,
          document.body
        )} */}
    </div>
  );
};

export default MailItem;
