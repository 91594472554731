import React, { useRef } from "react";
import { Divider, useDisclosure } from "@chakra-ui/react";
import { SideBarContainer } from "./SideBarContainer";
import { SideBarActionSection } from "./SideBarActionSection";
import { SideBarViewSection } from "./SideBarViewSection";
import { SpacesSection } from "./SpacesSection";
import { TrashPopupWrapper } from "../TrashPopup/TrashPopupWrapper";

interface SpaceSideBarProps {
  isExpanded: boolean;
  onToggle: () => void;
  onOpen: () => void;
  onClickSettings: () => void;
  onUpdateCalendar: () => void;
}

export const SpaceSideBar: React.FC<SpaceSideBarProps> = ({
  isExpanded,
  onToggle,
  onOpen,
  onClickSettings,
}) => {
  const {
    isOpen: isTrashPopupOpen,
    onClose: onCloseTrashPopup,
    onToggle: onToggleTrashPopup,
  } = useDisclosure();

  const trashToggleButtonRef = useRef<HTMLButtonElement | null>(null);
  return (
    <SideBarContainer isExpanded={isExpanded}>
      <SideBarViewSection isExpanded={isExpanded} onToggleSidebar={onToggle} />

      <Divider bg={"white"} opacity={0.08} />

      <SpacesSection isExpanded={isExpanded} onOpenSidebar={onOpen} />

      <Divider bg={"white"} opacity={0.08} />

      <SideBarActionSection
        isExpanded={isExpanded}
        onClickSettings={onClickSettings}
        onClickTrash={onToggleTrashPopup}
        trashToggleButtonRef={trashToggleButtonRef}
      />

      <TrashPopupWrapper
        isOpen={isTrashPopupOpen}
        onClose={onCloseTrashPopup}
        toggleButtonRef={trashToggleButtonRef}
      />
    </SideBarContainer>
  );
};
