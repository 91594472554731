import React, { useEffect, useState } from "react";

import { createPortal } from "react-dom";

import InboxReorderDragLayer from "../../InboxReorderDragLayer";
import styles from "./TaskRow.module.css";

import { Flex } from "@chakra-ui/react";
import { pxToRem } from "../../../../utils/styles/size";
import InboxSubTaskToggle from "./InboxSubTaskToggle";

import InboxBlockBody from "./InboxBlockBody";

import { CalendarBlockType, InboxBlockType } from "../../../../types/block/BlockType";
import { ContextMenu } from "../../../Common/ContextMenu";
import { useInboxDragAndDrop } from "../../../../hooks/inbox/useInboxDragAndDrop";

interface TaskRowProps {
  data: InboxBlockType | CalendarBlockType;
  index: number;
  onDataDuplicate: (e: React.MouseEvent, rowData: InboxBlockType | CalendarBlockType) => void;
  onReorder: (payload: string[]) => void;
  isSelected: boolean;
  onSelect: (id: string | null) => void;
  listType: string;
  isOverdue?: boolean;
  onDataDelete: (rowData: InboxBlockType | CalendarBlockType) => void;
  onClickBlock: (
    e: React.MouseEvent,
    data: InboxBlockType | CalendarBlockType,
    ref: React.RefObject<HTMLDivElement>
  ) => void;
}
const TaskRow = React.memo(
  ({
    data,
    index,
    onDataDuplicate,
    onDataDelete,
    onReorder,
    onSelect,
    isSelected,
    listType,
    onClickBlock,
  }: TaskRowProps) => {
    const [prevIndex, setPrevIndex] = useState(index);
    const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);
    const [integrationImg, setIntegrationImg] = useState<string | null>(null);

    const { ref, isDragging: shouldShowPreview } = useInboxDragAndDrop({
      index,
      data,
      onReorder,
      prevIndex,
      setPrevIndex,
    });

    useEffect(() => {
      const integrationData = data.integration?.provider;
      if (integrationData && integrationData !== "") {
        setIntegrationImg(integrationData);
      }
    }, []);

    const handleDelete = async () => {
      setIsSidebarModalOn(false);
      onDataDelete(data);
    };

    const handleItemContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      const { clientX, clientY } = e;
      setIsSidebarModalOn(true);
      onSelect(data.id);
    };

    const handleSideBarClose = () => {
      setIsSidebarModalOn(false);
      onSelect(null);
    };

    const handleDuplicate = (e: React.MouseEvent) => {
      setIsSidebarModalOn(false);
      onDataDuplicate(e, data);
    };

    const menuItems = [
      {
        label: "Duplicate",
        onClick: handleDuplicate,
      },
      {
        label: "Delete",
        onClick: handleDelete,
      },
    ];

    return (
      <Flex
        ref={ref}
        w={"full"}
        border={"1px solid"}
        borderRadius={"5px"}
        bg={shouldShowPreview || isSelected ? "whiteAlpha.50" : "initial"}
        borderColor={isSelected ? "whiteAlpha.200" : "transparent"}
        _hover={{ backgroundColor: "whiteAlpha.50" }}
        _focus={{ border: "1px solid whiteAlpha.50", bg: "whiteAlpha.200" }}
        id="inbox-general"
        cursor={"pointer"}
        minH={pxToRem(30)}
        boxSizing="border-box"
        flexWrap={"nowrap"}
        onClick={(e) => onClickBlock(e, data, ref)}
        onContextMenu={handleItemContextMenu}
        draggable="true"
        key={data.id}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Flex opacity={shouldShowPreview ? 0 : 1} w={"full"}>
          <InboxSubTaskToggle />
          <InboxBlockBody data={data} isOverdue={false} isCompleted={false} />
        </Flex>
        {integrationImg && (
          <div
            className={`${styles["integration"]} ${
              integrationImg === "gmail"
                ? styles["integration-gmail"]
                : integrationImg === "jira"
                  ? styles["integration-jira"]
                  : integrationImg === "slack" && styles["integration-slack"]
            } `}
          ></div>
        )}

        {isSidebarModalOn && (
          <ContextMenu
            isOpen={isSidebarModalOn}
            onClose={handleSideBarClose}
            menuItems={menuItems}
          />
        )}
        {shouldShowPreview &&
          createPortal(
            <Flex cursor="pointer">
              <InboxReorderDragLayer />
            </Flex>,
            document.body
          )}
      </Flex>
    );
  }
);

export default TaskRow;
