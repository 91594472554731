export type InboxHeaderTab = "Inbox" | "Today";

export interface ToggleVisibility {
  overdue: boolean;
  completed: boolean;
  planned?: boolean;
}

export interface ToggleVisibilityByTab {
  Inbox: ToggleVisibility;
  Today: ToggleVisibility;
}

export enum TaskPriorityEnum {
  High = "High",
  Medium = "Medium",
  Low = "Low",
  NoPriority = "NoPriority",
}

export enum EventRSVPResponse {
  Declined = "declined",
  Tentative = "tentative",
  Accepted = "accepted",
  NeedsAction = "needsAction",
}
