import { ServerEventTimeType } from "../../../types/block/BlockContentsType";
import { CalendarBlockType, ServerCalendarBlockType } from "../../../types/block/BlockType";
import moment from "moment";

export const convertClientBlockTypeToServerBlockType = (
  eventData: CalendarBlockType
): ServerCalendarBlockType => {
  const { start, end, allDay, startTimeZone, endTimeZone, ...restProps } = eventData;

  // 날짜 변환 함수
  const formatDate = (date: string | Date): Date => {
    if (date instanceof Date) {
      return date;
    }
    return new Date(date);
  };

  // 시작 시간 객체 생성
  const startObj = (() => {
    if (typeof start === "object" && ("date" in start || "dateTime" in start)) {
      return start;
    }
    return allDay
      ? { date: moment(formatDate(start as string | Date)).format("YYYY-MM-DD") }
      : { dateTime: formatDate(start as string | Date), timeZone: startTimeZone || "" };
  })();

  // 종료 시간 객체 생성
  const endObj = (() => {
    if (typeof end === "object" && ("date" in end || "dateTime" in end)) {
      return end;
    }
    return allDay
      ? { date: moment(formatDate(end as string | Date)).format("YYYY-MM-DD") }
      : { dateTime: formatDate(end as string | Date), timeZone: endTimeZone || "" };
  })();

  // 나머지 속성들은 자동으로 복사하고 변환된 날짜 정보만 추가
  return {
    ...restProps,
    allDay,
    start: startObj as ServerEventTimeType,
    end: endObj as ServerEventTimeType,
  };
};
