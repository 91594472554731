import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSetRecoilState } from "recoil";
import { mobaCalendarListState } from "../../recoil/calendar/mobaCalendarListState";
import useApi from "../../services/auth/useApi";
import { CalendarBlockType } from "../../types/block/BlockType";
import { useJuneTrackCall } from "../../utils/june/analytics";

export interface DeleteCalendarBlockParams {
  creator: string;
  dataId: string;
}

export interface UpdateCalendarBlockontext {
  previousCalendarList: CalendarBlockType[] | undefined;
  previousMobaList: CalendarBlockType[];
}

export const deleteCalendarBlockApi = async (
  api: ReturnType<typeof useApi>,
  creator: string,
  dataId: string
): Promise<void> => {
  await api.patch(
    `tasks/${dataId}/mark`,
    { params: { notification: false } },
    {
      headers: { "x-requester": creator },
    }
  );
};

export const useDeleteCalendarBlockMutation = (
  options?: UseMutationOptions<
    void,
    AxiosError,
    DeleteCalendarBlockParams,
    UpdateCalendarBlockontext
  >
) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const trackCall = useJuneTrackCall();
  const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);

  return useMutation<void, AxiosError, DeleteCalendarBlockParams, UpdateCalendarBlockontext>({
    mutationFn: ({ creator, dataId }) => deleteCalendarBlockApi(api, creator, dataId),

    onMutate: async ({ dataId }) => {
      await queryClient.cancelQueries({ queryKey: ["calendar"] });

      const previousCalendarList = queryClient.getQueryData<CalendarBlockType[]>(["calendar"]);
      const previousMobaList = await new Promise<CalendarBlockType[]>((resolve) => {
        setMobaCalendarList((current: CalendarBlockType[]) => {
          resolve(current);
          return current;
        });
      });

      setMobaCalendarList((currentList: CalendarBlockType[]) =>
        currentList.filter((task) => task.id !== dataId)
      );

      return { previousCalendarList, previousMobaList };
    },

    onError: (_error, _params, context) => {
      if (context) {
        queryClient.setQueryData<CalendarBlockType[]>(["calendar"], context.previousCalendarList);
        setMobaCalendarList(context.previousMobaList);
      }
    },

    onSuccess: (_data, _params) => {
      // Track Call
    },

    onSettled: () => {
      // queryClient.invalidateQueries
    },

    ...options,
  });
};
