import { v4 as uuid4 } from "uuid";

export const isDividerInput = (input: string) => {
  return input === "---";
};

export interface TaskDividerType {
  id: string;
  category: TaskCategory.TaskDivider;
  color?: string;
  creator: string;
}

export type CreateTaskDividerType = TaskDividerType;

export enum TaskCategory {
  "TaskDivider" = "task-divider",
}

export const createTaskDividerPayload = (email: string, location: string): TaskDividerType => {
  return {
    id: `${email}-task-divider-${uuid4()}`,
    category: TaskCategory.TaskDivider,
    creator: email,
  };
};
