import { pxToRem } from "../../../utils/styles/size";
import { useCallback, useRef, useState } from "react";
import createInboxBlockPayload from "../../../services/inbox/createInboxBlockPayload";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { accountState } from "../../../recoil/account/accountStateV2";
import { visibilityState } from "../../../recoil/calendar/settingCalendar";
import { addSelectedSpace, extractSpaceId } from "../../../services/space/space.service";
import {
  inProgressSelectedSpaceIdListSelector,
  selectedSpaceIdListState,
} from "../../../recoil/spaces/selectedSpaceIdListState";
import { useCreateInboxBlockMutation } from "../../../react-query/inbox/core/useCreateInboxBlockMutation";
import { InboxBlockType, ServerCalendarBlockType } from "../../../types/block/BlockType";
import { Flex, Textarea } from "@chakra-ui/react";
import { InboxHeaderTab } from "../../../types/inbox/inbox-headers";

import moment from "moment";
import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import {
  createTaskDividerPayload,
  isDividerInput,
} from "../../../services/divider/divider.service";
import { getPrimaryAccount } from "../../../services/auth/account.service";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import { useCreateTaskDividerMutation } from "../../../react-query/inbox/divider/useCreateTaskDividerMutation";
import { useCreateCalendarBlockMutation } from "../../../react-query/calendar/useCreateCalendarBlockMutation";
import { convertServerBlockTypeToClientBlockType } from "../../../utils/common/block/convertServerBlockTypeToClientBlockType";
import { isValidUrl } from "../../../utils/common/block/links/isValidUrl";
import { MODAL_OFFSET_Y, MODAL_OFFSET_X } from "../../../constants/task/InboxInput";

interface InboxInputProps {
  activeTab: InboxHeaderTab;
  handleSaveTaskSelectedId: (id: string | null) => void;
}

const InboxInput = ({ activeTab, handleSaveTaskSelectedId }: InboxInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const accountData = useRecoilValue(accountState);
  const [newTaskName, setNewTaskName] = useState<string>("");
  const [isBlurredWithText, setIsBlurredWithText] = useState(false);

  const defaultVisibility = useRecoilValue(visibilityState);
  const inProgressSelectedSpaceIdList = useRecoilValue(inProgressSelectedSpaceIdListSelector);
  const setSelectedSpaces = useSetRecoilState(selectedSpaceIdListState);
  const setTaskDetail = useSetRecoilState(taskPopupState);
  const { mutate: createDivider } = useCreateTaskDividerMutation();
  const { mutate: createInboxBlockMutate } = useCreateInboxBlockMutation();
  const { mutate: createCalendarBlockMutate } = useCreateCalendarBlockMutation();

  const trackCall = useJuneTrackCall();

  const getTextareaPosition = () => {
    const element = textareaRef.current;
    if (!element) return null;

    const rect = element.getBoundingClientRect();
    return {
      bottom: rect.bottom,
      right: rect.right,
      absoluteBottom: rect.bottom + window.scrollY,
      absoluteRight: rect.right + window.scrollX,
    };
  };

  const handleCreateDivider = () => {
    const email = getPrimaryAccount(accountData)?.email ?? "";
    const newItem = createTaskDividerPayload(email, "task");
    createDivider(newItem, {
      onSuccess: () => {
        trackCall("create_divider", { location: "inbox" });
      },
    });
  };

  const handleTaskChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newInput = e.target.value;
    setNewTaskName(newInput);
    handleResizeHeight();

    if (activeTab === "Inbox" && isDividerInput(newInput)) {
      setNewTaskName("");
      handleCreateDivider();
    }
  };

  const handleBlur = () => {
    if (newTaskName.trim() !== "") {
      setIsBlurredWithText(true);
    } else {
      setIsBlurredWithText(false);
    }
  };

  const handleFocus = () => {
    setIsBlurredWithText(false);
  };

  const handleAddLink = (
    trimmedTaskName: string,
    accountData: any,
    defaultVisibility: any,
    inProgressSelectedSpaceIdList: any,
    activeTab: string
  ) => {
    const newItem: InboxBlockType = {
      ...createInboxBlockPayload(
        "",
        accountData?.accountInfo?.accounts[0]?.email ?? "",
        defaultVisibility
      ),
      ...extractSpaceId(inProgressSelectedSpaceIdList),

      links: [
        {
          url: trimmedTaskName,
          name: "",
          isLoading: true,
        },
      ],
    };

    setSelectedSpaces((prev) => addSelectedSpace(prev, newItem.projectId));
    handleSaveTaskSelectedId(newItem.id);

    const position = getTextareaPosition();
    if (activeTab === "Inbox") {
      createInboxBlockMutate(newItem, {
        onSuccess: () => {
          setTaskDetail((prev) => ({
            ...prev,
            isVisible: true,
            data: newItem,
            modalPosition: {
              x: position?.right ? position.right + MODAL_OFFSET_X : 0,
              y: position?.bottom ? position.bottom + MODAL_OFFSET_Y : 0,
            },
            isCreateSelectEvent: true,
            type: "inbox",
          }));
        },
      });
      return;
    }

    if (activeTab === "Today") {
      const calendarNewItem: ServerCalendarBlockType = {
        ...newItem,
        allDay: true,
        start: { date: moment(new Date()).format("YYYY-MM-DD") },
        end: { date: moment(new Date()).format("YYYY-MM-DD") },
      };

      const clientConverted = convertServerBlockTypeToClientBlockType(calendarNewItem);
      createCalendarBlockMutate(calendarNewItem, {
        onSuccess: () => {
          setTaskDetail((prev) => ({
            ...prev,
            isVisible: true,
            data: clientConverted,
            modalPosition: {
              x: position?.right ? position.right + MODAL_OFFSET_X : 0,
              y: position?.bottom ? position.bottom + MODAL_OFFSET_Y : 0,
            },
            isCreateSelectEvent: true,
            type: "inbox",
          }));
        },
      });

      return;
    }
  };

  const handleKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.nativeEvent.isComposing) {
        return;
      }
      if (e.key === "Escape") {
        setNewTaskName("");
        if (textareaRef.current) {
          textareaRef.current.style.height = "36px";
          textareaRef.current.blur();
        }

        return;
      }
      if (e.key === "Enter") {
        if (e.shiftKey) {
          return;
        }
        e.preventDefault();

        const trimmedTaskName = newTaskName.trim();
        if (!trimmedTaskName) return;

        const isUrl = isValidUrl(trimmedTaskName);

        if (isUrl) {
          handleAddLink(
            trimmedTaskName,
            accountData,
            defaultVisibility,
            inProgressSelectedSpaceIdList,
            activeTab
          );
        } else {
          // 일반 텍스트인 경우
          const newItem: InboxBlockType = {
            ...createInboxBlockPayload(
              trimmedTaskName,
              accountData?.accountInfo?.accounts[0]?.email ?? "",
              defaultVisibility
            ),
            ...extractSpaceId(inProgressSelectedSpaceIdList),
          };

          setSelectedSpaces((prev) => addSelectedSpace(prev, newItem.projectId));

          if (activeTab === "Inbox") {
            createInboxBlockMutate(newItem);
          } else {
            //NOTE calendar allday로 생성
            const calendarNewItem: ServerCalendarBlockType = {
              ...newItem,
              allDay: true,
              start: { date: moment(new Date()).format("YYYY-MM-DD") },
              end: { date: moment(new Date()).format("YYYY-MM-DD") },
            };
            createCalendarBlockMutate(calendarNewItem);
          }
        }

        setNewTaskName("");
        if (textareaRef.current) {
          textareaRef.current.style.height = "36px";
        }
      } else if (e.key === "Escape") {
        textareaRef?.current?.blur();
      }
    },
    [newTaskName, accountData, defaultVisibility, inProgressSelectedSpaceIdList]
  );

  const handleResizeHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = "37px";
    const scrollHeight = textarea.scrollHeight;

    if (scrollHeight >= 37 && scrollHeight <= 74) {
      textarea.style.height = `${scrollHeight}px`;
    } else if (scrollHeight > 74) {
      textarea.style.height = "74px";
    }
  };

  return (
    <Flex pt={2}>
      <Textarea
        ref={textareaRef}
        placeholder="+ Add a Task"
        padding={pxToRem(8)}
        flexShrink="0"
        boxSizing="border-box"
        h={pxToRem(37)}
        border={"none"}
        color={"white"}
        // TODO textStyle 적용 안되는 이슈 있음
        textStyle={"body1"}
        fontSize={pxToRem(14)}
        fontWeight={400}
        lineHeight={"150%"}
        _placeholder={{
          color: "#7C7D7D",
          transition: "color 0.3s ease",
          fontSize: pxToRem(14),
          fontWeight: 400,
          lineHeight: "150%",
        }}
        backgroundColor={isBlurredWithText ? "rgba(0, 0, 0, 0.20)" : "initial"}
        _focus={{
          backgroundColor: "rgba(0, 0, 0, 0.20)",
          boxShadow: "none",
          caretColor: "#69E4FF",
          _placeholder: { color: "transparent" },
        }}
        _active={{
          outline: "none",
          border: "none",
          caretColor: "#69E4FF",
          backgroundColor: "rgba(0, 0, 0, 0.20)",
          _placeholder: { color: "transparent" },
        }}
        _hover={{
          backgroundColor: "whiteAlpha.100",
          _placeholder: { color: "#fff" },
          _active: {
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            _placeholder: { color: "transparent" },
          },
          _focus: {
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            _placeholder: { color: "transparent" },
          },
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={newTaskName}
        onChange={handleTaskChange}
        onKeyDown={handleKeyDown}
        resize="none"
        rows={1}
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      />
    </Flex>
  );
};

export default InboxInput;
