import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CalendarBlockType, InboxBlockType, isCalendarBlock } from "../../types/block/BlockType";
import { TaskDividerType } from "../../services/divider/divider.service";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import { isInboxBlock } from "../../services/inbox/inboxType";
import { draggedEventState } from "../../recoil/calendar/calendarStateV2";
import { is } from "@blocknote/core/types/src/i18n/locales";

export interface InboxDragItem {
  dragType: string;
  data: InboxBlockType | TaskDividerType | CalendarBlockType;
  index: number;
}

interface useDragAndDropProps {
  prevIndex: number;
  setPrevIndex: (newIndex: number) => void;
  data: InboxBlockType | TaskDividerType | CalendarBlockType;
  index: number;
  onReorder: (orderId: string[]) => void;
}

export const useInboxDragAndDrop = ({
  prevIndex,
  setPrevIndex,
  index,
  data,
  onReorder,
}: useDragAndDropProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [inboxList, setInboxList] = useRecoilState(inboxTaskListState);
  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);

  const isListDragging = useMemo(() => draggedEvent !== null, [draggedEvent]);

  const getDragItem = useCallback(() => {
    const dragItem = { dragType: "inboxItem" as const, data, index };

    if (isCalendarBlock(data) || isInboxBlock(data)) {
      setDraggedEvent({
        ...data,
        dragType: "inboxItem",
        index: index,
        start: "",
        end: "",
        allDay: false,
        title: data.title,
        visibility: data.visibility,
        transparency: data.transparency,
        taskType: data.taskType,
      });
    }
    return dragItem;
  }, [data, index, setDraggedEvent]);

  const [draggingItem, drag, preview] = useDrag({
    type: "inbox",
    item: getDragItem,
    collect: useCallback((monitor: DragSourceMonitor) => {
      const item = monitor.getItem() as { dragType: string };
      if (item && item.dragType !== "inboxItem") {
        return;
      }

      return {
        isDragging: monitor.isDragging(),
        item: monitor.getItem() as InboxDragItem,
      };
    }, []),

    // end: () => {
    //   setDraggedEvent(null);
    // },
  });
  const { item, isDragging = false } = draggingItem ?? {};

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    const transformValue = 100;
    if (prevIndex !== index && isListDragging && !isDragging) {
      const animation = ref.current?.animate(
        [
          { transform: `translateY(${(prevIndex - index) * transformValue}%)` },
          { transform: "translateY(0%)" },
        ],
        {
          duration: 150,
          easing: "ease-in-out",
        }
      );
      if (animation) {
        animation.onfinish = () => {
          setPrevIndex(index);
        };
      }
    } else {
      // 드래그가 아닌 경우는 애니메이션 없이 바로 업데이트
      setPrevIndex(index);
    }
  }, [index, prevIndex, isDragging, isListDragging]);

  const [, drop] = useDrop({
    accept: "inbox",
    hover: (item: InboxDragItem, monitor: any) => {
      if (!ref.current || isDragging) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      const dragIndex = item.index;
      const hoverIndex = index;
      const isDragItemDivider = !isInboxBlock(item?.data);

      // 디바이더는 작은 높이를 가졌기 때문에, 이동 감도를 줄이기 위해 70% threshold 설정
      // 일반 항목은 50% 기준으로 처리 (절반을 넘어가면 moveProject 트리거)
      const threshold = isDragItemDivider ? hoverHeight * 0.7 : hoverHeight / 2;

      if (
        (dragIndex < hoverIndex && hoverClientY < threshold) ||
        (dragIndex > hoverIndex && hoverClientY > hoverHeight - threshold)
      ) {
        return;
      }

      moveInboxList(dragIndex, hoverIndex);
    },
    drop() {
      if (inboxList) {
        onReorder(inboxList.map((block) => block.id));
      }
    },
  });

  const moveInboxList = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex === hoverIndex || !item) {
        return;
      }
      if (!inboxList) return;
      const updatedList = [...inboxList];
      const [movedItem] = updatedList.splice(dragIndex, 1); // 드래그된 아이템 삭제
      updatedList.splice(hoverIndex, 0, movedItem);
      setInboxList(updatedList);
      item.index = hoverIndex;
    },
    [item, inboxList]
  );

  drag(drop(ref));
  // const shouldShowPreview = item && item.index === index && item.dragType === "inboxItem";

  return { ref, isDragging };
};
