import { Button, Flex, Text } from "@chakra-ui/react";

import { pxToRem } from "../../../utils/styles/size";

interface InboxHeaderButtonProps {
  title: string;
  count: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  activeTab: string;
}

const InboxHeaderTabButton = ({ title, count, onClick, activeTab }: InboxHeaderButtonProps) => {
  return (
    <Button
      h={pxToRem(32)}
      padding={"0 8px"}
      gap={pxToRem(6)}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor={activeTab === title ? "whiteAlpha.200" : "transparent"}
      border={"1px solid"}
      borderColor={"whiteAlpha.200"}
      borderRadius={"5px"}
      color={"white"}
      _hover={{
        background: activeTab === title ? "whiteAlpha.200" : "whiteAlpha.50",
      }}
      value={title}
      onClick={onClick}
    >
      <Flex>
        <Text textStyle="label3" lineHeight={0}>
          {title}
        </Text>
      </Flex>
      <Flex>
        <Text textStyle={"body3"} color={"#ABADAD"} lineHeight={0}>
          {count}
        </Text>
      </Flex>
    </Button>
  );
};

export default InboxHeaderTabButton;
