import { Flex, MenuItem, Text, useStyleConfig } from "@chakra-ui/react";
import React from "react";
import { pxToRem } from "../../../utils/styles/size";
import { hexToRgba } from "../../../utils/styles/color";
import { CheckIcon } from "../../../icons";

export const PriorityMenuItem: React.FC<{
  label: string;
  isSelected: boolean;
  onClick: () => void;
  children: React.ReactNode;
}> = ({ label, isSelected, onClick, children }) => {
  return (
    <MenuItem {...commonMenuItemStyles(isSelected)} boxSizing={"border-box"} onClick={onClick}>
      {children}
      <Flex justifyContent={"space-between"} alignItems={"center"} w={"full"}>
        <Text
          textStyle={"body3"}
          className={!isSelected ? "menu-item-text" : ""}
          color={isSelected ? "#69e4ff" : "#CACDCE"}
        >
          {label}
        </Text>
        {isSelected && <CheckIcon w={pxToRem(14)} h={pxToRem(14)} color={"#69e3ff"} />}
      </Flex>
    </MenuItem>
  );
};

const commonMenuItemStyles = (isSelected: boolean) => {
  return {
    p: 2,
    bg: "#313435",
    color: "#CACDCE",
    _hover: {
      bg: hexToRgba("#ffffff", 0.04),
      ".menu-item-text": {
        color: "#fff",
      },
    },
    _focus: {
      outline: "none",
      boxShadow: "none",
    },
    _first: {
      borderTopRadius: 6,
    },
    _last: {
      borderBottomRadius: 6,
    },
    _only: {
      borderRadius: 6,
    },
    h: pxToRem(32),
    gap: 1.5,
  };
};
