import { CalendarBlockType, ServerCalendarBlockType } from "../../types/block/BlockType";
import { convertGoogleCalendarToMobaCalendar } from "./convertGoogleCalendarToMobaCalendar";

export const initialConvertGoogleCalendarToMobaCalendar = (
  googleCalendarEvent: ServerCalendarBlockType[]
): CalendarBlockType[] => {
  return googleCalendarEvent
    .filter((it) => it.start && it.end)
    .map((it) => {
      return convertGoogleCalendarToMobaCalendar(it);
    });
};
