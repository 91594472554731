import { useJuneTrackCall } from "../utils/june/analytics";
import { RefObject } from "react";
import {
  isNoteDataChanged,
  isNoteEmpty,
} from "../utils/taskDetail/checkDataChanged/isNoteDataChanged";

interface NoteData {
  current: string;
}

interface Link {
  name: string;
  url: string;
}

interface LinkData {
  current: Link[];
}

interface TaskDetail {
  type: string;
  // NOTE 나머지 타입에 대해서는 추후 추가 필요
}

interface Data {
  note: NoteData;
  links: LinkData[];
}

export function useJuneTrackChanges() {
  const trackCall = useJuneTrackCall();
  const currentActiveTab = localStorage.getItem("activeTab") ?? "Inbox";

  const trackNoteLinkChanges = (
    initialNoteData: RefObject<NoteData>,
    data: Data,
    initialLinks: RefObject<LinkData[]>,
    taskDetail: TaskDetail,
    isTask: boolean,
    toggleExpand: boolean
  ) => {
    if (isNoteDataChanged(initialNoteData.current, data.note) && !isNoteEmpty(data.note)) {
      trackCall("add_note", {
        location:
          taskDetail.type === "calendar"
            ? taskDetail.type
            : currentActiveTab === "Inbox"
              ? "inbox"
              : "today_inbox",
        type: isTask ? "task" : "event",
        detail_type: toggleExpand ? "full" : "modal",
      });
    }

    const isListDataChanged = initialLinks.current?.length !== data.links?.length;
    const isCurrentLinksEmpty = !data.links?.length;
    if (isListDataChanged && !isCurrentLinksEmpty) {
      trackCall("add_link", {
        location:
          taskDetail.type === "calendar"
            ? taskDetail.type
            : currentActiveTab === "Inbox"
              ? "inbox"
              : "today_inbox",
        type: isTask ? "task" : "event",
        detail_type: toggleExpand ? "full" : "modal",
        count: data.links && data.links.length,
      });
    }
  };

  return trackNoteLinkChanges;
}
