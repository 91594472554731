import { atom, selector } from "recoil";
import { BlockPopupState } from "../../types/block/BlockType";

export const taskPopupState = atom<BlockPopupState>({
  key: "taskPopupState",
  default: {
    isVisible: false,
    data: null,
    modalPosition: { x: 0, y: 0 },
    targetRect: null,
    onClose: null,
    loadData: null,
    handleDataDuplicate: null,
    type: "", // inbox or calendar,
    handleEventDelete: null,
    handleEventChange: null,
    modalHeight: undefined,
  },
});

export const taskPopupVisibleSelector = selector({
  key: "taskPopupVisibleSelector",
  get: ({ get }) => {
    const taskPopup = get(taskPopupState);
    return taskPopup.isVisible;
  },
});
