import { Flex, IconButton, Text } from "@chakra-ui/react";
import { pxToRem } from "../../../../utils/styles/size";
import { priorityOptions } from "../../../../constants/task/TaskPriority";
import {
  defaultPriorityIcon,
  getBasicStyles,
} from "../../../../views/TaskDetail/Header/PriorityButton";
import {
  CalendarBlockType,
  InboxBlockType,
  isCalendarBlock,
} from "../../../../types/block/BlockType";
import InboxBlockDateTime from "./InboxBlockDateTime";

interface InboxBlockRightSectionProps {
  data: CalendarBlockType | InboxBlockType;

  isOverdue: boolean;
  isTodayGeneral?: boolean;
}
const InboxBlockRightSection = ({
  isOverdue,
  isTodayGeneral,

  data,
}: InboxBlockRightSectionProps) => {
  const currentPriorityIcon = priorityOptions.find((option) => {
    return option.value === data.priority;
  })?.icon;
  return (
    <Flex gap={1.5} justifyContent={"flex-start"} alignItems={"center"} py={0.5}>
      {data && isCalendarBlock(data) && data.start && !data.allDay && (
        <InboxBlockDateTime
          isOverdue={isOverdue}
          isTodayGeneral={isTodayGeneral ?? false}
          isAllday={data.allDay}
          startDateTime={new Date(data.start).toString()}
          endDateTime={new Date(data.start).toString()}
        />
      )}
      {currentPriorityIcon ? (
        <IconButton
          icon={currentPriorityIcon}
          boxSizing={"border-box"}
          aria-label={`Priority`}
          p={0}
          flexShrink={1}
          h={"full"}
          w={"full"}
          variant="outline"
          fontSize={pxToRem(14)}
          borderRadius={pxToRem(3)}
          minWidth={"initial"}
          boxSize={pxToRem(14)}
          _active={{}}
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
          cursor={"initial"}
          _hover={{ bgColor: "black" }}
          {...getBasicStyles(false, data.priority)}
        />
      ) : null}
    </Flex>
  );
};

export default InboxBlockRightSection;
