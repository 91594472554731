import { useRecoilValue } from "recoil";

import { CalendarBlockType, InboxBlockType } from "../../types/block/BlockType";
import { useMemo } from "react";
import { doneTaskListState } from "../../recoil/taskList/doneTaskListState";
import { threeMonthsEventsSelector } from "../../recoil/calendar/mobaCalendarListState";
import { filterTasks } from "../../services/task/task.service";

/**
 * 같은 recurringEventId를 가진 이벤트들 중 가장 앞선(가장 이른 시작 날짜) 이벤트 하나만 반환하는 함수
 * 정렬 없이 그룹 내 최소 시작 시간을 가진 이벤트를 선형 탐색으로 찾는다.
 */
function filterEarliestRecurringEvents(events: CalendarBlockType[]): CalendarBlockType[] {
  const groupedByRecurring: Record<string, CalendarBlockType[]> = {};

  events.forEach((event) => {
    const key = event.recurringEventId || event.id;
    if (!groupedByRecurring[key]) {
      groupedByRecurring[key] = [];
    }
    groupedByRecurring[key].push(event);
  });

  const filtered: CalendarBlockType[] = [];
  for (const group of Object.values(groupedByRecurring)) {
    let earliestEvent = group[0];
    for (let i = 1; i < group.length; i++) {
      if (new Date(group[i].start).getTime() < new Date(earliestEvent.start).getTime()) {
        earliestEvent = group[i];
      }
    }
    filtered.push(earliestEvent);
  }

  return filtered;
}

const useCalendarDataClassification = (
  inbox3MonthsTaskList: CalendarBlockType[] | null,
  doneTaskList: InboxBlockType[] | null,
  selectedSpaceIdList: string[]
) => {
  const TODAY = useMemo(() => new Date().setHours(0, 0, 0, 0), []);

  const sortTasksByEndDate = (tasks: CalendarBlockType[]) => {
    return tasks.sort((a, b) => new Date(a.end).getTime() - new Date(b.end).getTime());
  };

  return useMemo(() => {
    let result: {
      todayTaskList: CalendarBlockType[];
      inboxPlannedList: CalendarBlockType[];
      overdueList: CalendarBlockType[];
      inboxCompletedList: (CalendarBlockType | InboxBlockType)[];
      todayCompletedList: CalendarBlockType[];
    } = {
      todayTaskList: [],
      inboxPlannedList: [],
      overdueList: [],
      inboxCompletedList: [],
      todayCompletedList: [],
    };

    if (Array.isArray(inbox3MonthsTaskList)) {
      inbox3MonthsTaskList
        .filter((task) => filterTasks(selectedSpaceIdList, task.projectId)) // 프로젝트 필터링
        .forEach((task) => {
          const isTask = task.taskType === "Task";
          const taskDate = new Date(task.start).setHours(0, 0, 0, 0);
          const isCompleted = task.itemStatus === "Completed";

          if (!isTask) return;

          if (isCompleted) {
            result.inboxCompletedList.push(task);
            if (taskDate === TODAY) result.todayCompletedList.push(task);
          } else if (taskDate === TODAY) {
            result.todayTaskList.push(task);
          } else if (taskDate > TODAY) {
            result.inboxPlannedList.push(task);
          } else {
            result.overdueList.push(task);
          }
        });
    }

    if (doneTaskList) {
      result.inboxCompletedList = [
        ...doneTaskList.filter((task) => filterTasks(selectedSpaceIdList, task.projectId)),
        ...result.inboxCompletedList,
      ];
    }

    // 반복 이벤트 중 가장 앞선 이벤트 하나만 남기기
    result.todayTaskList = filterEarliestRecurringEvents(result.todayTaskList);
    result.inboxPlannedList = filterEarliestRecurringEvents(result.inboxPlannedList);
    result.overdueList = filterEarliestRecurringEvents(result.overdueList);
    result.todayCompletedList = filterEarliestRecurringEvents(result.todayCompletedList);

    // 종료 날짜 기준으로 정렬
    result.todayTaskList = result.todayTaskList.sort((a, b) => {
      const createTimeBlockA = new Date(a.createdAt ?? "");
      const createTimeBlockB = new Date(b.createdAt ?? "");
      return createTimeBlockB.getTime() - createTimeBlockA.getTime();
    });
    result.inboxPlannedList = sortTasksByEndDate(result.inboxPlannedList);
    result.overdueList = sortTasksByEndDate(result.overdueList);
    result.todayCompletedList = sortTasksByEndDate(result.todayCompletedList);
    result.inboxCompletedList = sortTasksByEndDate(
      result.inboxCompletedList as CalendarBlockType[]
    );

    return result;
  }, [inbox3MonthsTaskList, doneTaskList, selectedSpaceIdList, TODAY]);
};

export function useInbox3MonthsTaskList(selectedSpaceIdList: string[] | null) {
  const doneTaskList = useRecoilValue(doneTaskListState);
  const threeMonthsEvents = useRecoilValue(threeMonthsEventsSelector);

  return useCalendarDataClassification(threeMonthsEvents, doneTaskList, selectedSpaceIdList ?? []);
}
