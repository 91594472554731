import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import useApi from "../../../services/auth/useApi";

import { useJuneTrackCall } from "../../../utils/june/analytics";
import { InboxBlockType } from "../../../types/block/BlockType";
import { inboxBlockQueryKey } from "../inboxQueryKey";
import { useSetRecoilState } from "recoil";
import { inboxTaskListState } from "../../../recoil/taskList/inboxTaskListState";

export const createInboxBlockApi = async (
  api: ReturnType<typeof useApi>,
  payload: InboxBlockType
): Promise<void> => {
  await api.post("/tasks", payload, {
    headers: {
      "x-requester": payload.creator,
    },
  });
};

interface InboxCreateContext {
  previousTasks: InboxBlockType[] | undefined;
}

export const useCreateInboxBlockMutation = (
  options?: UseMutationOptions<void, AxiosError, InboxBlockType, InboxCreateContext>
) => {
  const api = useApi();
  const trackCall = useJuneTrackCall();
  const queryClient = useQueryClient();
  const setInboxTaskList = useSetRecoilState(inboxTaskListState);

  return useMutation<void, AxiosError, InboxBlockType, InboxCreateContext>({
    mutationFn: (payload) => createInboxBlockApi(api, payload),

    onMutate: async (newTodo) => {
      await queryClient.cancelQueries({ queryKey: inboxBlockQueryKey() });

      const previousTasks = queryClient.getQueryData<InboxBlockType[]>(inboxBlockQueryKey());

      setInboxTaskList((prev) => {
        if (!prev) return [newTodo];
        return [newTodo, ...prev];
      });

      return { previousTasks };
    },
    onError: (err, newTodo, context) => {
      if (context?.previousTasks) {
        setInboxTaskList(context.previousTasks);
      }
    },
    // 캐시로 되돌리는 코드
    // onMutate: async (newTodo) => {
    //   queryClient.setQueryData<InboxBlockType[]>(["tasks"], (old) => {
    //     if (!old) return [newTodo];
    //     return [newTodo, ...old];
    //   });
    // },

    onSuccess: () => {
      trackCall("create_block", { location: "inbox" });
      queryClient.invalidateQueries({ queryKey: inboxBlockQueryKey() });
    },
    ...options,
  });
};
