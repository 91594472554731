import { atom, selectorFamily } from "recoil";
import { DividerType, NoSpaceType, SpaceType } from "../../types/space";
import { selector } from "recoil";
import {
  getInProgressSpaceList,
  insertNoSpaceFilter,
  isDividerType,
} from "../../services/space/space.service";

/**
 * 전체 Space 상태를 관리하는 Atom.
 * 포함 요소:
 * - SpaceType: Space (ex. in progress / deleted / completed 등 전부 포함)
 * - DividerType: Space를 구분하는 Divider
 */
export const projectListState = atom<(SpaceType | DividerType | NoSpaceType)[] | null>({
  key: "projectListState",
  default: null,
});

/**
 * In Progress인 Space와 Divider를 가져오는 Selector.
 * 포함 요소:
 * - SpaceType: Space (in progress만)
 * - DividerType: Space를 구분하는 Divider
 */
export const inProgressItemsSelector = selector({
  key: "inProgressItemsSelector",
  get: ({ get }) => {
    const projectList = get(projectListState) || [];
    return getInProgressSpaceList(projectList);
  },
});

/**
 * 전체 Space에서 Divider를 제외한 Selector.
 * 포함 요소:
 * - SpaceType: Space (ex. in progress / deleted / completed 등 전부 포함)
 */
export const spacesWithoutDividerSelector = selector({
  key: "spacesWithoutDividerSelector",
  get: ({ get }) => {
    const projectList = get(projectListState) || [];
    return projectList.filter((space) => !isDividerType(space));
  },
});

/**
 * In Progress인 Space와 No Space filter를 리턴하는 Selector.
 * 포함 요소:
 * - SpaceType: Space (ex. in progress만)
 * - DividerType: Space를 구분하는 Divider
 * - No Space: No Space 필터
 */
export const inProgressAllSpaceSelector = selectorFamily({
  key: "inProgressAllSpaceStateSelector",
  get:
    (order: string[]) =>
    ({ get }) => {
      const inProgressItems = get(inProgressItemsSelector);
      return insertNoSpaceFilter(order, inProgressItems);
    },
});
