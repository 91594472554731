import { Flex } from "@chakra-ui/react";
import {
  CalendarBlockType,
  DraggedEventType,
  InboxBlockPositionType,
  InboxBlockStatusType,
  InboxBlockType,
} from "../../../../types/block/BlockType";

import InboxBlock from "../InboxBlock/InboxBlock";
import useFetchInProgressBlockQuery from "../../../../react-query/inbox/core/useFetchInProgressBlockQuery";
import { InboxHeaderTab } from "../../../../types/inbox/inbox-headers";
import { pxToRem } from "../../../../utils/styles/size";
import { TaskDividerType } from "../../../../services/divider/divider.service";
import TaskDividerBlock from "../Divider/TaskDividerBlock";

import { isInboxBlock } from "../../../../services/inbox/inboxType";
import { useRecoilValue } from "recoil";
import { draggedEventState } from "../../../../recoil/calendar/calendarStateV2";
import { useState } from "react";
import { useUpdateCalendarBlockMutation } from "../../../../react-query/calendar/useUpdateCalendarBlockMutation";
import moment from "moment";
import { deleteNullValueInObject } from "../../../../utils/taskDetail/formatServerSendData";
// import { mobaCalendarListState } from "../../../../recoil/calendar/mobaCalendarListState";
// import { useDeleteRecurrenceBlock } from "../../../../queries/RecurrenceBlock";
// import { useUpdateRecurrenceBlock } from "../../../../queries/useUpdateRecurrenceBlock";

interface InboxGeneralListProps {
  activeTab: InboxHeaderTab;
  taskList?: CalendarBlockType[];
  onDataDelete: (data: InboxBlockType | CalendarBlockType) => void;
  onDataDuplicate: (e: React.MouseEvent, data: InboxBlockType | CalendarBlockType) => void;
  taskSelectedId: string | null;
  onSelect: (id: string | null) => void;
  flexGrow: 0 | 1;
  onClickInboxBlock: (
    e: React.MouseEvent,
    data: InboxBlockType | CalendarBlockType,
    ref: React.RefObject<HTMLDivElement>
  ) => void;
  draggedEvent: DraggedEventType | null;
}
const InboxGeneralList = ({
  activeTab,
  taskList,
  onDataDelete,
  onDataDuplicate,
  taskSelectedId,
  onSelect,
  flexGrow,
  onClickInboxBlock,
  draggedEvent,
}: InboxGeneralListProps) => {
  const { data: inboxTaskList, isError } = useFetchInProgressBlockQuery();

  const [isTodayGeneralListHovered, setIsTodayListHovered] = useState<boolean>(false);
  const { mutate: updateCalendarBlock } = useUpdateCalendarBlockMutation();
  // const setMobaCalendarList = useSetRecoilState(mobaCalendarListState);
  // const { mutate: deleteRecurrenceMutate } = useDeleteRecurrenceBlock();
  // const { mutate: updateRecurrenceBlock } = useUpdateRecurrenceBlock();

  if (isError) {
    return <div> isError</div>;
  }

  const handleUpdateCalendarToToday = async (event: DraggedEventType) => {
    const { color, kind, dragType, index, isCreateSelectEvent, ...eventData } = event;

    const timeChangedEvent = {
      ...eventData,
      allDay: true,
      start: { date: moment(new Date()).format("YYYY-MM-DD") },
      end: { date: moment(new Date()).format("YYYY-MM-DD") },
      createdAt: new Date(),
    };

    const nullDeletedEvent = deleteNullValueInObject(timeChangedEvent);

    if (event.recurrence && event.recurrence.length > 0) {
      // TODO 추후 반복 로직 추가
      // const recurrenceDeletedEvent = {
      //   ...nullDeletedEvent,
      //   recurrence: [],
      // };

      // setMobaCalendarList((current: CalendarBlockType[] | null) => {
      //   if (!current) return [];
      //   const updatedEvents = current?.filter((current) => {
      //     return !(
      //       event.recurringEventId === current.id ||
      //       event.recurringEventId === current.recurringEventId
      //     );
      //   });
      //   return updatedEvents;
      // });

      // updateRecurrenceBlock({
      //   updatedBlockData: recurrenceDeletedEvent,
      //   selectedOption: "current",
      //   notify: false,
      //   creator: event.creator,
      // });
      return;
    }

    updateCalendarBlock({ payload: nullDeletedEvent });

    // NOTE 반복 있는 이벤트일 경우
    // 현재 이동시키는 이벤트만 두고 나머지 다 삭제 ?
  };

  return (
    <Flex
      flexGrow={flexGrow}
      w={"100%"}
      height={"fit-content"}
      flexDir={"column"}
      gap={pxToRem(6)}
      borderRadius={"6px"}
      border={"2px solid"}
      borderColor={isTodayGeneralListHovered ? "whiteAlpha.400" : "transparent"}
      onMouseEnter={() => {
        if (draggedEvent && draggedEvent?.dragType === "calendarTask") {
          setIsTodayListHovered(true);
        }
      }}
      onMouseLeave={() => {
        setIsTodayListHovered(false);
      }}
      onMouseUp={() => {
        if (draggedEvent && draggedEvent.dragType === "calendarTask") {
          handleUpdateCalendarToToday(draggedEvent);
          setIsTodayListHovered(false);
        }
      }}
    >
      {/* NOTE GeneralList에는 isOverdue 항상 false */}
      {activeTab === "Inbox"
        ? inboxTaskList?.map((task: InboxBlockType | TaskDividerType, index) => {
            if (isInboxBlock(task)) {
              return (
                <InboxBlock
                  data={task}
                  key={task.id}
                  index={index}
                  onDataDelete={onDataDelete}
                  onDataDuplicate={onDataDuplicate}
                  status={InboxBlockStatusType.Inbox}
                  position={InboxBlockPositionType.General}
                  isSelected={taskSelectedId === task.id}
                  onSelect={onSelect}
                  onClickBlock={onClickInboxBlock}
                />
              );
            } else {
              return (
                <TaskDividerBlock
                  key={task.id}
                  index={index}
                  data={task}
                  onDelete={() => {}}
                  onDuplicate={() => {}}
                  onReorder={() => {}}
                  isSelected={taskSelectedId === task.id}
                  onSelect={onSelect}
                />
              );
            }
          })
        : // Today General
          taskList?.map((task: CalendarBlockType, index) => (
            <InboxBlock
              data={task}
              index={index}
              key={task.id}
              onDataDelete={onDataDelete}
              onDataDuplicate={onDataDuplicate}
              isTodayGeneral={true}
              status={InboxBlockStatusType.Today}
              position={InboxBlockPositionType.General}
              isSelected={taskSelectedId === task.id}
              onSelect={onSelect}
              onClickBlock={onClickInboxBlock}
            />
          ))}
    </Flex>
  );
};

export default InboxGeneralList;
