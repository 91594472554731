import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Resizable } from "re-resizable";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { tokenState } from "../recoil/auth/tokenState";

import { useJuneIdentifyCall, useJuneTrackCall } from "../utils/june/analytics";
import { loadFromLocalStorage, saveToLocalStorage } from "../utils/localStorage/localStorage";

import useFetchCompletedTasks from "../hooks/useFetchCompletedTasks";

import { CalendarViewType } from "../constants";

import TaskInbox from "../compoenets/TaskInbox";
import Integration from "../compoenets/Integration";
import CalendarCustom from "../compoenets/CalendarCustom";
import useApi from "../services/auth/useApi";
import Settings from "../compoenets/Settings";
import Wiki from "../compoenets/Wiki";
import ProjectCreate from "../compoenets/Wiki/ProjectCreate";
import Onboarding from "../compoenets/Onboarding";
import Toast from "./Toast";
import GuestPopup from "./Popup/GuestPopup/GuestPopup";
import RecurringPopup from "./Popup/RecurringPopup/RecurringPopup";

import styles from "./Main.module.css";
import { SpaceSideBar } from "../compoenets/ProjectFilter/SideBarMenu/SpaceSideBar";
import { viewModeState } from "../recoil/view/viewMode";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { accountState } from "../recoil/account/accountStateV2";
import { spaceQueryKey } from "../react-query/space/core/useFetchSpaceQuery";
import { TaskDetailWrapper } from "./TaskDetailWrapper";
import { pxToRem } from "../utils/styles/size";
import { resizableHandleStyle, resizableStyles } from "../utils/common/resizableStyles.ts";
import ChangeLogPopup from "./Popup/ChangelogPopup/ChangeLogPopup";

const isDev = process.env.NODE_ENV !== "production";

export default function Main() {
  const navigate = useNavigate();
  // const location = useLocation();

  const [token, setToken] = useRecoilState(tokenState);
  const setAccountData = useSetRecoilState(accountState);
  const [showSettingsPopup, setShowSettingsPopup] = useState(null);

  const api = useApi();

  const [showCreateProejct, setShowCreateProject] = useState({
    isShow: false,
    projectInfo: null,
  });
  const viewMode = useRecoilValue(viewModeState);
  const isCalendarMode = viewMode === "calendar";
  const [showOnboarding, setShowOnboarding] = useState(null);

  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);
  const [isIntegrationDataFirstLoading, setIsIntegrationDataFirstLoading] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const identifyCall = useJuneIdentifyCall();
  const trackCall = useJuneTrackCall();

  const { fetchCompletedTasks } = useFetchCompletedTasks();
  const fetchProjectsData = () => {
    queryClient.invalidateQueries({ queryKey: spaceQueryKey() });
  };

  /** disclosures */
  const {
    isOpen: isSpaceSidebarExpanded,
    onToggle: onToggleSpaceSidebar,
    onOpen: onOpenSpaceSidebar,
  } = useDisclosure();

  const tokenRef = useRef(token);
  const inboxRef = useRef(null);
  const integrationBtnRef = useRef(null);
  const guestPopupRef = useRef(null);
  const recurringPopupRef = useRef(null);

  const queryClient = useQueryClient();

  // NOTE Integration 관련 코드
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const integrationType = searchParams.get("integrationType");

  //   if (integrationType) {
  //     const capitalizedIntegrationType = capitalizeFirstLetter(integrationType);

  //     // URL을 /main으로 변경
  //     navigate("/main", { replace: true });

  //     // 로컬 스토리지에서 lastSelectedIntegration 객체를 가져와 isValidToken만 삭제
  //     const lastSelectedIntegration = JSON.parse(localStorage.getItem("lastSelectedIntegration"));
  //     if (lastSelectedIntegration) {
  //       delete lastSelectedIntegration.isValidToken;
  //       localStorage.setItem("lastSelectedIntegration", JSON.stringify(lastSelectedIntegration));
  //     }
  //     trackCall("reconnect_integration", { type: integrationType });

  //     setToast({
  //       type: capitalizedIntegrationType,
  //       isVisible: true,
  //       message: `${capitalizedIntegrationType} connected`,
  //     });
  //   }
  // }, [location, setToast, navigate]);

  useEffect(() => {
    // 첫 로드 시에만 실행
    if (!initialLoadDone) {
      loadData(true, true, true);
      setInitialLoadDone(true);
    }
  }, []);

  useEffect(() => {
    if (initialLoadDone) {
      loadData(true, true, true);
    }
  }, []);

  useEffect(() => {
    if (isCalendarMode && initialLoadDone) {
      loadData(false, false, true);
    }
  }, [isCalendarMode]);

  const handleShowCreateProject = (isShow, projectInfo) => {
    setShowCreateProject({ isShow: isShow, projectInfo: projectInfo });
  };

  const toggleSettingsPopup = (settingState) => {
    setShowSettingsPopup(settingState);
  };

  const toggleIntegrationModal = () => {
    setIsIntegrationModalOpen((prev) => !prev);
  };

  const loadDoneTask = () => {
    fetchCompletedTasks();
  };

  const loadInboxTask = () => {
    // useFetchInProgressBlockQuery();
  };

  const loadProjects = () => {
    fetchProjectsData();
  };

  const loadData = async () => {
    try {
      const res = await api.get("accounts/me");
      if (!isDev && res.data.accountInfo.membership === "trial") {
        return navigate("/joinWaitList");
      }

      handleAccountInfo(res.data);
      loadTasks();
      // await refreshTokensAndLoadCalendar(res.data, queryClient);
    } catch (err) {
      console.error("Failed to load data:", err);
    }
  };

  // NOTE 계정 정보 가져오기
  const handleAccountInfo = (data) => {
    const { accountInfo } = data;
    const primaryAccountInfo = accountInfo.accounts.find((acc) => acc.type === "primary");

    identifyCall(primaryAccountInfo.email, accountInfo.membership);
    setAccountData(data);
    setShowOnboarding(accountInfo.onboarding);
  };

  const loadTasks = () => {
    loadDoneTask();
    loadInboxTask();
    loadProjects();
  };

  useEffect(() => {
    tokenRef.current = token;
  }, [token, setToken]);

  const handleOnboardingClick = () => {
    setShowOnboarding(true);
  };

  useEffect(() => {
    if (!loadFromLocalStorage("calendarViewType")) {
      saveToLocalStorage("calendarViewType", CalendarViewType.WEEK.type);
    }
  }, []);

  const savedWidth = localStorage.getItem("savedWidth") ?? "320px";
  const handleResizeStop = (e, direction, ref, delta) => {
    if (!ref) {
      console.warn("Resize reference is not available");
      return;
    }
    const currentWidth = ref?.style?.width;
    localStorage.setItem("savedWidth", currentWidth);
  };

  return (
    <div className={styles["main"]}>
      {showOnboarding === false && <Onboarding hideOnboarding={handleOnboardingClick} />}
      <SpaceSideBar
        isExpanded={isSpaceSidebarExpanded}
        onToggle={onToggleSpaceSidebar}
        onOpen={onOpenSpaceSidebar}
        onClickSettings={() => toggleSettingsPopup("Account")}
      />
      <Flex flex={1} bg="#1a1c1c" h="100%" p={pxToRem(7)} gap={2}>
        <>
          {viewMode === "calendar" && (
            <Box sx={resizableStyles} height={"100%"}>
              <Resizable
                defaultSize={{
                  width: savedWidth,
                  height: "100%",
                }}
                minWidth={250}
                maxWidth={1000}
                enable={{
                  left: false,
                  right: true,
                  top: false,
                  bottom: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }}
                handleStyles={{
                  right: resizableHandleStyle,
                }}
                handleClasses={{
                  right: "custom-resizer-handle-right",
                }}
                onResizeStop={handleResizeStop}
              >
                <TaskInbox
                  inboxRef={inboxRef}
                  loadDoneTask={loadDoneTask}
                  toggleIntegrationModal={toggleIntegrationModal}
                  setIsIntegrationModalOpen={setIsIntegrationModalOpen}
                  isIntegrationModalOpen={isIntegrationModalOpen}
                  integrationBtnRef={integrationBtnRef}
                  setIsIntegrationDataFirstLoading={setIsIntegrationDataFirstLoading}
                />
              </Resizable>
            </Box>
          )}
        </>

        <Flex
          flex={1}
          minW="422px"
          position="relative"
          borderRadius="8px"
          border="1px solid #313435"
          overflow="hidden"
        >
          {showCreateProejct.isShow === true && !isCalendarMode && (
            <ProjectCreate
              onDismissClick={handleShowCreateProject}
              loadData={loadData}
              projectDetail={showCreateProejct.projectInfo}
            />
          )}
          {isCalendarMode ? (
            <CalendarCustom loadData={loadData} />
          ) : (
            !showCreateProejct.isShow && (
              <Wiki onProjectAddClick={handleShowCreateProject} loadData={loadData} />
            )
          )}
        </Flex>
        {showSettingsPopup && (
          <Settings
            settingState={showSettingsPopup}
            onClose={() => toggleSettingsPopup(null)}
            loadData={loadData}
          />
        )}
      </Flex>

      {isIntegrationModalOpen && (
        <Integration
          inboxRef={inboxRef}
          settingState={(settingState) => toggleSettingsPopup(settingState)}
          isIntegrationModalOpen={isIntegrationModalOpen}
          setIsIntegrationModalOpen={setIsIntegrationModalOpen}
          integrationBtnRef={integrationBtnRef}
          isIntegrationDataFirstLoading={isIntegrationDataFirstLoading}
        />
      )}

      <TaskDetailWrapper guestPopupRef={guestPopupRef} recurringPopupRef={recurringPopupRef} />

      <Toast />
      <GuestPopup guestPopupRef={guestPopupRef} />
      <RecurringPopup recurringPopupRef={recurringPopupRef} />
      <ChangeLogPopup />
    </div>
  );
}
