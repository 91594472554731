import { Text } from "@chakra-ui/react";
import { formatTimeDuration } from "../../../utils/common/dateTime/formatTimeDuration";
import { useRecoilValue } from "recoil";
import { defaultDurationState } from "../../../recoil/calendar/settingCalendar";
import formatTimeInterval from "../../../utils/common/dateTime/formatTimeInterval";

interface CalendarBlockTimeProps {
  start: Date | string;
  end: Date | string;
  isOnlyDuration: boolean;
}

const CalendarBlockTime = ({ start, end, isOnlyDuration }: CalendarBlockTimeProps) => {
  const duration = useRecoilValue(defaultDurationState);
  return (
    <Text
      color="rgba(255, 255, 255, 0.40)"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      noOfLines={1}
      textStyle="caption2"
    >
      {formatTimeDuration(Number(duration))}
    </Text>
  );
};

export default CalendarBlockTime;
