import { Flex } from "@chakra-ui/react";

import {
  CalendarBlockType,
  InboxBlockType,
  isCalendarBlock,
} from "../../../../types/block/BlockType";

import InboxBlockSummary from "./InboxBlockSummary";
import InboxBlockCheckbox from "./InboxBlockCheckbox";

import { pxToRem } from "../../../../utils/styles/size";
import InboxBlockRightSection from "./InboxBlockRightSection";

interface InboxBlockBodyProps {
  data: InboxBlockType | CalendarBlockType;
  isOverdue: boolean;
  isTodayGeneral?: boolean;
  isCompleted: boolean;
}

const InboxBlockBody = ({ data, isOverdue, isTodayGeneral, isCompleted }: InboxBlockBodyProps) => {
  return (
    <Flex
      borderRadius={pxToRem(5)}
      padding={`${pxToRem(6)} ${pxToRem(6)} ${pxToRem(6)} ${pxToRem(2)} `}
      alignItems={"flex-start"}
      flex={1}
      gap={pxToRem(6)}
      id={"inbox-block-body"}
      minWidth={0}
    >
      <InboxBlockCheckbox
        data={data}
        isCompleted={data?.itemStatus === "Completed"}
        projectId={data?.projectId}
      />
      <InboxBlockSummary
        title={data?.title ?? "No title"}
        links={data?.links ?? []}
        previewNote={data?.previewNote ?? ""}
        isCompleted={isCompleted}
      />
      <InboxBlockRightSection data={data} isOverdue={isOverdue} isTodayGeneral={isTodayGeneral} />
    </Flex>
  );
};

export default InboxBlockBody;
