import React from "react";
import { Input, Textarea } from "@chakra-ui/react";
import { hexToRgba } from "../../../utils/styles/color";
import { pxToRem } from "../../../utils/styles/size";
import { hideScrollBar } from "./LocationMenu";
import ResizeTextarea from "react-textarea-autosize";

interface LocationSearchInputProps {
  value: string;
  onChange: (value: string) => void;
  expand: boolean;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const commonInputStyling = {
  bg: hexToRgba("#000000", 0.2),
  borderRadius: 5,
  border: "none",
  px: 1,
  py: 1,
  color: "white",
  autoFocus: true,
  _focusVisible: {},
  boxSizing: "border-box" as const,
  fontWeight: 400,
  fontSize: pxToRem(14),
  lineHeight: 1.5,
  sx: {
    caretColor: "#69E4FF",
  },
  _placeholder: {
    color: "#7c7d7d",
  },
  "aria-label": "New location",
  placeholder: "Add location",
};

export const LocationSearchInput: React.FC<LocationSearchInputProps> = ({
  value,
  onChange,
  expand,
  onBlur,
  onKeyDown,
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.stopPropagation();
    onKeyDown?.(e);
  };

  if (expand) {
    return (
      <Textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        css={{
          ...hideScrollBar(),
        }}
        as={ResizeTextarea}
        minH="unset"
        overflow="hidden"
        w="100%"
        resize="none"
        minRows={1}
        {...commonInputStyling}
      />
    );
  }

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      h="fit-content"
      {...commonInputStyling}
    />
  );
};
