import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { accountState } from "../../../recoil/account/accountStateV2";

export default function ChangeLogPopup() {
  const accountData = useRecoilValue(accountState);
  useEffect(() => {
    if (!accountData) return;

    let script = document.querySelector(`script[src="https://do.featurebase.app/js/sdk.js"]`);
    if (!script) {
      script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://do.featurebase.app/js/sdk.js";
      script.async = true;
      document.head.appendChild(script);
    }
    script.addEventListener("load", () => {
      //console.log(window.Featurebase);
      if (!window.Featurebase) console.error(`no feature base script!`);
      window.Featurebase(
        "init_changelog_widget",
        {
          organization: "moba", // Replace this with your organization name, copy-paste the subdomain part from your Featurebase workspace url (e.g. https://*yourorg*.featurebase.app)
          dropdown: {
            enabled: true, // Add this to enable the dropdown view of the changelog
            placement: "right", // Add this to change the placement of the dropdown
          },
          popup: {
            enabled: true, // Add this to enable the popup view of the changelog
            usersName: accountData.accountInfo.accounts[0].givenName, // This will show the user's name in the popup as a greeting
            autoOpenForNewUpdates: true, // This will open the popup for new updates
          },
          theme: "dark", // Choose between dark or light theme
          locale: "en", // Change the language, view all available languages from https://help.featurebase.app/en/articles/8879098-using-featurebase-in-my-language
        },
        (error, result) => {
          if (error) {
            console.error(`featurebase load failed`, error);
          }
          // else console.log(`featurebase load scuccess`, result);
          //window.Featurebase('manually_open_changelog_popup') // use this function when manually open
        }
      );
    });
    return () => {
      document.head.removeChild(script);
    };
  }, [accountData]);

  /**
   * data-featurebase-changelog 가 button 에 붙어야만 스크립트 코드가 실행됌
   * 따라서 넓이 높이를 0, zIndex를 맨 밑으로 줘서 안보이게 설정했습니다.
   * 더 좋은 아이디어가있다면 언제든지 코드 수정을 부탁드립니다.
   */
  return (
    <div style={{ width: "0px", height: "0px", zIndex: "-999" }}>
      <button data-featurebase-changelog></button>
    </div>
  );
}
